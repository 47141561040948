<template>
  <form>
    <h1>{{ $t('register.step_2.title') }}</h1>

    <div class="support-infos-title">
      <h2 v-if="!emailIsConfirmed">
        {{ $t('register.step_2.subtitle_email') }}
      </h2>
      <h2 v-else>{{ $t('register.step_2.subtitle_phone') }}</h2>
      <button>
        <p v-if="!emailIsConfirmed">1/2</p>
        <p v-else>2/2</p>
      </button>
    </div>

    <div class="code pc">
      <div>
        <input
          class="a"
          type="text"
          maxlength="1"
          v-model="square.a"
          @keyup="checkNumber($event)"
        />
        <input
          class="b"
          type="text"
          maxlength="1"
          v-model="square.b"
          @keyup="checkNumber($event)"
        />
        <input
          class="c"
          type="text"
          maxlength="1"
          v-model="square.c"
          @keyup="checkNumber($event)"
        />
        <input
          class="d"
          type="text"
          maxlength="1"
          v-model="square.d"
          @keyup="checkNumber($event)"
        />
        <input
          class="e"
          type="text"
          maxlength="1"
          v-model="square.e"
          @keyup="checkNumber($event)"
        />
        <input
          class="f"
          type="text"
          maxlength="1"
          v-model="square.f"
          @keyup="checkNumber($event)"
        />
      </div>
    </div>

    <div class="code tel">
      <el-input
      v-if="!emailIsConfirmed"
        type="number"
        minlength="6"
        maxlength="6"
        v-model="code.email"
        focus
        placeholder="CODE"
        clearable
      />

      <el-input
        v-else
        type="number"
        minlength="6"
        maxlength="6"
        v-model="code.phone"
        focus
        placeholder="CODE"
        clearable
      />
    </div>

    <button
      class="generic-btn confirm-code"
      @click.prevent="checkNumber(0)"
    >
      {{ $t('commons.confirm') }}
    </button>

    <div class="btn-options">
      <p>{{ $t('register.step_2.no_receipt') }}</p>
      <button
        :disabled="this.timer_count_email > 0 || this.timer_count_phone > 0"
        @click.prevent="!emailIsConfirmed ? setTimerEmail() : setTimerPhone()"
      >
        <img
          src="@/assets/svg/v2/registerProcess/resend.svg"
          alt="Resend code"
        />
        {{ $t('register.step_2.resend') }}
        <span v-if="timer_count_email !== null">
          ({{ this.timer_count_email }}s)</span
        >
        <span v-if="timer_count_phone !== null">
          ({{ this.timer_count_phone }}s)</span
        >
      </button>
    </div>

    <div v-if="!emailIsConfirmed && getUserDetails" class="device-informations">
      <p>{{ $t('register.email') }} : <span>{{ getUserDetails.address.email }}</span></p>
      <button @click.prevent="$router.push('/auth/account/details')"> 
        <img
          src="@/assets/svg/v2/registerProcess/edit.svg"
          alt="Resend code"
        />
        <span>{{ $t('settings.update') }}</span>
      </button>
    </div>

    <div class="confirmations-status-email">
      <div class="support">
        <img src="@/assets/svg/v2/registerProcess/email.svg" alt="Email" />
        <p>{{ $t('register.email') }}</p>
      </div>
      <div class="state">
        <p v-if="!emailIsConfirmed" :class="{ await: !emailIsConfirmed }">
          {{ $t('register.step_2.await_validation') }}
        </p>
        <img
          v-if="!emailIsConfirmed"
          src="@/assets/svg/v2/registerProcess/awaitConfirmation.svg"
          alt="Await confirmation email"
        />

        <p v-if="emailIsConfirmed">{{ $t('register.step_2.validated') }}</p>
        <img
          v-if="emailIsConfirmed"
          src="@/assets/svg/v2/registerProcess/complete.svg"
          alt="Email confirmed"
        />
      </div>
    </div>

    <div class="confirmations-status-phone">
      <div class="support">
        <img src="@/assets/svg/v2/registerProcess/phone.svg" alt="Phone" />
        <p>{{ $t('register.phone') }}</p>
      </div>
      <div class="state">
        <p v-if="!phoneIsConfirmed" :class="{ await: !phoneIsConfirmed }">
          {{ $t('register.step_2.await_validation') }}
        </p>
        <img
          v-if="!phoneIsConfirmed"
          src="@/assets/svg/v2/registerProcess/awaitConfirmation.svg"
          alt="Await confirmation phone"
        />

        <p v-if="phoneIsConfirmed">{{ $t('register.step_2.validated') }}</p>
        <img
          v-if="phoneIsConfirmed"
          src="@/assets/svg/v2/registerProcess/complete.svg"
          alt="Phone confirmed"
        />
      </div>
    </div>
  </form>
</template>

<script>
import { ElMessage } from 'element-plus'
import { mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      timer_count_email: null,
      timer_count_phone: null,

      code: {
        email: null,
        phone: null,
      },

      regex: {
        email: false,
        phone: false,
      },

      square: {
        a: null,
        b: null,
        c: null,
        d: null,
        e: null,
        f: null,
      },
    }
  },
  async mounted() {
    await this.getPhoneEmailCheck().finally(() => {
      this.getClient();
      document.querySelector('.a').focus()
    })
  },
  computed: {
    ...mapGetters({
      emailIsConfirm: 'emailIsConfirm',
      phoneIsConfirm: 'phoneIsConfirm',
      getUserDetails: 'getUserDetails',
    }),
    emailIsConfirmed() {
      return this.emailIsConfirm
    },
    phoneIsConfirmed() {
      return this.phoneIsConfirm
    },
  },
  methods: {
    ...mapActions({
      getPhoneEmailCheck: 'getPhoneEmailCheck',
      getClient: 'getAccountDetails',
    }),
    setTimerEmail() {
      this.$store.dispatch('getEmailCode', null)
      this.timer_count_email = 60
      let interval = setInterval(() => {
        this.timer_count_email = this.timer_count_email - 1
        if (this.timer_count_email < 1) {
          clearInterval(interval)
          this.timer_count_email = null
        }
      }, 1000)
    },
    setTimerPhone() {
      this.$store.dispatch('getPhoneCode', null)
      this.timer_count_phone = 60
      let interval = setInterval(() => {
        this.timer_count_phone -= 1
        if (this.timer_count_phone < 1) {
          clearInterval(interval)
          this.timer_count_phone = null
        }
      }, 1000)
    },
    sendEmailCode() {
      const regex_code = /^[0-9]{6}$/

      if (!regex_code.test(this.code.email)) {
        this.regex.email = true
        ElMessage({
          message: this.$t.register.step_2.code_err,
          type: 'warning',
          grouping: true,
        })
      } else {
        this.regex.email = false
        const email_payload = { email_code: this.code.email }
        this.$store.dispatch('sendEmailCode', email_payload)
        this.square = {
          a: null,
          b: null,
          c: null,
          d: null,
          e: null,
          f: null,
        }
        document.querySelector('.a').focus()
      }
    },
    sendPhoneCode() {
      const regex_code = /^[0-9]{6}$/

      if (!regex_code.test(this.code.phone)) {
        this.regex.phone = true
        ElMessage({
          message: this.$t.register.step_2.code_err,
          type: 'warning',
          grouping: true,
        })
      } else {
        this.regex.phone = false
        const phone_payload = { phone_code: this.code.phone }
        this.$store.dispatch('sendPhoneCode', phone_payload)
        this.square = {
          a: null,
          b: null,
          c: null,
          d: null,
          e: null,
          f: null,
        }
        document.querySelector('.a').focus()
      }
    },
    checkNumber(e) {
      if (e.keyCode === 8) {
        let new_key = null
        switch (e.srcElement.className) {
          case 'a':
            new_key = 'a'
            this.square.a = null
            break
          case 'b':
            new_key = 'a'
            this.square.a = null
            this.square.b = null
            break
          case 'c':
            new_key = 'b'
            this.square.b = null
            this.square.c = null
            break
          case 'd':
            new_key = 'c'
            this.square.c = null
            this.square.d = null
            break
          case 'e':
            new_key = 'd'
            this.square.d = null
            this.square.e = null
            break
          case 'f':
            new_key = 'e'
            this.square.e = null
            this.square.f = null
            break
        }

        document.querySelector('.' + new_key).focus()
      }
      if (this.square.a != null && e.keyCode != 8) {
        document.querySelector('.b').focus()
        if (this.square.b != null && e.keyCode != 8) {
          document.querySelector('.c').focus()
          if (this.square.c != null && e.keyCode != 8) {
            document.querySelector('.d').focus()
            if (this.square.d != null && e.keyCode != 8) {
              document.querySelector('.e').focus()
              if (this.square.e != null && e.keyCode != 8) {
                document.querySelector('.f').focus()
                if (this.square.f != null && e.keyCode != 8) {
                  const code =
                    this.square.a +
                    this.square.b +
                    this.square.c +
                    this.square.d +
                    this.square.e +
                    this.square.f

                  const regex_code = /^[0-9]{6}$/

                  if (!regex_code.test(code)) {
                    ElMessage({
                      message: this.$t.register.step_2.code_err,
                      type: 'error',
                      grouping: true,
                    })
                  } else {
                    if (!this.emailIsConfirmed) {
                      this.code.email = code
                      this.sendEmailCode()
                    } else {
                      this.code.phone = code
                      this.sendPhoneCode()
                    }
                  }
                }
              }
            }
          }
        }
      } else {
        const regex_code = /^[0-9]{6}$/
        let code = this.code.email;

        if (this.emailIsConfirmed) {
          code = this.code.phone;
        }

        if (!regex_code.test(code)) {
          ElMessage({
            message: this.$t.register.step_2.code_err,
            type: 'error',
            grouping: true,
          })
        } else {
          if (!this.emailIsConfirmed) {
            this.code.email = code
            this.sendEmailCode()
          } else {
            this.code.phone = code
            this.sendPhoneCode()
          }
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/root.scss';
form,
.confirmations-status-email,
.confirmations-status-phone,
.support,
.state,
.btn-options,
.support-infos-title,
.support-infos-subtitle,
.device-informations {
  display: flex;
}

.tel {
  display: none;
}

form {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 500px;

  h1 {
    margin-top: 74px;
  }

  .code {
    align-items: center;

    div {
      input {
        text-align: center;
        height: 81px;
        width: 61px;
        border: 1px solid #bebebe;
        background: #333;
        border-radius: 8px;
        margin: 25px 8px;
        caret-color: white;
        color: white;
        font-size: 42px;
        font-weight: 500;

        &:focus {
          border-radius: 8px;
          border: 2px solid white;
          background: #333;
          outline: none;
        }
      }
    }

    span {
      font-size: 50px;
    }
  }

  .btn-options, .device-informations {
    margin-top: 40px;
    justify-content: space-between;
    width: 100%;

    p {
      color: $grey40;
      font-size: 15px;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: transparent;
      color: $subtitle;
      font-size: 15px;
      border: 0;
      width: 90px;
      height: 23px;

      img {
        margin-right: 4px;
      }
    }
  }

  .device-informations {
    margin-top: 10px;
    width: 100%;
    
    p {
      font-size: 15px;

      span {
        color: white;
      }
    }
  }

  h4 {
    text-align: center;
    line-height: 25px;
  }
}

.support-infos-title {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 47px;

  h2 {
    color: white;
    font-size: 16px;
    font-weight: 600;
  }

  button {
    background: transparent;
    border-radius: 50px;
    border: 1px solid $border;
    height: 30px;
    width: 43px;

    p {
      font-size: 15px;
      color: $subtitle;
    }
  }
}

.support-infos-subtitle {
  color: $subtitle;
  width: 100%;
  margin-top: 7px;
}

.confirmations-status-email,
.confirmations-status-phone {
  border: 1px solid $border;
  background: #222;
  height: 50px;
  width: 490px;
  justify-content: space-between;

  .support {
    color: $grey40;
    align-items: center;
    margin-left: 11px;

    img {
      margin-right: 9px;
    }
  }

  .state {
    align-items: center;
    margin-right: 11px;

    img {
      margin-left: 8px;
    }

    p {
      color: #84cc16;
    }
  }
}

.confirmations-status-email {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 0;
  margin-top: 42px;
}

.confirmations-status-phone {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-bottom: 159px;
}

.await {
  color: #f59e0b !important;
}

@media screen and (max-width: 500px) {
  .await, .pc {
    display: none;
  }

  .tel {
    display: flex;
  }

  form {
    width: auto!important;
    margin: auto 5px;

    h1 {
      margin-top: 15px;
      max-width: 320px;
      text-align: center;
    }

    .support-infos-title {
      margin-top: 10px;
    }
  }

  .support-infos-title, .btn-options, .btn-options, .confirmations-status-email, .confirmations-status-phone, .device-informations {
    width: 100%;
  }

  .confirm-code {
    width: 200px;
    margin-bottom: 20px;
  }

  .btn-options {
    margin-top: 0!important;
  }

  .confirmations-status-email {
    margin-top: 20px;
  }

  .code {
    width: 100%;
    margin: 15px auto;

    div {
      display: flex;
      justify-content: space-between;
      width: 100%;

      input {
        width: 40px!important;
        height: 40px!important;
        margin: 30px 0px!important;
        font-size: 20px!important;
      }
    }
  }
}
</style>
