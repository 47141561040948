import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'element-plus/dist/index.css';
import 'element-plus/theme-chalk/dark/css-vars.css'
import ElementPlus from 'element-plus';
import Cookies from 'js-cookie';
import i18n from './i18n';
import Highcharts from "highcharts";
import Stock from "highcharts/modules/stock";
import HighchartsVue from "highcharts-vue";
import VuePdf from 'vue-pdf-embed';

Stock(Highcharts);
createApp(App).use(i18n).use(store).use(router).use(ElementPlus).use(Cookies).use(HighchartsVue).use(VuePdf).mount('#app');
