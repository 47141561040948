export default {
  "commons": {
    "buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kup i Sprzedaj"])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap"])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocktrade"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraj"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
    "bid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferta"])},
    "ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapytaj"])},
    "giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dawca"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taker"])},
    "report_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zgłoś błąd"])},
    "account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rodzaj konta"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamówienia"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilość"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powrót do"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamknij"])},
    "product_origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pochodzenie produktu"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontynuuj"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdzenie"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Połączenie"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyloguj się"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Załóż konto"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyszukiwanie i handel"])},
    "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subskrypcje"])},
    "dark_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tryb nocny"])},
    "light_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tryb przejrzysty"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historia transakcji"])},
    "portfolio_gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "matchs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mecze"])},
    "transactions_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historia transakcji"])},
    "account_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustawienia konta"])},
    "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywowany"])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dezaktywowany"])},
    "alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powiadomienia"])},
    "alerts_clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń wszystko"])},
    "alerts_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na razie brak powiadomień"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz"])},
    "no_copy_paste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie można kliknąć prawym przyciskiem myszy"])},
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostawa"])},
    "secure3d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdzanie transakcji"])},
    "cancel_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj"])},
    "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Łącznie"])},
    "too_many_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Składasz zbyt wiele próśb"])},
    "see_products_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osiągnąłeś dzienny limit konsultacji. Sprawdź swoje konto, aby uniknąć dalszych ograniczeń."])},
    "check_inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdź swój wpis:"])},
    "generic_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd, spróbuj ponownie później"])},
    "login_retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaloguj się ponownie"])},
    "email_phone_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosimy o potwierdzenie adresu e-mail i telefonu przed wykonaniem tej czynności"])},
    "not_authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie jesteś upoważniony do wykonania tej czynności"])},
    "service_unaivalable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usługa niedostępna, prosimy o kontakt z działem obsługi klienta"])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsfeed"])},
    "lang": {
      "lang_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz język"])},
      "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR"])},
      "fr_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francuski"])},
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PL"])},
      "en_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angielski"])},
      "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ES"])},
      "es_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiszpański"])},
      "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT"])},
      "it_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Włoski"])},
      "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PL"])},
      "pl_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polski"])},
      "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DE"])},
      "de_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niemiecki"])},
      "cs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CS"])},
      "cs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czeski"])},
      "da": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DA"])},
      "da_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duński"])},
      "el": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EL"])},
      "el_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grecki"])},
      "hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HU"])},
      "hu_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Węgierski"])},
      "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JA"])},
      "ja_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japoński"])},
      "ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KO"])},
      "ko_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koreański"])},
      "pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PT"])},
      "pt_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugalski"])},
      "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WIELKA BRYTANIA"])},
      "ru_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rosyjski"])},
      "sk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SK"])},
      "sk_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Słowacki"])},
      "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZH"])},
      "zh_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiński"])}
    },
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bieżące zlecenia"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zastosuj"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywny"])},
    "refuse_match_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odrzuć dopasowanie"])},
    "date_expire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data wygaśnięcia"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "price_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena zawiera podatek VAT"])},
    "inputs_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdź, czy wszystkie wymagane pola zostały poprawnie wypełnione"])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zgłoś problem"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kupujący"])},
    "seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprzedawca"])},
    "code_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysłany kod"])},
    "code_time_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod jest ważny przez 15 minut"])},
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artykuły"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesjonalny"])},
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indywidualne"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfel"])},
    "my_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfel"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj"])},
    "error_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdź swój wpis lub skontaktuj się z obsługą klienta"])},
    "copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiowanie"])},
    "copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udana kopia"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtr :"])}
  },
  "major": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby odwiedzić tę stronę, musisz mieć ukończone 18 lat"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdź swój wiek, aby wejść."])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mam ukończone 18 lat"])},
    "nok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mam mniej niż 18 lat"])},
    "conditions_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzyskując dostęp do tej witryny, użytkownik wyraża zgodę na"])},
    "conditions_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["warunki użytkowania"])},
    "conditions_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oraz"])},
    "conditions_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["polityka prywatności."])}
  },
  "home": {
    "revolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rewolucja sama w sobie"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odniesienie do kupna, sprzedaży i handlu alkoholami"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niezależnie od tego, czy jesteś miłośnikiem whisky, kolekcjonerem koniaku, czy profesjonalistą poszukującym doskonałości, platforma Exchange jest dla Ciebie. Optymalne bezpieczeństwo, całkowita przejrzystość i niskie opłaty: odkryj handel alkoholami z całkowitym spokojem."])},
    "secure_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezpieczne transakcje"])},
    "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obniżone opłaty 2,5% z VAT"])},
    "verified_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W 100% zweryfikowane darmowe konta"])},
    "title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niezależnie od tego, czy jesteś osobą prywatną, czy profesjonalistą, zapoznaj się z naszym agregatem ponad 70 źródeł cen tysięcy alkoholi!"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Załóż darmowe konto"])},
    "buy_and_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kupno i sprzedaż"])},
    "buy_and_sell_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dzięki platformie The Exchange można kupować i sprzedawać w sposób ciągły. Łatwy dostęp do wszystkich dostępnych ofert dzięki naszym znormalizowanym referencjom. Znajdź to, czego szukasz lub wyraź swoje zainteresowanie, dopasuj wyszukiwanie kontrahentów, oszczędzaj czas i handluj po najlepszej cenie!"])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giełda"])},
    "swap_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jesteśmy jedyną platformą, która oferuje możliwość wymiany butelek przy użyciu naszego zabezpieczonego rozwiązania gotówkowego. Brak ryzyka kontrahenta, pieniądze są bezpieczne na platformie do momentu dostarczenia butelek."])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handel blokowy"])},
    "blocktrade_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zabezpiecz transakcję kupna lub sprzedaży za pomocą Block Trade, funkcji dostępnej wyłącznie na platformie The Exchange, która generuje unikalny link dla każdej transakcji."])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarządzanie portfelem"])},
    "portfolio_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dla bardziej doświadczonych kolekcjonerów i inwestorów platforma Exchange oferuje narzędzie do zarządzania portfelem, które pozwala monitorować swoje pozycje i ich wartości dzięki cenom na żywo z platformy i naszym danym historycznym. Koniec z niekończącymi się pytaniami o wartość butelek, uzyskaj obiektywny i agnostyczny widok, jak nigdy dotąd."])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historyczne dane cenowe"])},
    "price_history_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zebraliśmy najbardziej kompleksową historię cen wszystkich możliwych alkoholi, z cenami sięgającymi 2002 roku! Wszystko jest ustandaryzowane i dostępne w 18 różnych walutach."])},
    "title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gwarancja zaufania"])},
    "secure_transactions_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Płatności są dokonywane za pośrednictwem naszego dostawcy usług płatniczych Mangopay, aby zapewnić płynne transakcje, bez ryzyka zniknięcia pieniędzy, których nigdy nie wyślesz do kontrahenta."])},
    "low_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najniższe opłaty na rynku"])},
    "low_fees_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mamy najniższe opłaty na rynku, 2,5% z VAT dla kupującego i sprzedającego, brak opłat za wystawienie lub rezerwę. Płacisz tylko wtedy, gdy dojdzie do skutecznej transakcji."])},
    "anonymous_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonimowość przed transakcją"])},
    "anonymous_transactions_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niezależnie od tego, czy jesteś osobą prywatną, czy profesjonalistą, nasza platforma jest rozwiązaniem wszystkich dzisiejszych problemów dzięki anonimowości przedtransakcyjnej."])},
    "verified_accounts_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dzięki kontroli rejestracji przez naszego dostawcę usług płatniczych przed zatwierdzeniem konta, żadne konto fantomowe, oszust lub bot nie może wejść na platformę. Identyfikowalność i bezpieczeństwo są całkowite."])},
    "title_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferta dostosowana do Twoich potrzeb"])},
    "join_community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dołącz do społeczności, aby znaleźć, zarządzać i handlować swoimi alkoholami!"])},
    "search": {
      "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rodzaje alkoholi"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz"])},
      "bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Butelka"])},
      "search_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker, nazwa, EAN, słowa kluczowe..."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szukaj"])},
      "search_bottle_bottler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyszukiwanie w obrębie"])}
    },
    "without_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handel dostępny bez subskrypcji!"])}
  },
  "newsfeed": {
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog i recenzje"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsfeed"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wydarzenia"])},
    "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zresetuj filtry"])},
    "event_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
    "sort_asc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortuj alfabetycznie"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraj"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daty"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miesiąc"])},
    "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rodzaje alkoholi"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyszukiwanie..."])},
    "events_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtruj wydarzenia"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data rozpoczęcia"])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data zakończenia"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na"])},
    "events_show_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nadchodzące wydarzenia"])},
    "events_show_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nadchodzące wydarzenia"])}
  },
  "subscriptions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roczne subskrypcje"])},
    "price_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stawki roczne"])},
    "price_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stawki miesięczne"])},
    "free_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 miesiąc za darmo przy płatności rocznej"])},
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferta specjalna"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesjonalna oferta"])},
    "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historyczne ceny premium"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dla użytkowników, którzy chcą mieć pełne dane historyczne bez opóźnień."])},
    "all_taxs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])},
    "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["miesięcznie"])},
    "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rocznie"])},
    "per_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["według rodzaju alkoholu"])},
    "references_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostęp do wszystkich referencji"])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceny historyczne bez opóźnień"])},
    "advanced_stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaawansowane statystyki"])},
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subskrybuj alkohole"])},
    "choice_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybór alkoholi :"])},
    "wine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wina"])},
    "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inne"])},
    "complementary_module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moduł uzupełniający"])},
    "portfolio_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dla doświadczonych kolekcjonerów, którzy potrzebują więcej danych i prawdziwego narzędzia do zarządzania."])},
    "portfolio_subtitle_pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dołącz do społeczności specjalistów, aby analizować, zarządzać i handlować swoimi alkoholami!"])},
    "advanced_gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaawansowane zarządzanie portfelem"])},
    "choice_this_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz tę ofertę"])},
    "add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj do koszyka"])},
    "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koszyk"])},
    "see_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobacz"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miesięczna płatność"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roczna płatność"])},
    "sub_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma częściowa"])},
    "next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Następne próbkowanie"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Łącznie"])},
    "include_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT (20%)"])},
    "payment_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejdź do płatności"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń"])},
    "already_in_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta subskrypcja jest już w koszyku"])},
    "already_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jesteś już subskrybentem"])},
    "cart_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twój koszyk jest pusty"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powrót do subskrypcji"])},
    "contact_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje kontaktowe"])},
    "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozliczenia"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metoda płatności"])},
    "payment_method_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcje na platformie wymiany są przeprowadzane za pośrednictwem naszego dostawcy usług płatniczych Mangopay."])},
    "banking_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karta kredytowa"])},
    "card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer karty"])},
    "card_fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imię i nazwisko na karcie płatniczej"])},
    "card_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data wygaśnięcia (MM/YY)"])},
    "card_code_security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod bezpieczeństwa"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sfinalizuj zakup"])},
    "soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Już wkrótce"])},
    "recap_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podsumowanie płatności"])},
    "buy_commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prowizja za zakup"])},
    "buy_commission_tva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT Prowizja od zakupu"])},
    "already_sub_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subskrybent"])}
  },
  "payment": {
    "cvx_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod weryfikacyjny karty (znajdujący się na odwrocie karty, zazwyczaj 3-cyfrowy)."])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anulowanie zostało uwzględnione"])},
    "cancel_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anulowanie nie powiodło się, prosimy o kontakt z obsługą klienta"])}
  },
  "login": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Witamy na platformie wymiany"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostęp do płynnego, bezpiecznego i znormalizowanego środowiska handlowego."])},
    "save_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz mój login"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaloguj się"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapomniałeś hasła?"])},
    "forgot": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset hasła"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymasz wiadomość e-mail z instrukcjami, jak zresetować hasło."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź swój adres e-mail"])}
    },
    "reset": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset hasła"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymasz wiadomość e-mail z instrukcjami, jak zresetować hasło."])},
      "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowe hasło"])},
      "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdź hasło"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod weryfikacyjny"])}
    },
    "doubleFA": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podwójne uwierzytelnianie"])},
      "code_reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź kod otrzymany e-mailem lub telefonicznie"])}
    },
    "password_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasło musi zawierać 7 znaków, w tym co najmniej jedną wielką literę."])},
    "wrong_values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosimy o poprawne wypełnienie wszystkich pól"])},
    "wrong_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź prawidłowy kod"])}
  },
  "register": {
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Załóż konto"])},
    "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civility"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imię"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
    "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data urodzenia"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Narodowość"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraj"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
    "address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer ulicy"])},
    "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod pocztowy"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasło"])},
    "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdź hasło"])},
    "mangopay_accept_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akceptuję"])},
    "mangopay_accept_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["warunki użytkowania"])},
    "mangopay_accept_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mangopay."])},
    "tep_accept_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akceptuję"])},
    "tep_accept_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["warunki użytkowania"])},
    "tep_accept_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i"])},
    "tep_accept_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["polityka prywatności"])},
    "tep_accept_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de Platforma wymiany."])},
    "newsletter_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz się do naszego newslettera"])},
    "newsletter_enter_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź swój adres e-mail"])},
    "create_and_continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz moje darmowe konto i kontynuuj"])},
    "end_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakończ rejestrację"])},
    "verify_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzyskaj zweryfikowane konto"])},
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa firmy"])},
    "company_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pełny adres"])},
    "company_zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod pocztowy"])},
    "company_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "company_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraj"])},
    "company_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesjonalny e-mail"])},
    "company_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rodzaj firmy"])},
    "company_siret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer firmy"])},
    "company_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer VAT"])},
    "company_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moja firma"])},
    "company_siret_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["123 456 789 00010"])},
    "step": {
      "step_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje ogólne"])},
      "step_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdzenie 2FA"])},
      "step_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrola KYC"])},
      "step_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dane banku"])},
      "step_1_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje"])},
      "step_4_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dane banku"])}
    },
    "step_0": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostęp do płynnego, bezpiecznego i znormalizowanego środowiska handlowego."])},
      "usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz zastosowanie"])},
      "account_no_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po zakończeniu rejestracji nie można zmienić typu konta."])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osoby fizyczne"])},
      "professionnal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesjonalny"])},
      "individual_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto do użytku osobistego"])},
      "professionnal_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweryfikowane konto profesjonalne"])}
    },
    "step_1": {
      "general_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje ogólne"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wypełnij wszystkie poniższe pola"])},
      "personnal_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto prywatne"])},
      "business_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto korporacyjne"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz"])},
      "business_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoria profesjonalna"])},
      "business_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forma prawna"])},
      "business_part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Właściciele posiadający ponad 25% udziałów ?"])},
      "business_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosimy o podanie szczegółów dotyczących innych beneficjentów rzeczywistych posiadających ponad 25% udziałów."])},
      "add_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj"])},
      "birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["urodzony na"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamieszkaj w"])},
      "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" i narodowość"])},
      "password_requirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasło musi zawierać co najmniej 8 znaków, w tym wielką literę, małą literę, cyfrę i znak specjalny."])}
    },
    "step_2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdzenie adresu e-mail i numeru telefonu"])},
      "subtitle_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź kod otrzymany w wiadomości e-mail"])},
      "subtitle_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź kod otrzymany telefonicznie"])},
      "email_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysłano do Ciebie wiadomość e-mail."])},
      "phone_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiadomość SMS została wysłana."])},
      "no_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie otrzymałeś kodu?"])},
      "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij do"])},
      "validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdzone"])},
      "await_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oczekiwanie na potwierdzenie"])},
      "code_err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź prawidłowy kod"])}
    },
    "before_kyc": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gratulacje, masz już bezpłatny dostęp do wielu funkcji strony!"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy chcesz teraz zweryfikować swoją tożsamość, aby w pełni cieszyć się doświadczeniem?"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzyskaj zweryfikowane konto"])},
      "continue_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzyskaj bezpłatne zweryfikowane konto"])}
    },
    "step_3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weryfikacja tożsamości"])},
      "document_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz rodzaj dokumentu"])},
      "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paszport"])},
      "identity_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dowód tożsamości"])},
      "import_file_passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaimportuj zdjęcie z paszportu"])},
      "import_file_identity_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaimportuj zdjęcie obu stron swojego dowodu osobistego"])},
      "choice_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz plik"])},
      "condition_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejrzysty i czytelny plik"])},
      "condition_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozmiar pliku maks. 7 Mb"])},
      "condition_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format JPG, JPEG lub PNG"])},
      "validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prześlij do weryfikacji"])},
      "verification_timing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średni czas weryfikacji wynosi około 24 godzin."])},
      "active_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ograniczona funkcjonalność"])},
      "description_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukończ proces rejestracji i uzyskaj dostęp do wszystkich funkcji."])},
      "documents_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysyłane dokumenty"])},
      "verification_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obecnie sprawdzane są"])},
      "verification_in_progress_restriction_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Można wyszukiwać butelki i sprawdzać ceny historyczne."])},
      "verification_in_progress_restriction_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie można jeszcze uzyskać dostępu do części transakcyjnej ani wykupić subskrypcji."])},
      "send_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysłane na"])},
      "kbis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyciąg z Kbis lub równoważny dokument sprzed mniej niż trzech miesięcy"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopia umowy spółki"])},
      "passport_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paszport lub dowód osobisty, obie strony"])},
      "document_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument wysłany:"])},
      "documents_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przesyłane dokumenty:"])},
      "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odmowa"])},
      "refused_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powód odmowy:"])},
      "IDENTITY_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tożsamość osobista"])},
      "REGISTRATION_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tożsamość korporacyjna"])},
      "ARTICLES_OF_ASSOCIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
      "SHAREHOLDER_DECLARATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deklaracja akcjonariusza"])},
      "ADDRESS_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weryfikacja adresu"])},
      "payment_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejdź do płatności"])},
      "error_card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieprawidłowy numer karty"])},
      "error_card_fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imię i nazwisko na nieważnej karcie"])},
      "error_card_expiration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieprawidłowa data wygaśnięcia"])},
      "error_card_cvx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieprawidłowy kod karty kredytowej"])},
      "title_payment_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opłaty KYC dla firm"])},
      "kyc_documents_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumenty zostały wysłane pomyślnie. Zatwierdzenie nastąpi w ciągu 24 godzin."])},
      "ubo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deklaracja właścicieli rzeczywistych"])},
      "verification_timing_individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średni czas weryfikacji to zaledwie kilka minut"])},
      "kyc_explain_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rób wyraźne, ostre zdjęcia:"])},
      "kycexplain_content_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upewnij się, że dowód osobisty lub paszport jest w pełni widoczny, bez cieni i odbić, a wszystkie szczegóły są czytelne."])},
      "kyc_explain_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij w kompatybilnym formacie:"])},
      "kycexplain_content_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Należy użyć formatu JPEG, JPG lub PNG o wystarczającej rozdzielczości, aby informacje były wyraźnie widoczne."])},
      "kyc_explain_title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdź pełny dokument :"])},
      "kycexplain_content_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upewnij się, że wszystkie niezbędne strony są zawarte osobno."])}
    },
    "step_4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dane banku"])},
      "why_enter_banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź swoje dane bankowe, aby otrzymać pieniądze ze sprzedaży."])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoje dane zostaną zweryfikowane przez nasz dział obsługi klienta. Po zatwierdzeniu danych bankowych otrzymasz wiadomość e-mail."])},
      "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "iban_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN, numer konta"])},
      "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])},
      "bic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC, ABA"])},
      "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
      "region_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosimy o wypełnienie"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prześlij"])},
      "mangopay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcje na platformie wymiany są przeprowadzane za pośrednictwem naszego dostawcy usług płatniczych Mangopay."])},
      "banking_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoje dane bankowe zostały zapisane"])},
      "banking_already_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoje dane bankowe zostały już wysłane"])}
    },
    "success": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gratulacje! Twoje konto zostało zweryfikowane."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przeglądaj butelki"])},
      "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostęp do mojego portfolio"])},
      "unlock_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieograniczony tryb aktywny"])},
      "unlock_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teraz można uzyskać dostęp do pełnego zakresu funkcji."])}
    },
    "us_phone_regulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyrażam zgodę na otrzymywanie wiadomości od The Exchange Platform na powyższy numer telefonu. Mogą obowiązywać opłaty za transmisję danych, odpowiedz STOP, aby zrezygnować z subskrypcji."])},
    "us_phone_regulation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli odrzucisz wiadomości SMS 2FA, nie będziesz mógł potwierdzić swojego konta. Zawsze możesz zrezygnować z subskrypcji po potwierdzeniu numeru telefonu za pośrednictwem naszego systemu SMS 2FA."])},
    "birth_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miejsce narodzin"])},
    "birth_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraj urodzenia"])},
    "company_siret_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer SIRET"])}
  },
  "search": {
    "barcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeskanuj lub wpisz kod kreskowy"])},
    "last_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnie wyszukiwania"])},
    "delete_all_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń wszystko"])},
    "find_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wyświetlany produkt"])},
    "find_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wyświetlane produkty"])},
    "total_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["produkt znaleziony za pomocą tego wyszukiwania"])},
    "total_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkty znalezione za pomocą tego wyszukiwania"])},
    "total_product_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["znaleziony produkt"])},
    "total_products_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["znalezione produkty"])},
    "empty_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brakująca butelka?"])},
    "filter_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtruj według"])},
    "see_composite_ticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobacz wszystko"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kupno"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprzedaż"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taker"])},
    "giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dawca"])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handel blokowy"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
    "no_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono butelek pasujących do wyszukiwania."])},
    "history_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobacz historię wyszukiwania"])},
    "no_auth_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz konto, aby odblokować nieograniczony dostęp"])},
    "no_auth_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarejestruj się za darmo"])},
    "connect_access_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta funkcja wymaga zalogowania"])},
    "empty": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brakująca butelka"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa butelki"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W razie potrzeby podaj szczegóły brakującej butelki"])},
      "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcjonalnie"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
      "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.thexchangeplatform.io"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij"])},
      "missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono żadnych wyników."])},
      "description_placeholder_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Można opisać powody odmowy"])}
    },
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bieżące zlecenia"])},
    "bottler_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strona dystrybutora"])},
    "search_explain_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Można używać kombinacji słów kluczowych."])},
    "search_explain_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W wyszukiwaniu nie jest rozróżniana wielkość liter."])},
    "search_explain_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie ma potrzeby określania \"lat\"."])},
    "search_explain_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wpisanie ogólnej kategorii alkoholi (rum, whisky, koniak itp.) nie przyniesie żadnych wyników."])}
  },
  "product_page": {
    "add_to_portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj do portfolio"])},
    "btn_buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kupno/Sprzedaż"])},
    "btn_swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap"])},
    "btn_blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block Trade"])},
    "product_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje o butelkach"])},
    "ticker_composite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker composite"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tytuł"])},
    "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rodzaje alkoholi"])},
    "distillery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distillerie"])},
    "bottler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottler"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiek"])},
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marka"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abv"])},
    "vintage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vintage"])},
    "bottling_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rok butelkowania"])},
    "bottle_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zawartość"])},
    "num_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer butelki"])},
    "num_bottles_produced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba wyprodukowanych butelek"])},
    "base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baza"])},
    "production_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metoda produkcji"])},
    "distillery_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status destylarni"])},
    "classification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klasyfikacja"])},
    "empty_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak zamówienia na tę butelkę"])},
    "composite_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker composite"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywny"])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieaktywny"])},
    "search_add_bottle_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyszukaj i dodaj butelkę do wykresu"])},
    "isbn_ticker_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN, ticker lub nazwa produktu"])},
    "bottle_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Butelka, której szukasz, nie została znaleziona"])},
    "trades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprzedaż"])},
    "sources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Źródła"])},
    "empty_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak dostępnego obrazu dla tego odniesienia"])},
    "cask_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer beczki"])},
    "show_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobacz ceny historyczne"])},
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zlecenia na żywo"])},
    "number_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba butelek"])},
    "orders": {
      "buy_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kupno"])},
      "sell_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprzedaż"])},
      "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz zamówienie"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indywidualne"])},
      "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro"])},
      "mix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mix"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak zamówień w tej chwili."])},
      "buy_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zlecenie kupna"])},
      "buy_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena zakupu"])},
      "buy_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilość"])},
      "buy_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcje"])},
      "buy_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaplanować datę i godzinę zakończenia?"])},
      "buy_create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz zlecenie zakupu"])},
      "sell_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zlecenie sprzedaży"])},
      "sell_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cennik"])},
      "sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilość"])},
      "sell_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waga"])},
      "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Długość"])},
      "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerokość"])},
      "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysokość"])},
      "sell_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj zdjęcia"])},
      "sell_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcje"])},
      "sell_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaplanować datę i godzinę zakończenia?"])},
      "sell_create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz zlecenie sprzedaży"])},
      "success_create_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamówienie zostało pomyślnie utworzone"])},
      "ft_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zasady, których należy przestrzegać jako"])},
      "buy_ft_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unikaj niepotrzebnego zalewania księgi zleceń"])},
      "buy_ft_1_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogranicz swoje zlecenia do rzeczywistych transakcji."])},
      "buy_ft_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unikaj powtarzających się odmów"])},
      "buy_ft_2_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powtarzające się odmowy dopasowania mogą być postrzegane jako złośliwe lub nieodpowiedzialne. Upewnij się, że zawierasz transakcje tylko wtedy, gdy zamierzasz je sfinalizować."])},
      "buy_ft_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szanuj czasy odzyskiwania"])},
      "buy_ft_3_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powtarzające się nieodbieranie paczek na czas może prowadzić do niedogodności dla sprzedawcy i dodatkowych kosztów."])},
      "buy_ft_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szanuj integralność księgi zamówień"])},
      "buy_ft_4_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oszustwa lub manipulowanie księgą zleceń, dezaktywacja zainteresowania po odrzuceniu dopasowania itp. są uważane za zwodnicze praktyki. Upewnij się, że Twoje działania są przejrzyste."])},
      "buy_ft_5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bądź przygotowany na związane z tym koszty"])},
      "buy_ft_5_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogą obowiązywać lokalne podatki importowe i celne. Upewnij się, że jesteś przygotowany na ich zapłacenie podczas zawierania transakcji."])},
      "sell_ft_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostarczanie dokładnych informacji"])},
      "sell_ft_1_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upewnij się, że opis butelki jest dokładny i zgodny z prawdą. Pomaga to kupującym podjąć świadomą decyzję. Zapewnij pełne zdjęcie z przodu i z tyłu."])},
      "sell_ft_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szanuj szczegóły oferty"])},
      "sell_ft_2_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobowiązujesz się do sprzedaży butelki, dotrzymaj tego zobowiązania. Każda oferta, która nie zostanie dotrzymana, będzie miała konsekwencje dla sprzedającego."])},
      "sell_ft_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gwarancja jakości opakowania i jego zawartości"])},
      "sell_ft_3_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powtarzające się dostawy uszkodzonych paczek (zniszczenie, pęknięcie, wyciek) i nieprawidłowa zawartość mają wpływ na Ciebie. Upewnij się, że butelki są starannie zapakowane."])},
      "sell_ft_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szanuj czas dostawy"])},
      "sell_ft_4_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powtarzający się brak wysyłki na czas może wpłynąć na dostęp do obszaru transakcji. Zaplanuj odpowiednio, aby uniknąć opóźnień."])},
      "create_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz zlecenie kupna"])},
      "create_sell_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz zlecenie sprzedaży"])},
      "create_taker_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz zlecenie Swap Taker"])},
      "create_giver_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz zlecenie Swap Giver"])},
      "create_blocktrade_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz transakcję pakietową"])},
      "weight_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednostka wagi"])},
      "size_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wielkość jednostki"])},
      "success_create_dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoje zgłoszenie zostało przetworzone. Wkrótce skontaktujemy się z Tobą"])},
      "package_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odniesienie do pakietu:"])},
      "dispute_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutaj możesz szczegółowo opisać swój problem, aby ułatwić rozwiązanie zgłoszenia"])},
      "confirm_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdź odbiór paczki"])},
      "confirm_shipment_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdzając odbiór przesyłki, potwierdzasz, że produkt spełnia Twoje oczekiwania."])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywa"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunięto"])},
      "match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dopasowanie"])},
      "match_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oczekiwanie"])},
      "ship_send_pending_buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysyłka"])},
      "ship_send_pending_seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij do"])},
      "delivery_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostawa"])},
      "delivery_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostarczone"])},
      "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spory sądowe"])},
      "end_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakończone"])},
      "update_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj"])},
      "shipping_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szczegóły dostawy"])},
      "track_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer śledzenia"])},
      "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Płatne"])},
      "track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Śledź moją przesyłkę"])},
      "shipping_details_see": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobacz szczegóły dostawy"])},
      "in_transit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W dostawie"])},
      "order_create_block_by_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosimy o zaakceptowanie lub odrzucenie propozycji przed utworzeniem nowego zlecenia"])},
      "blocktrade_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link Block Trade"])},
      "blocktrade_unique_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unikalny link"])},
      "blocktrade_secure_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezpieczna transakcja z naszym dostawcą płatności Mangopay"])},
      "delete_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń"])},
      "shipping_download_sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pobierz dokument dostawy"])},
      "bo_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weryfikacja"])},
      "anonymous_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonimowa transakcja (3 EUR z VAT)"])},
      "max_min_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musisz przesłać zdjęcia butelki (maksymalnie: 8)"])},
      "duplicate_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie można umieścić tego samego zdjęcia więcej niż raz"])},
      "pictures_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akceptowane są tylko obrazy mniejsze niż 7mb i z rozszerzeniem .JPG, .JPEG i .png."])},
      "add_min_one_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoje zamówienie nie może być ważne krócej niż godzinę"])},
      "empty_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na chwilę obecną brak zleceń kupna."])},
      "empty_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na razie brak zleceń sprzedaży."])},
      "anonymous_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kupujący nie pozna Twojej tożsamości"])},
      "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolekcja domowa"])},
      "pickup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paczka jest odbierana z domu przez firmę UPS"])},
      "pickup_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data pobrania"])},
      "pickup_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czas pierwszego odbioru"])},
      "pickup_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatni czas odbioru"])},
      "not_match_preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To zlecenie nie jest zgodne z preferencjami kraju użytkownika lub kontrahenta"])},
      "load_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj zdjęcia"])},
      "or_drag_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lub przeciągnij i upuść"])},
      "file_format_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tylko .JPG, .PNG i .JPEG (maks. 7 MB na zdjęcie)"])},
      "pickup_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostępne w punktach przekaźnikowych"])},
      "kyc_not_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby uzyskać dostęp do sekcji transakcji, należy zweryfikować swoją tożsamość."])},
      "kyc_redirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdź moją tożsamość"])}
    },
    "price_history": {
      "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceny historyczne"])},
      "compare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj butelkę"])},
      "responsability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wykresy mają charakter wyłącznie informacyjny. Nie ponosimy odpowiedzialności za jakiekolwiek błędy."])},
      "data_info_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nasze dane historyczne agregują"])},
      "data_info_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sprzedaż"])},
      "data_info_11-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sprzedaż"])},
      "data_info_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z"])},
      "data_info_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["różne źródła."])},
      "data_info_3-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["źródło."])},
      "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waluta"])},
      "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opłaty"])},
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średnia"])},
      "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top"])},
      "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bas"])},
      "base_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base 100"])},
      "line_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wykres liniowy"])},
      "candlesticks_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wykres świecowy"])},
      "chart_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcje wykresów"])},
      "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["giełda"])},
      "perf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wydajność"])},
      "since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z"])},
      "delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceny są wyświetlane z opóźnieniem"])},
      "delay_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["miesiąc"])},
      "no_recent_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dane historyczne cen nie są dostępne. Jeśli jeszcze ich nie uwzględniliśmy, prosimy o kontakt z pomocą techniczną w celu ich dodania."])},
      "current_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualny widok"])},
      "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codziennie"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miesięcznie"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corocznie"])},
      "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co tydzień"])},
      "compare_dialog": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porównaj z"])},
        "no_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak wybranych butelek"])},
        "same_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta butelka jest już na wykresie"])},
        "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osiągnąłeś limit 6 butelek na wykresie"])}
      },
      "composite": {
        "display_composite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Composite"])}
      }
    },
    "report": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zgłoś błąd"])},
      "error_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rodzaj błędu"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uwagi"])},
      "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcjonalnie"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opisz problem dokładniej tutaj..."])},
      "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.thexchangeplatform.io"])},
      "pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrzuty ekranu / obrazy"])},
      "pictures_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksymalnie 2 zdjęcia."])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij"])}
    },
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj"])},
    "no_bottles_found_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono butelki dla tego odniesienia."])},
    "shipping_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szczegóły dostawy"])},
    "success_send_error_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoja prośba została uwzględniona"])},
    "release_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data wydania"])},
    "release_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceny wyjściowe"])}
  },
  "matchs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mecze"])},
    "match_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dopasuj kupującego"])},
    "match_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dopasuj sprzedawcę"])},
    "accept_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kupno"])},
    "accept_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akceptuj i sprzedawaj"])},
    "show_transactions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zobacz moje ", _interpolate(_named("num_transac")), " bieżące transakcje"])},
    "show_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokaż zdjęcia"])},
    "buying_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje zlecenie kupna"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kupujący"])},
    "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W toku"])},
    "selling_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zlecenie sprzedaży"])},
    "buying_order_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zlecenie kupna"])},
    "sold_by_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrahent"])},
    "selling_order_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje zlecenie sprzedaży"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak dopasowań do wyświetlenia."])}
  },
  "account_verification_details": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tworzenie konta i funkcje dostępne dla wszystkich"])},
    "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funkcje"])},
    "unverified_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieaudytowane konto"])},
    "verified_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweryfikowane konto"])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsfeed"])},
    "full_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezpłatny i nieograniczony pełny dostęp"])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceny historyczne"])},
    "advanced_visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaawansowana wizualizacja"])},
    "month_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15-miesięczne odroczenie"])},
    "month_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 miesięcy odroczenia"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "portfolio_month_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15-miesięczna odroczona wycena"])},
    "portfolio_month_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12-miesięczna odroczona wycena"])},
    "tickers_limit_50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50 unikalnych tickerów"])},
    "tickers_limit_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100 unikalnych tickerów"])},
    "database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baza danych"])},
    "tickers_limit_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50 notowań dziennie"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handel"])},
    "orders_visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobacz aktywne zlecenia"])},
    "orders_not_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak dostępu transakcyjnego"])},
    "kyc_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weryfikacja tożsamości jest bezpłatna dla osób fizycznych i podlega opłacie dla profesjonalistów. KYC przeprowadzane przez naszego dostawcę usług płatniczych Mangopay."])},
    "not_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieograniczony pełny dostęp"])}
  },
  "portfolio": {
    "copied_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID skopiowane"])},
    "folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plik"])},
    "import_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import pliku CSV lub Excel"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj"])},
    "bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Butelka"])},
    "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz wszystko"])},
    "unselect_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odznacz wszystkie"])},
    "research_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyszukaj butelkę"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "to_guide_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby Cię poprowadzić,"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pobierz"])},
    "load_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prześlij plik"])},
    "or_drag_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lub przeciągnij i upuść"])},
    "file_csv_and_xlsx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tylko pliki CSV i XLSX"])},
    "an_import_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["model importu"])},
    "columns_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolumny"])},
    "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj"])},
    "average_holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średni okres posiadania"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiana nazwy"])},
    "move_in_another_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejdź do innego folderu"])},
    "rename_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiana nazwy folderu"])},
    "empty_folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa pliku jest wymagana"])},
    "max_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksymalna liczba znaków nie może przekraczać 30"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametry"])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statystyki"])},
    "column_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolumny tabeli"])},
    "options_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabela opcji"])},
    "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcje"])},
    "foldAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Złóż wszystko"])},
    "unfoldAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozwiń wszystko"])},
    "show_sell_stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokaż sprzedane akcje"])},
    "bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Butelki"])},
    "tickers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickers"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infos"])},
    "th_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "th_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilość"])},
    "th_net_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena netto"])},
    "th_gross_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena brutto"])},
    "price_with_commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prowizja"])},
    "th_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opłaty"])},
    "th_storage_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miejsce przechowywania"])},
    "th_holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holding"])},
    "th_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Załączniki"])},
    "th_buy_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gdzie kupić"])},
    "add_folder_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj plik"])},
    "add_bottle_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj butelkę"])},
    "name_parent_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa folderu nadrzędnego"])},
    "additional_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatkowe kolumny"])},
    "column_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamówienie kolumnowe"])},
    "my_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje butelki"])},
    "purchaseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data zakupu"])},
    "addPriceOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj opcje cenowe"])},
    "sellDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data sprzedaży"])},
    "swap_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data wymiany"])},
    "sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprzedaż"])},
    "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kupno"])},
    "no_bottles_found_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono butelek pasujących do wyszukiwania."])},
    "select_parent_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz folder nadrzędny"])},
    "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa pliku"])},
    "create_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Załóż konto"])},
    "modify_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmień butelkę"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis"])},
    "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W toku"])},
    "bottle_on_hold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posiadane butelki"])},
    "bottle_on_hold_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posiadane butelki"])},
    "unique_references_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unikalne referencje"])},
    "on_position_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posiadane butelki pomnożone przez odpowiednią cenę zakupu"])},
    "AUM_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posiadane butelki pomnożone przez wartość rynkową"])},
    "running_pnl_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus valeur en cours, różnica między wartością rynkową a ceną zakupu pomnożona przez ilość."])},
    "running_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bieżące wyniki"])},
    "running_year_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualna roczna stopa zwrotu"])},
    "average_price_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średnia cena wszystkich butelek"])},
    "average_holding_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średni czas, przez jaki pakiet kupującego pozostaje w portfelu"])},
    "bought_bottles_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dotychczas zakupione butelki"])},
    "sold_bottles_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoje butelki sprzedane do tej pory"])},
    "total_spent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowite dotychczasowe wydatki"])},
    "total_sold_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowita dotychczasowa sprzedaż"])},
    "total_pnl_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skumulowany zysk od pierwszego dnia do dnia dzisiejszego"])},
    "total_pnl_period_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrealizowany zysk w wybranym okresie"])},
    "realized_total_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwrot obliczany od pierwszego dnia (uwzględnia tylko sprzedane butelki)"])},
    "realized_year_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efektywny roczny zysk (sprzedane butelki)"])},
    "unique_references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unikalne referencje"])},
    "on_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wartość początkowa pozycji"])},
    "running_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bieżące wyniki"])},
    "running_year_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roczna stopa zwrotu"])},
    "realized_year_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historyczna roczna stopa zwrotu"])},
    "realized_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historyczny zrealizowany zwrot"])},
    "average_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średnia cena"])},
    "historical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historia"])},
    "bought_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakupione butelki"])},
    "unique_ticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyjątkowy ticker"])},
    "AUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wycena"])},
    "running_pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utajony zysk"])},
    "sold_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprzedane butelki"])},
    "total_spent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowite wydatki"])},
    "total_pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowity zrealizowany zysk"])},
    "total_pnl_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrealizowany zysk (okres)"])},
    "realized_total_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historyczny zrealizowany zwrot"])},
    "total_sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowita sprzedaż"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data kupna/sprzedaży"])},
    "net_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena netto"])},
    "price_without_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceny z wyłączeniem."])},
    "price_with_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceny z opłatami"])},
    "price_with_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceny z podatkiem VAT"])},
    "price_net": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena netto"])},
    "price_gross_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena zakupu brutto"])},
    "price_gross_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena sprzedaży brutto"])},
    "storage_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miejsce przechowywania"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilość"])},
    "boughts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kupno"])},
    "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprzedaż"])},
    "details_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dane referencyjne"])},
    "are_you_sure_delete_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy na pewno chcesz usunąć ten plik?"])},
    "xlsx_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plik Excel"])},
    "file_optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wskazówki dotyczące pliku Excel"])},
    "displayed_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyświetlane kolumny"])},
    "check_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz wszystko"])},
    "stockage_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wykorzystywana przestrzeń"])},
    "add_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Załóż konto"])},
    "add_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj butelkę"])},
    "import_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import pliku"])},
    "current_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualna butelka"])},
    "current_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualne butelki"])},
    "current_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualne referencje"])},
    "current_references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualne referencje"])},
    "current_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualna dokumentacja"])},
    "move_to_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejdź do folderu"])},
    "move_bottles_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["butelki w folderze"])},
    "move_references_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["referencje w dokumentacji"])},
    "move_bottle_to_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejdź do folderu"])},
    "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opłaty"])},
    "holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stan posiadania (dzień)"])},
    "files_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Załączniki"])},
    "buy_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gdzie kupić"])},
    "sell_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miejsce sprzedaży"])},
    "sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprzedana ilość"])},
    "wab_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena WAB"])},
    "wasd_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nagrody WASD"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoria"])},
    "sub_asset_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klasa aktywów"])},
    "see_product_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejdź do karty produktu"])},
    "last_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnia cena"])},
    "volatility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmienność"])},
    "uncheck_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odznacz wszystkie"])},
    "selected_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wybrane butelki"])},
    "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruch"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń"])},
    "delete_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń butelkę"])},
    "tel_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio jest obecnie dostępne tylko na PC, ale wkrótce będzie dostępne na urządzeniach mobilnych."])},
    "volatility_portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmienność portfela"])},
    "volatility_spirits_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmienność cen napojów spirytusowych"])},
    "volatility_distillery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmienność destylarni"])},
    "volatility_vintage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmienność rocznika"])},
    "volatility_age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiek zmienności"])},
    "portfolio_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio premium już wkrótce"])},
    "see_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobacz szczegóły"])},
    "go_to_product_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejdź do strony produktu"])},
    "folder_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utworzony plik"])},
    "folder_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plik został zmodyfikowany"])},
    "folder_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plik został usunięty"])},
    "folder_created_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd podczas tworzenia pliku"])},
    "bottle_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Butelka została dodana"])},
    "bottle_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Butelka została zmodyfikowana"])},
    "bottle_move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Butelka została przeniesiona"])},
    "file_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plik został pomyślnie zaimportowany"])},
    "move_modal_title_origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oryginalny plik :"])},
    "move_modal_content_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Będziesz przenosić ten folder i wszystkie znajdujące się w nim butelki."])},
    "move_folder_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twój plik został przeniesiony"])},
    "folder_contain_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niemożliwe do usunięcia, ponieważ w tym folderze znajdują się butelki"])}
  },
  "bid": {
    "send_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij ofertę"])}
  },
  "create_order": {
    "file_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dozwolone są tylko pliki .jpg, .jpeg i .png o rozmiarze mniejszym niż 3 MB."])}
  },
  "settings": {
    "title_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustawienia konta"])},
    "title_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarządzanie subskrypcją"])},
    "my_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje subskrypcje"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metoda płatności"])},
    "invoice_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historia faktur"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje konto"])},
    "buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kupno i sprzedaż"])},
    "bank_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dane banku"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezpieczeństwo i 2FA"])},
    "personnal_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
    "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civility"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Narodowość"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imię"])},
    "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data urodzenia"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer telefonu"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
    "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod pocztowy"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraj"])},
    "other_delivery_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj kolejny adres dostawy"])},
    "delivery_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres dostawy"])},
    "delivery_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń adres dostawy"])},
    "billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres rozliczeniowy"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj"])},
    "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysłane do"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak informacji"])},
    "fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posiadacz konta"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysłane na"])},
    "update_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmień swój RIB"])},
    "2fa_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podwójne uwierzytelnianie"])},
    "2fa_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymuj kod za każdym razem, gdy się logujesz i za każdym razem, gdy dokonujesz zmiany na swoim koncie"])},
    "2fa_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podwójne uwierzytelnianie przez e-mail"])},
    "2fa_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podwójne uwierzytelnianie przez SMS"])},
    "login_history_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najnowsze połączenia"])},
    "login_history_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyświetl historię ostatnich 10 połączeń z kontem na wszystkich urządzeniach."])},
    "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymuj newsletter"])},
    "update_dialog": {
      "title_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiana adresu e-mail"])},
      "actual_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualny adres e-mail"])},
      "new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowy adres e-mail"])},
      "title_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiana numeru telefonu"])},
      "actual_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualny numer telefonu"])},
      "new_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowy numer telefonu"])},
      "title_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj adres dostawy"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarejestruj się"])},
      "err_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieprawidłowy adres e-mail"])},
      "err_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieprawidłowy numer telefonu"])},
      "err_email_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masz już ten adres e-mail"])},
      "err_phone_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masz już ten numer telefonu"])}
    },
    "subscriptions": {
      "last_month_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wydatki w bieżącym miesiącu"])},
      "sub_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subskrybent od"])},
      "never_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie jesteś subskrybentem"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roczne subskrypcje"])},
      "next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data następnej zbiórki"])},
      "subscription_term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subskrypcja do"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrezygnuj z subskrypcji"])},
      "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przeglądaj dostępne subskrypcje cen historycznych"])},
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrezygnuj z subskrypcji"])},
      "cancel_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subskrypcja wygaśnie w dniu"])},
      "cancel_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subskrypcja dotyczy"])},
      "cancel_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdź anulowanie"])},
      "reactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wznów subskrypcję"])},
      "reactive_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontynuuj subskrypcję"])},
      "reactive_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Następna płatność zostanie dokonana w dniu"])},
      "reactive_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subskrypcja dotyczy"])},
      "reactive_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Płatność będzie dokonywana co"])},
      "reactive_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["po cenie"])},
      "reactive_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdzenie ożywienia"])},
      "commitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaangażowany do"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["miesiąc"])},
      "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ans"])},
      "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historyczne ceny premium"])}
    },
    "invoices": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historia faktur"])},
      "download_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pobierz wszystko"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceny"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subskrypcja"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pobierz fakturę"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Płatne"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie masz faktury"])},
      "delivery_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szczegóły dostawy"])},
      "track_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Śledź pakiet"])},
      "delivery_estimation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szacowana dostawa"])},
      "transaction_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nr transakcji"])}
    },
    "confirm": {
      "subtitle_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź kod otrzymany pocztą elektroniczną"])},
      "subtitle_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź kod otrzymany telefonicznie"])},
      "new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowy e-mail"])},
      "new_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowy telefon"])},
      "code_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź kod otrzymany pocztą elektroniczną"])},
      "code_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź kod otrzymany telefonicznie"])}
    },
    "billing_address_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faktura i adres dostawy"])},
    "card_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karta debetowa nr."])},
    "card_created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodano na"])},
    "card_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj"])},
    "card_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wygasa w dniu"])},
    "card_update_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmień moją kartę bankową"])},
    "card_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie zarejestrowałeś jeszcze karty płatniczej."])},
    "delivery_choice_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustaw ten adres jako domyślny"])},
    "delivery_is_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferowany adres dostawy"])},
    "preferences_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoje preferencje zostały pomyślnie zarejestrowane"])},
    "birth_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miasto urodzenia"])},
    "birth_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraj urodzenia"])},
    "preferences_mails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powiadomienia"])},
    "preference_t_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tworzenie zlecenia kupna"])},
    "preference_t_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tworzenie zlecenia sprzedaży"])},
    "preference_t_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tworzenie zlecenia wymiany"])},
    "preference_t_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tworzenie zlecenia typu blocktrade"])},
    "preference_t_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kupujący odrzucił ofertę"])},
    "preference_t_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zlecenie sprzedaży oczekuje na zatwierdzenie"])},
    "preference_t_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoryzuj otrzymywanie wiadomości SMS (walidacja konta i/lub 2FA)"])},
    "preferences": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferencje dotyczące komunikacji"])}
    },
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
    "relay_ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS Relay Point"])},
    "buySellPreferences": {
      "buying_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcje zakupu"])},
      "selling_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcje sprzedaży"])},
      "buying_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widoczność zakupów"])},
      "selling_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widoczność sprzedaży"])},
      "country_exclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraje do wykluczenia"])},
      "country_inclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraje do uwzględnienia"])},
      "preferences_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skopiuj moje preferencje zakupowe dla sprzedaży"])},
      "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoje zmiany zostały uwzględnione"])}
    }
  },
  "footer": {
    "social_medias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sieci społecznościowe"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wsparcie"])},
    "legals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legalny"])},
    "help_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centrum pomocy"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "recruitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekrutacja"])},
    "bug_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zgłoś błąd"])},
    "service_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status usług"])},
    "legal_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje prawne"])},
    "cgu_cgv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZASADY I WARUNKI"])},
    "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarządzanie plikami cookie"])},
    "tep_footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["spółka zarejestrowana we Francji"])}
  },
  "paiment_process": {
    "shipping_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opłaty za dostawę"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podatki"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kupno"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuse"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podsumowanie zamówienia"])},
    "sold_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprzedawana część :"])},
    "delivery_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opłaty za dostawę"])},
    "selling_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opłaty za sprzedaż"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowita kwota"])}
  },
  "payment_process": {
    "transaction_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szczegóły transakcji"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podsumowanie zamówienia"])},
    "shipping_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koszty wysyłki"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podatek"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kupno"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprzedaż"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuse"])},
    "sold_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprzedawca :"])},
    "delivery_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opłaty za dostawę"])},
    "selling_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opłaty transakcyjne"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowita kwota"])},
    "price_tva_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W tym podatek VAT w"])},
    "shipping_ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opłaty za dostawę UPS"])},
    "payment_type_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metoda płatności"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metoda płatności"])},
    "inssurance_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubezpieczenie UPS"])},
    "ups_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS w domu"])},
    "ups_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS point relais"])},
    "relay_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostępne punkty wypłat"])},
    "save_relay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz ten adres do przyszłych zakupów"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcja nie powiodła się. Spróbuj ponownie"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcja została pomyślnie zakończona"])},
    "payment_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoja płatność jest weryfikowana"])},
    "payment_please_wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poczekaj kilka sekund"])}
  },
  "orders": {
    "swap_taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap Taker"])},
    "swap_giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap Giver"])},
    "account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rodzaj konta"])},
    "current_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bieżące zlecenia"])},
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kierunek"])},
    "creation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data utworzenia"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceny"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilość"])},
    "update_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modyfikacja zlecenia kupna"])},
    "update_sell_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modyfikacja zlecenia sprzedaży"])},
    "update_block_trade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modyfikowanie transakcji blokowych"])},
    "update_swap_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modyfikacja zlecenia Swap"])},
    "display_deleted_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokaż usunięte zlecenia"])},
    "wish_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chciałbym otrzymywać"])},
    "possess_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posiadam"])},
    "in_exchange_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["w zamian za"])},
    "that_trade_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co chciałbym wymienić na"])},
    "update_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamówienie zostało pomyślnie zmodyfikowane"])},
    "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamówienie zostało pomyślnie utworzone"])},
    "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowita kwota :"])},
    "total_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba zleceń :"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tytuł, cena..."])}
  },
  "match": {
    "refuse_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powód odmowy"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuse"])}
  },
  "transactions": {
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sens"])},
    "dispute_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trwające spory sądowe"])},
    "dispute_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogłosić spór"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie są przeprowadzane żadne transakcje."])}
  },
  "blog": {
    "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zresetuj filtry"])},
    "published_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opublikowano na :"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmodyfikowano na :"])},
    "articles_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtruj artykuły"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono żadnych przedmiotów."])}
  },
  "wallet": {
    "available_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostępna kwota"])},
    "waiting_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na poczekaniu"])},
    "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowita kwota"])},
    "withdraw_money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wypłata"])},
    "money_movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruchy"])},
    "payin_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokonana płatność"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak transakcji do wyświetlenia."])},
    "transfert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer"])},
    "transfert_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer przychodzący"])},
    "transfert_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przelew wychodzący"])},
    "payout_seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Płatność dla sprzedającego"])},
    "bank_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wypłata"])},
    "bank_payout_in_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wypłata na moje konto bankowe"])},
    "payout_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wypłata dostępnych środków"])},
    "payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdź wypłatę"])},
    "success_payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przelew na konto bankowe został pomyślnie zakończony"])},
    "failed_payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer na konto nie powiódł się. Jeśli problem nadal występuje, prosimy o kontakt z Działem Obsługi Klienta"])},
    "order_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mój portfel (0%)"])},
    "available_after_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota pozostała po transakcji :"])},
    "payin_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymana płatność"])},
    "transactions_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostęp do historii moich transakcji"])},
    "send_iban_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przelewaj pieniądze bezpośrednio do swojego portfela, aby uniknąć opłat za płatności"])},
    "wallet_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Właściciel :"])},
    "wallet_iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN :"])},
    "wallet_bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC :"])},
    "wallet_await_funds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Środki będą dostępne w ciągu 2-3 dni roboczych."])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Element pomyślnie skopiowany"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiowanie"])},
    "wallet_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udostępnianie portfela"])}
  },
  "filters": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaawansowane filtry"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtr"])},
    "filters_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do wyszukiwania bez słów kluczowych wymagane są 2 filtry"])}
  }
}