<template>
  <form
    v-if="
      kycState?.infos !== 'VALIDATION_ASKED' &&
      !activeKycPaymentProcess &&
      displayRef
    "
  >
    <h1>{{ $t('register.step_3.title') }}</h1>

    <div v-if="client?.address.account_type === 0" class="select-document">
      <h2>{{ $t('register.step_3.document_type') }}</h2>

      <div>
        <button
          :class="{ activeType: documentType === 0 }"
          @click.prevent="documentType = 0"
        >
          {{ $t('register.step_3.passport') }}
        </button>
        <button
          :class="{ activeType: documentType === 1 }"
          @click.prevent="documentType = 1"
        >
          {{ $t('register.step_3.identity_card') }}
        </button>
      </div>
    </div>

    <div v-if="client?.address.account_type === 0" class="drop-zone">
      <h2 v-if="documentType === 0">
        {{ $t('register.step_3.import_file_passport') }}
      </h2>
      <h2 v-else>
        {{ $t('register.step_3.import_file_identity_card') }}
      </h2>

      <input
        @change="files()"
        :multiple="false"
        ref="file"
        name="document"
        class="custom-file-input"
        type="file"
        accept="image/jpg, image/jpeg, image/png"
      />

      <div class="files-dropped">
        <div v-for="(file, n) in returnFile" :key="n" class="file-dropped">
          <kycFilesGestionVue :file="file" @deleteFile="deleteFile" />
        </div>
      </div>

      <input
        v-if="documentType === 1"
        @change="files('other')"
        :multiple="false"
        ref="otherFile"
        name="document"
        class="custom-file-input"
        type="file"
        accept="image/jpg, image/jpeg, image/png"
      />

      <div v-if="documentType === 1" class="files-dropped">
        <div v-for="(file, n) in returnOtherFile" :key="n" class="file-dropped">
          <kycFilesGestionVue :file="file" @deleteFile="deleteFile" />
        </div>
      </div>

      <div class="document-informations">
        <div class="document-informations-list">
          <img src="@/assets/svg/v2/checked.svg" alt="Checked" />
          <p>{{ $t('register.step_3.condition_1') }}</p>

          <el-popover placement="top" width="300">
            <template #reference>
              <div>
                <img src="@/assets/svg/v2/infos.svg" alt="Informations" width="20" />
              </div>
            </template>
            <div class="explain-content-kyc">
              <ul>
                <li>
                  <strong>{{ $t('register.step_3.kyc_explain_title_1') }}</strong>
                  <p>{{ $t('register.step_3.kycexplain_content_1') }}</p>
                </li>
                <li>
                  <strong>{{ $t('register.step_3.kyc_explain_title_2') }}</strong>
                  <p>{{ $t('register.step_3.kycexplain_content_2') }}</p>
                </li>
                <li>
                  <strong>{{ $t('register.step_3.kyc_explain_title_3') }}</strong>
                  <p>{{ $t('register.step_3.kycexplain_content_3') }}</p>
                </li>
              </ul>
            </div>
          </el-popover>
        </div>

        <div class="document-informations-list">
          <img src="@/assets/svg/v2/checked.svg" alt="Checked" />
          <p>{{ $t('register.step_3.condition_2') }}</p>
        </div>

        <div class="document-informations-list">
          <img src="@/assets/svg/v2/checked.svg" alt="Checked" />
          <p>{{ $t('register.step_3.condition_3') }}</p>

          <el-popover placement="top" width="300">
            <template #reference>
              <div>
                <img src="@/assets/svg/v2/infos.svg" alt="Informations" width="20" />
              </div>
            </template>
            <div class="explain-content-kyc">
              <ul>
                <li>
                  <strong>{{ $t('register.step_3.kyc_explain_title_1') }}</strong>
                  <p>{{ $t('register.step_3.kycexplain_content_1') }}</p>
                </li>
                <li>
                  <strong>{{ $t('register.step_3.kyc_explain_title_2') }}</strong>
                  <p>{{ $t('register.step_3.kycexplain_content_2') }}</p>
                </li>
                <li>
                  <strong>{{ $t('register.step_3.kyc_explain_title_3') }}</strong>
                  <p>{{ $t('register.step_3.kycexplain_content_3') }}</p>
                </li>
              </ul>
            </div>
          </el-popover>
        </div>
      </div>
    </div>

    <div
      v-else-if="client?.address.account_type === 1"
      class="drop-zone-business"
    >
      <div class="organization-kyc">
        <h3>{{ client?.address?.organization?.name }}</h3>

        <div class="drop-zone-identity-business">
          <div class="kbis">
            <h4>{{ $t('register.step_3.kbis') }}</h4>
            <input
              @change="proFiles('kbis')"
              :multiple="false"
              ref="fileKbis"
              name="document"
              class="custom-file-input"
              type="file"
              accept="image/jpg, image/jpeg, image/png, application/pdf"
            />

            <div class="files-dropped">
              <div
                v-for="(file, n) in returnFileKbis"
                :key="n"
                class="file-dropped"
              >
                <kycFilesGestionVue :file="file" @deleteFile="deleteKbis" />
              </div>
            </div>
          </div>

          <div class="status">
            <h4>{{ $t('register.step_3.status') }}</h4>
            <input
              @change="proFiles('status')"
              :multiple="false"
              ref="fileStatus"
              name="document"
              class="custom-file-input"
              type="file"
              accept="image/jpg, image/jpeg, image/png, application/pdf"
            />
          </div>

          <div class="files-dropped">
            <div
              v-for="(file, n) in returnFileStatus"
              :key="n"
              class="file-dropped"
            >
              <kycFilesGestionVue :file="file" @deleteFile="deleteStatus" />
            </div>
          </div>

          <div class="status">
            <h4>{{ $t('register.step_3.ubo') }}</h4>
            <input
              @change="proFiles('ubo')"
              :multiple="false"
              ref="fileUBO"
              name="document"
              class="custom-file-input"
              type="file"
              accept="image/jpg, image/jpeg, image/png, application/pdf"
            />
          </div>

          <div class="files-dropped">
            <div
              v-for="(file, n) in returnFileUBO"
              :key="n"
              class="file-dropped"
            >
              <kycFilesGestionVue :file="file" @deleteFile="deleteUBO" />
            </div>
          </div>
        </div>
      </div>

      <div class="owner-account">
        <h3>
          {{ client?.identity.firstname.toUpperCase() }}
          {{ client?.identity.lastname.toUpperCase() }}
        </h3>

        <div class="drop-zone-identity">
          <h4>{{ $t('register.step_3.passport_identity') }}</h4>
          <input
            @change="fileOwner1()"
            ref="fileOwner1"
            name="document"
            class="custom-file-input"
            type="file"
            accept="image/jpg, image/jpeg, image/png"
          />

          <div class="files-dropped">
            <div
              v-for="(file, n) in returnFileOwner1"
              :key="n"
              class="file-dropped"
            >
              <kycFilesGestionVue :file="file" @deleteFile="deleteOwnerFile1" />
            </div>
          </div>

          <input
            @change="fileOwner2()"
            ref="fileOwner2"
            name="document"
            class="custom-file-input"
            type="file"
            accept="image/jpg, image/jpeg, image/png"
          />

          <div class="files-dropped">
            <div
              v-for="(file, n) in returnFileOwner2"
              :key="n"
              class="file-dropped"
            >
              <kycFilesGestionVue :file="file" @deleteFile="deleteOwnerFile2" />
            </div>
          </div>
        </div>
      </div>

      <div class="owner-account" v-if="client?.address.shareholders.length > 0">
        <h3>
          {{ client?.address.shareholders[0].firstname.toUpperCase() }}
          {{ client?.address.shareholders[0].lastname.toUpperCase() }}
        </h3>

        <div class="drop-zone-identity">
          <h4>{{ $t('register.step_3.passport_identity') }}</h4>
          <input
            @change="shareholdersFiles('fileShareholderRecto', 1)"
            ref="fileShareholderRecto0"
            name="document"
            class="custom-file-input"
            type="file"
            accept="image/jpg, image/jpeg, image/png"
          />

          <div class="files-dropped">
            <div
              v-for="(file, n) in returnFileShareholderRecto0"
              :key="n"
              class="file-dropped"
            >
              <kycFilesGestionVue
                :file="file"
                @deleteFile="deleteShareholders"
              />
            </div>
          </div>

          <input
            @change="shareholdersFiles('fileShareholderVerso', 1)"
            ref="fileShareholderVerso0"
            name="document"
            class="custom-file-input"
            type="file"
            accept="image/jpg, image/jpeg, image/png"
          />

          <div class="files-dropped">
            <div
              v-for="(file, s) in returnFileShareholderVerso0"
              :key="s"
              class="file-dropped"
            >
              <kycFilesGestionVue
                :file="file"
                @deleteFile="deleteShareholders"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="owner-account" v-if="client?.address.shareholders.length > 1">
        <h3>
          {{ client?.address.shareholders[1].firstname.toUpperCase() }}
          {{ client?.address.shareholders[1].lastname.toUpperCase() }}
        </h3>

        <div class="drop-zone-identity">
          <h4>{{ $t('register.step_3.passport_identity') }}</h4>
          <input
            @change="shareholdersFiles('fileShareholderRecto', 2)"
            ref="fileShareholderRecto1"
            name="document"
            class="custom-file-input"
            type="file"
            accept="image/jpg, image/jpeg, image/png"
          />

          <div class="files-dropped">
            <div
              v-for="(file, n) in returnFileShareholderRecto1"
              :key="n"
              class="file-dropped"
            >
              <kycFilesGestionVue
                :file="file"
                @deleteFile="deleteShareholders"
              />
            </div>
          </div>

          <input
            @change="shareholdersFiles('fileShareholderVerso', 2)"
            ref="fileShareholderVerso1"
            name="document"
            class="custom-file-input"
            type="file"
            accept="image/jpg, image/jpeg, image/png"
          />

          <div class="files-dropped">
            <div
              v-for="(file, s) in returnFileShareholderVerso1"
              :key="s"
              class="file-dropped"
            >
              <kycFilesGestionVue
                :file="file"
                @deleteFile="deleteShareholders"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="owner-account" v-if="client?.address.shareholders.length > 2">
        <h3>
          {{ client?.address.shareholders[2].firstname.toUpperCase() }}
          {{ client?.address.shareholders[2].lastname.toUpperCase() }}
        </h3>

        <div class="drop-zone-identity">
          <h4>{{ $t('register.step_3.passport_identity') }}</h4>
          <input
            @change="shareholdersFiles('fileShareholderRecto', 3)"
            ref="fileShareholderRecto2"
            name="document"
            class="custom-file-input"
            type="file"
            accept="image/jpg, image/jpeg, image/png"
          />

          <div class="files-dropped">
            <div
              v-for="(file, n) in returnFileShareholderRecto2"
              :key="n"
              class="file-dropped"
            >
              <kycFilesGestionVue
                :file="file"
                @deleteFile="deleteShareholders"
              />
            </div>
          </div>

          <input
            @change="shareholdersFiles('fileShareholderVerso', 3)"
            ref="fileShareholderVerso2"
            name="document"
            class="custom-file-input"
            type="file"
            accept="image/jpg, image/jpeg, image/png"
          />

          <div class="files-dropped">
            <div
              v-for="(file, s) in returnFileShareholderVerso2"
              :key="s"
              class="file-dropped"
            >
              <kycFilesGestionVue
                :file="file"
                @deleteFile="deleteShareholders"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <button
      v-if="kycMustBePay"
      @click.prevent="activePaymentProcess()"
      class="generic-btn continue-pro-kyc"
    >
      {{ $t('subscriptions.payment_process') }}
    </button>
    <button
      v-else-if="
        !kycState?.kyc_need_payment && client?.address.account_type === 1
      "
      @click.prevent="checkInputsPayment('ignorePayment')"
      class="generic-btn continue-pro-kyc"
    >
      {{ $t('register.step_3.validation') }}
    </button>
    <button v-else @click.prevent="sendKycRequest()" class="generic-btn center">
      {{ $t('register.step_3.validation') }}
    </button>

    <p v-if="client?.address.account_type === 1" class="verification-timing">
      {{ $t('register.step_3.verification_timing') }}
    </p>

    <p v-else class="verification-timing">
      {{ $t('register.step_3.verification_timing_individual') }}
    </p>

    <div class="restriction-box">
      <div class="image-container">
        <img
          src="@/assets/svg/v2/registerProcess/lock.svg"
          alt="Lock account"
        />
      </div>
      <div class="restriction-explain">
        <h3>{{ $t('register.step_3.active_restriction') }}</h3>
        <p>{{ $t('register.step_3.description_restriction') }}</p>
      </div>
    </div>
  </form>

  <div
    v-if="
      activeKycPaymentProcess &&
      kycState?.infos !== 'VALIDATION_ASKED' &&
      kycState?.kyc_need_payment
    "
    class="payment-process-kyc"
  >
    <div class="back">
      <img src="@/assets/svg/v2/arrowToLeft.svg" alt="Back" />
      <button @click="activeKycPaymentProcess = false">
        {{ $t('commons.back') }}
      </button>
    </div>

    <h1>{{ $t('register.step_3.payment_process') }}</h1>

    <div class="payment">
      <div class="payment-sources">
        <div class="payment-sources-selection">
          <img
            src="@/assets/svg/v2/dotActive.svg"
            alt="Selected banking card"
          />
          <p>{{ $t('subscriptions.banking_card') }}</p>
        </div>
        <div class="payment-sources-accepted">
          <img src="@/assets/svg/v2/subscriptions/visa.svg" alt="Visa" />
          <img
            src="@/assets/svg/v2/subscriptions/mastercard.svg"
            alt="Mastercard"
          />
          <img src="@/assets/svg/v2/subscriptions/ae.svg" alt="AE" />
        </div>
      </div>

      <form>
        <el-input
          class="form-input"
          :class="{ errorInput: cardRegex.card_number }"
          :placeholder="$t('subscriptions.card_number')"
          type="text"
          minlength="15"
          maxlength="20"
          v-model="card.card_number"
          :size="size"
          :suffix-icon="Lock"
        />
        <el-input
          class="form-input"
          :class="{ errorInput: cardRegex.fullnames }"
          :placeholder="$t('subscriptions.card_fullnames')"
          type="text"
          minlength="5"
          maxlength="75"
          v-model="card.fullnames"
          :size="size"
        />

        <div>
          <el-input
            class="form-input"
            clearable
            :class="{ errorInput: cardRegex.card_expiration_date }"
            :placeholder="$t('subscriptions.card_expiration')"
            type="text"
            minlength="5"
            maxlength="5"
            v-model="card.card_expiration_date"
            :size="size"
          />
          <el-input
            class="form-input"
            :class="{ errorInput: cardRegex.card_cvx }"
            :placeholder="$t('subscriptions.card_code_security')"
            type="text"
            minlength="3"
            maxlength="3"
            v-model="card.card_cvx"
            :size="size"
            :suffix-icon="QuestionFilled"
          />
        </div>
      </form>

      <div class="invoice-details">
        <div class="invoice-details-country">
          <h4>{{ $t('register.step_3.title_payment_details') }}</h4>
          <p>
            {{ priceCountry(client?.address?.organization?.country).price }}€
          </p>
        </div>
        <div class="invoice-details-tva">
          <h4>{{ $t('commons.vat') }} (20%)</h4>
          <p>{{ priceCountry(client?.address?.organization?.country).vat }}€</p>
        </div>
        <div class="invoice-details-total">
          <h4>{{ $t('commons.total') }}</h4>
          <p>
            {{ priceCountry(client?.address?.organization?.country).total }}€
          </p>
        </div>
      </div>
    </div>

    <button
      @click.prevent="checkInputsPayment()"
      class="generic-btn margin-center"
    >
      {{ $t('subscriptions.payment_process') }} ({{
        priceCountry(client?.address?.organization?.country).total
      }}€)
    </button>
  </div>

  <div
    class="kyc-validation-asked"
    v-if="kycState?.infos === 'VALIDATION_ASKED'"
  >
    <h1>{{ $t('register.step_3.title') }}</h1>
    <h2 v-if="client?.address.account_type === 1">{{ $t('register.step_3.verification_timing') }}</h2>
    <h2 v-else>{{ $t('register.step_3.verification_timing_individual') }}</h2>

    <div class="validation-asked-informations">
      <div class="validation-asked-informations-title">
        <img
          src="@/assets/svg/v2/registerProcess/document.svg"
          alt="Document"
        />
        <p>{{ $t('register.step_3.documents_send') }}</p>
      </div>
      <div class="validation-asked-informations-content">
        <p>{{ $t('register.step_3.verification_in_progress') }}</p>
        <img
          src="@/assets/svg/v2/registerProcess/verificationInProgress.svg"
          alt="Verification in progress"
        />
      </div>
    </div>

    <div class="restriction-box">
      <div class="image-container">
        <img
          src="@/assets/svg/v2/registerProcess/lock.svg"
          alt="Lock account"
        />
      </div>
      <div class="restriction-explain">
        <h3>{{ $t('register.step_3.active_restriction') }}</h3>
        <p>
          {{ $t('register.step_3.verification_in_progress_restriction_1') }}
        </p>
        <p>
          {{ $t('register.step_3.verification_in_progress_restriction_2') }}
        </p>
        <p>{{ $t('register.step_3.description_restriction') }}</p>
      </div>
    </div>
  </div>

  <payment3DSecureVue v-if="get3DSecureDialog"></payment3DSecureVue>
</template>

<script setup>
import { QuestionFilled, Lock } from '@element-plus/icons-vue'
</script>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ElMessage, ElLoading } from 'element-plus'
import { find, findIndex, filter } from 'lodash'
import kycFilesGestionVue from './3_kycFilesGestion.vue'
import payment3DSecureVue from '../modules/payments/payment3DSecure.vue'

export default {
  components: {
    kycFilesGestionVue,
    payment3DSecureVue,
  },
  data() {
    return {
      kycCheckResult: {},
      filesNameList: [],
      documentType: 1,
      documentTypePro: 1,
      document_list: [
        { label: 'Passeport', value: 0 },
        { label: "Carte d'identité", value: 1 },
      ],
      ext: /[A-Za-z0-9_\-()ÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ]+.(jpg|jpeg|png|JPG|PNG|JPEG|pdf|PDF)$/,
      displayRef: false,

      activeKycPaymentProcess: false,
      card: {
        fullnames: '',
        card_number: '',
        card_expiration_date: '',
        card_cvx: '',
        card_type: 'CB_VISA_MASTERCARD',
      },

      cardRegex: {
        fullnames: false,
        card_number: false,
        card_expiration_date: false,
        card_cvx: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      kycState: 'kycState',
      client: 'getUserDetails',
      get3DSecureDialog: 'get3DSecureDialog',
    }),
    kycMustBePay() {
      if (
        this.isAccountTypeSelected === 2 &&
        this.client?.identity.account_type === 1 &&
        this.kycState?.kyc_need_payment
      ) {
        return true
      }
      return false
    },
    isAccountTypeSelected() {
      return this.$store.state.auth.register_step - 1
    },
    returnFile() {
      return filter(this.filesNameList, (o) => {
        return o.infos === 'IP1'
      })
    },
    returnOtherFile() {
      return filter(this.filesNameList, (o) => {
        return o.infos === 'IP2'
      })
    },
    returnFileKbis() {
      return filter(this.filesNameList, (o) => {
        return o.infos === 'KBIS'
      })
    },
    returnFileStatus() {
      return filter(this.filesNameList, (o) => {
        return o.infos === 'STATUS'
      })
    },
    returnFileUBO() {
      return filter(this.filesNameList, (o) => {
        return o.infos === 'UBO_DECLARATION'
      })
    },
    returnFileOwner1() {
      return filter(this.filesNameList, (o) => {
        return o.infos === 'IP_S1R'
      })
    },
    returnFileOwner2() {
      return filter(this.filesNameList, (o) => {
        return o.infos === 'IP_S1V'
      })
    },
    returnFileShareholderRecto0() {
      return filter(this.filesNameList, (o) => {
        return o.infos === 'recto' && o.type === 'IDENTITY_PROOF_SH1'
      })
    },
    returnFileShareholderVerso0() {
      return filter(this.filesNameList, (o) => {
        return o.infos === 'verso' && o.type === 'IDENTITY_PROOF_SH1'
      })
    },
    returnFileShareholderRecto1() {
      return filter(this.filesNameList, (o) => {
        return o.infos === 'recto' && o.type === 'IDENTITY_PROOF_SH2'
      })
    },
    returnFileShareholderVerso1() {
      return filter(this.filesNameList, (o) => {
        return o.infos === 'verso' && o.type === 'IDENTITY_PROOF_SH2'
      })
    },
    returnFileShareholderRecto2() {
      return filter(this.filesNameList, (o) => {
        return o.infos === 'recto' && o.type === 'IDENTITY_PROOF_SH3'
      })
    },
    returnFileShareholderVerso2() {
      return filter(this.filesNameList, (o) => {
        return o.infos === 'verso' && o.type === 'IDENTITY_PROOF_SH3'
      })
    },
  },
  mounted() {
    if (this) {
      this.authVerification()
      const res = this.$store.state.auth
      this.kycCheckResult = res.kyc_details

      if (!this.client) {
        this.getClient()
      }

      this.displayRef = true
    }
  },
  watch: {
    'card.card_expiration_date': function () {
      if (this.card.card_expiration_date) {
        let slashCount = 0
        for (var i = 0; i < this.card.card_expiration_date.length; i++) {
          if (this.card.card_expiration_date[i] === '/') {
            slashCount++
          }
        }

        if (slashCount > 1) {
          this.card.card_expiration_date = ''
        }

        if (this.card.card_expiration_date.length === 2) {
          this.card.card_expiration_date += '/'
        }
      }
    },
  },
  methods: {
    ...mapActions({
      kycRequest: 'sendKycRequest',
      sendKycPayment: 'sendKycPayment',
      authVerification: 'authverification',
      getClient: 'getAccountDetails',
    }),
    priceCountry(country) {
      let countryEU = [
        'AT',
        'BE',
        'BG',
        'HR',
        'CY',
        'CZ',
        'DK',
        'EE',
        'FI',
        'FR',
        'DE',
        'GR',
        'HU',
        'IE',
        'IT',
        'LV',
        'LT',
        'LU',
        'MT',
        'NL',
        'PL',
        'PT',
        'RO',
        'SK',
        'SI',
        'ES',
        'SE',
        'GB',
        'UK',
        'US',
      ]
      let vat = 1.2
      let amountForCountry = 5.0

      if (countryEU.includes(country)) {
        amountForCountry = 5.0
      } else if (country === 'CN') {
        amountForCountry = 20.0
      } else {
        amountForCountry = 15.0
      }

      const price = {
        price: amountForCountry,
        vat: amountForCountry * vat - amountForCountry,
        total: amountForCountry * vat,
      }

      return price
    },
    files(otherFile) {
      if (!otherFile) {
        for (
          let fileCount = 0;
          fileCount < this.$refs.file.files.length;
          fileCount++
        ) {
          if (this.$refs.file.files) {
            const fileName = this.$refs.file.files[fileCount].name
            const fileSize = this.$refs.file.files[fileCount].size

            if (!this.ext.test(fileName) || fileSize > 7000000) {
              this.$refs.file.value = null
              this.$forceUpdate()

              ElMessage({
                message: 'identity 1',
                type: 'error',
                grouping: true,
                duration: 5000,
              })
            } else {
              for (
                let fileCount = 0;
                fileCount < this.$refs.file.files.length;
                fileCount++
              ) {
                const reader = new FileReader()

                reader.onload = (event) => {
                  const file = {
                    name: fileName,
                    size: fileSize,
                    type: 'IDENTITY_PROOF',
                    infos: 'IP1',
                    data: event.target.result
                      .replace('data:image/jpeg;base64,', '')
                      .replace('data:image/jpg;base64,', '')
                      .replace('data:image/png;base64,', '')
                      .replace('data:application/pdf;base64,', ''),
                  }
                  this.filesNameList.push(file)
                }

                reader.readAsDataURL(this.$refs.file.files[fileCount])
              }
            }

            this.$forceUpdate()
          }
        }
      } else if (otherFile === 'other') {
        for (
          let fileCount = 0;
          fileCount < this.$refs.otherFile.files.length;
          fileCount++
        ) {
          if (this.$refs.file.files) {
            const fileName = this.$refs.otherFile.files[fileCount].name
            const fileSize = this.$refs.otherFile.files[fileCount].size

            if (!this.ext.test(fileName) || fileSize > 7000000) {
              this.$refs.otherFile.value = null
              this.$forceUpdate()

              ElMessage({
                message: 'identity 2',
                type: 'error',
                grouping: true,
                duration: 5000,
              })
            } else {
              for (
                let fileCount = 0;
                fileCount < this.$refs.otherFile.files.length;
                fileCount++
              ) {
                const reader = new FileReader()

                reader.onload = (event) => {
                  const file = {
                    name: fileName,
                    size: fileSize,
                    type: 'IDENTITY_PROOF',
                    infos: 'IP2',
                    data: event.target.result
                      .replace('data:image/jpeg;base64,', '')
                      .replace('data:image/jpg;base64,', '')
                      .replace('data:image/png;base64,', '')
                      .replace('data:application/pdf;base64,', ''),
                  }
                  this.filesNameList.push(file)
                }

                reader.readAsDataURL(this.$refs.otherFile.files[fileCount])
              }
            }
            this.$forceUpdate()
          }
        }
      }
    },
    deleteFile(infos) {
      if (this.documentType === 0) {
        this.$refs.file.value = null
        this.filesNameList = []
        this.$forceUpdate()
      } else if (this.documentType === 1) {
        const findInFileRef = find(this.$refs.file?.files, (o) => {
          return o.name === infos.name && o.size === infos.size
        })
        const index = findIndex(this.filesNameList, (o) => {
          return o.name === infos.name && o.size === infos.size
        })

        if (findInFileRef) {
          this.filesNameList.splice(index, 1)
          this.$refs.file.value = null
        } else {
          this.filesNameList.splice(index, 1)
          this.$refs.otherFile.value = null
        }

        this.$forceUpdate()
      }
    },
    deleteKbis(infos) {
      const index = findIndex(this.filesNameList, (o) => {
        return o.name === infos.name && o.size === infos.size
      })

      this.filesNameList.splice(index, 1)
      this.$refs.fileKbis.value = null
      this.$forceUpdate()
    },
    deleteStatus(infos) {
      const index = findIndex(this.filesNameList, (o) => {
        return o.name === infos.name && o.size === infos.size
      })

      this.filesNameList.splice(index, 1)
      this.$refs.fileStatus.value = null
      this.$forceUpdate()
    },
    deleteUBO(infos) {
      const index = findIndex(this.filesNameList, (o) => {
        return o.name === infos.name && o.size === infos.size
      })

      this.filesNameList.splice(index, 1)
      this.$refs.fileUBO.value = null
      this.$forceUpdate()
    },
    deleteOwnerFile1(infos) {
      const index = findIndex(this.filesNameList, (o) => {
        return o.name === infos.name && o.size === infos.size
      })

      this.filesNameList.splice(index, 1)
      this.$refs.fileOwner1.value = null
      this.$forceUpdate()
    },
    deleteOwnerFile2(infos) {
      const index = findIndex(this.filesNameList, (o) => {
        return o.name === infos.name && o.size === infos.size
      })

      this.filesNameList.splice(index, 1)
      this.$refs.fileOwner2.value = null
      this.$forceUpdate()
    },
    deleteShareholders(infos) {
      const index = findIndex(this.filesNameList, (o) => {
        return o.name === infos.name && o.size === infos.size
      })

      const findfile = find(this.filesNameList, (o) => {
        return o.name === infos.name && o.size === infos.size
      })

      if (
        findfile.type === 'IDENTITY_PROOF_SH1' &&
        findfile.infos === 'recto'
      ) {
        this.$refs.fileShareholderRecto0.value = null
      } else if (
        findfile.type === 'IDENTITY_PROOF_SH1' &&
        findfile.infos === 'verso'
      ) {
        this.$refs.fileShareholderVerso0.value = null
      } else if (
        findfile.type === 'IDENTITY_PROOF_SH2' &&
        findfile.infos === 'recto'
      ) {
        this.$refs.fileShareholderRecto1.value = null
      } else if (
        findfile.type === 'IDENTITY_PROOF_SH2' &&
        findfile.infos === 'verso'
      ) {
        this.$refs.fileShareholderVerso1.value = null
      } else if (
        findfile.type === 'IDENTITY_PROOF_SH3' &&
        findfile.infos === 'recto'
      ) {
        this.$refs.fileShareholderRecto2.value = null
      } else if (
        findfile.type === 'IDENTITY_PROOF_SH3' &&
        findfile.infos === 'verso'
      ) {
        this.$refs.fileShareholderVerso2.value = null
      }

      this.filesNameList.splice(index, 1)
      this.$forceUpdate()
    },
    proFiles(name) {
      if (name === 'kbis') {
        if (this.$refs.fileKbis.files) {
          if (
            !this.ext.test(this.$refs.fileKbis.files[0].name) ||
            this.$refs.fileKbis.files[0].size > 7000000
          ) {
            this.$refs.fileKbis.value = null
            this.$forceUpdate()

            ElMessage({
              message: 'kbis',
              type: 'error',
              grouping: true,
              duration: 5000,
            })
          } else {
            const reader = new FileReader()

            reader.onload = (event) => {
              const file = {
                name: this.$refs.fileKbis.files[0].name,
                size: this.$refs.fileKbis.files[0].size,
                type: 'REGISTRATION_PROOF',
                infos: 'KBIS',
                data: event.target.result
                  .replace('data:image/jpeg;base64,', '')
                  .replace('data:image/jpg;base64,', '')
                  .replace('data:image/png;base64,', '')
                  .replace('data:application/pdf;base64,', ''),
              }
              this.filesNameList.push(file)
            }

            reader.readAsDataURL(this.$refs.fileKbis.files[0])
          }

          this.$forceUpdate()
        }
      } else if (name === 'status') {
        if (this.$refs.fileStatus.files) {
          if (
            !this.ext.test(this.$refs.fileStatus.files[0].name) ||
            this.$refs.fileStatus.files[0].size > 7000000
          ) {
            this.$refs.fileStatus.value = null
            this.$forceUpdate()

            ElMessage({
              message: 'status',
              type: 'error',
              grouping: true,
              duration: 5000,
            })
          } else {
            const reader = new FileReader()

            reader.onload = (event) => {
              const file = {
                name: this.$refs.fileStatus.files[0].name,
                size: this.$refs.fileStatus.files[0].size,
                type: 'ARTICLES_OF_ASSOCIATION',
                infos: 'STATUS',
                data: event.target.result
                  .replace('data:image/jpeg;base64,', '')
                  .replace('data:image/jpg;base64,', '')
                  .replace('data:image/png;base64,', '')
                  .replace('data:application/pdf;base64,', ''),
              }
              this.filesNameList.push(file)
            }

            reader.readAsDataURL(this.$refs.fileStatus.files[0])
          }

          this.$forceUpdate()
        }
      } else if (name === 'ubo') {
        if (this.$refs.fileUBO.files) {
          if (
            !this.ext.test(this.$refs.fileUBO.files[0].name) ||
            this.$refs.fileUBO.files[0].size > 7000000
          ) {
            this.$refs.fileUBO.value = null
            this.$forceUpdate()

            ElMessage({
              message: 'UBO Declaration',
              type: 'error',
              grouping: true,
              duration: 5000,
            })
          } else {
            const reader = new FileReader()

            reader.onload = (event) => {
              const file = {
                name: this.$refs.fileUBO.files[0].name,
                size: this.$refs.fileUBO.files[0].size,
                type: 'UBO_DECLARATION',
                infos: 'UBO_DECLARATION',
                data: event.target.result
                  .replace('data:image/jpeg;base64,', '')
                  .replace('data:image/jpg;base64,', '')
                  .replace('data:image/png;base64,', '')
                  .replace('data:application/pdf;base64,', ''),
              }
              this.filesNameList.push(file)
            }

            reader.readAsDataURL(this.$refs.fileUBO.files[0])
          }

          this.$forceUpdate()
        }
      }
    },
    fileOwner1() {
      if (this.$refs.fileOwner1.files) {
        if (
          !this.ext.test(this.$refs.fileOwner1.files[0].name) ||
          this.$refs.fileOwner1.files[0].size > 7000000
        ) {
          this.$refs.fileOwner1.value = null
          this.$forceUpdate()

          ElMessage({
            message: 'owner 1',
            type: 'error',
            grouping: true,
            duration: 5000,
          })
        } else {
          const reader = new FileReader()

          reader.onload = (event) => {
            const file = {
              name: this.$refs.fileOwner1.files[0].name,
              size: this.$refs.fileOwner1.files[0].size,
              type: 'IDENTITY_PROOF',
              infos: 'IP_S1R',
              data: event.target.result
                .replace('data:image/jpeg;base64,', '')
                .replace('data:image/jpg;base64,', '')
                .replace('data:image/png;base64,', '')
                .replace('data:application/pdf;base64,', ''),
            }
            this.filesNameList.push(file)
          }

          reader.readAsDataURL(this.$refs.fileOwner1.files[0])
        }

        this.$forceUpdate()
      }
    },
    fileOwner2() {
      if (this.$refs.fileOwner2.files) {
        if (
          !this.ext.test(this.$refs.fileOwner2.files[0].name) ||
          this.$refs.fileOwner2.files[0].size > 7000000
        ) {
          this.$refs.fileOwner2.value = null
          this.$forceUpdate()

          ElMessage({
            message: 'owner 2',
            type: 'error',
            grouping: true,
            duration: 5000,
          })
        } else {
          const reader = new FileReader()

          reader.onload = (event) => {
            const file = {
              name: this.$refs.fileOwner2.files[0].name,
              size: this.$refs.fileOwner2.files[0].size,
              type: 'IDENTITY_PROOF',
              infos: 'IP_S1V',
              data: event.target.result
                .replace('data:image/jpeg;base64,', '')
                .replace('data:image/jpg;base64,', '')
                .replace('data:image/png;base64,', '')
                .replace('data:application/pdf;base64,', ''),
            }
            this.filesNameList.push(file)
          }

          reader.readAsDataURL(this.$refs.fileOwner2.files[0])
        }

        this.$forceUpdate()
      }
    },
    shareholdersFiles(type, count) {
      if (type === 'fileShareholderRecto') {
        if (count === 1) {
          if (this.$refs.fileShareholderRecto0.files) {
            if (
              !this.ext.test(this.$refs.fileShareholderRecto0.files[0].name) ||
              this.$refs.fileShareholderRecto0.files[0].size > 7000000
            ) {
              this.$refs.fileShareholderRecto0.value = null
              this.$forceUpdate()

              ElMessage({
                message: `${type} ${count}`,
                type: 'error',
                grouping: true,
                duration: 5000,
              })
            } else {
              const reader = new FileReader()

              reader.onload = (event) => {
                const file = {
                  name: this.$refs.fileShareholderRecto0.files[0].name,
                  size: this.$refs.fileShareholderRecto0.files[0].size,
                  type: `IDENTITY_PROOF_SH1`,
                  infos: 'recto',
                  data: event.target.result
                    .replace('data:image/jpeg;base64,', '')
                    .replace('data:image/jpg;base64,', '')
                    .replace('data:image/png;base64,', '')
                    .replace('data:application/pdf;base64,', ''),
                }
                this.filesNameList.push(file)
              }

              reader.readAsDataURL(this.$refs.fileShareholderRecto0.files[0])
            }

            this.$forceUpdate()
          }
        } else if (count === 2) {
          if (this.$refs.fileShareholderRecto1.files) {
            if (
              !this.ext.test(this.$refs.fileShareholderRecto1.files[0].name) ||
              this.$refs.fileShareholderRecto1.files[0].size > 7000000
            ) {
              this.$refs.fileShareholderRecto1.value = null
              this.$forceUpdate()

              ElMessage({
                message: `${type} ${count}`,
                type: 'error',
                grouping: true,
                duration: 5000,
              })
            } else {
              const reader = new FileReader()

              reader.onload = (event) => {
                const file = {
                  name: this.$refs.fileShareholderRecto1.files[0].name,
                  size: this.$refs.fileShareholderRecto1.files[0].size,
                  type: `IDENTITY_PROOF_SH2`,
                  infos: 'recto',
                  data: event.target.result
                    .replace('data:image/jpeg;base64,', '')
                    .replace('data:image/jpg;base64,', '')
                    .replace('data:image/png;base64,', '')
                    .replace('data:application/pdf;base64,', ''),
                }
                this.filesNameList.push(file)
              }

              reader.readAsDataURL(this.$refs.fileShareholderRecto1.files[0])
            }

            this.$forceUpdate()
          }
        } else if (count === 3) {
          if (this.$refs.fileShareholderRecto2.files) {
            if (
              !this.ext.test(this.$refs.fileShareholderRecto2.files[0].name) ||
              this.$refs.fileShareholderRecto2.files[0].size > 7000000
            ) {
              this.$refs.fileShareholderRecto2.value = null
              this.$forceUpdate()

              ElMessage({
                message: `${type} ${count}`,
                type: 'error',
                grouping: true,
                duration: 5000,
              })
            } else {
              const reader = new FileReader()

              reader.onload = (event) => {
                const file = {
                  name: this.$refs.fileShareholderRecto2.files[0].name,
                  size: this.$refs.fileShareholderRecto2.files[0].size,
                  type: `IDENTITY_PROOF_SH3`,
                  infos: 'recto',
                  data: event.target.result
                    .replace('data:image/jpeg;base64,', '')
                    .replace('data:image/jpg;base64,', '')
                    .replace('data:image/png;base64,', '')
                    .replace('data:application/pdf;base64,', ''),
                }
                this.filesNameList.push(file)
              }

              reader.readAsDataURL(this.$refs.fileShareholderRecto2.files[0])
            }

            this.$forceUpdate()
          }
        }
      } else if (type === 'fileShareholderVerso') {
        if (count === 1) {
          if (this.$refs.fileShareholderVerso0.files) {
            if (
              !this.ext.test(this.$refs.fileShareholderVerso0.files[0].name) ||
              this.$refs.fileShareholderVerso0.files[0].size > 7000000
            ) {
              this.$refs.fileShareholderVerso0.value = null
              this.$forceUpdate()

              ElMessage({
                message: `${type} ${count}`,
                type: 'error',
                grouping: true,
                duration: 5000,
              })
            } else {
              const reader = new FileReader()

              reader.onload = (event) => {
                const file = {
                  name: this.$refs.fileShareholderVerso0.files[0].name,
                  size: this.$refs.fileShareholderVerso0.files[0].size,
                  type: `IDENTITY_PROOF_SH1`,
                  infos: 'verso',
                  data: event.target.result
                    .replace('data:image/jpeg;base64,', '')
                    .replace('data:image/jpg;base64,', '')
                    .replace('data:image/png;base64,', '')
                    .replace('data:application/pdf;base64,', ''),
                }
                this.filesNameList.push(file)
              }

              reader.readAsDataURL(this.$refs.fileShareholderVerso0.files[0])
            }

            this.$forceUpdate()
          }
        } else if (count === 2) {
          if (this.$refs.fileShareholderVerso1.files) {
            if (
              !this.ext.test(this.$refs.fileShareholderVerso1.files[0].name) ||
              this.$refs.fileShareholderVerso1.files[0].size > 7000000
            ) {
              this.$refs.fileShareholderVerso1.value = null
              this.$forceUpdate()

              ElMessage({
                message: `${type} ${count}`,
                type: 'error',
                grouping: true,
                duration: 5000,
              })
            } else {
              const reader = new FileReader()

              reader.onload = (event) => {
                const file = {
                  name: this.$refs.fileShareholderVerso1.files[0].name,
                  size: this.$refs.fileShareholderVerso1.files[0].size,
                  type: `IDENTITY_PROOF_SH2`,
                  infos: 'verso',
                  data: event.target.result
                    .replace('data:image/jpeg;base64,', '')
                    .replace('data:image/jpg;base64,', '')
                    .replace('data:image/png;base64,', '')
                    .replace('data:application/pdf;base64,', ''),
                }
                this.filesNameList.push(file)
              }

              reader.readAsDataURL(this.$refs.fileShareholderVerso1.files[0])
            }

            this.$forceUpdate()
          }
        } else if (count === 3) {
          if (this.$refs.fileShareholderVerso2.files) {
            if (
              !this.ext.test(this.$refs.fileShareholderVerso2.files[0].name) ||
              this.$refs.fileShareholderVerso2.files[0].size > 7000000
            ) {
              this.$refs.fileShareholderVerso2.value = null
              this.$forceUpdate()

              ElMessage({
                message: `${type} ${count}`,
                type: 'error',
                grouping: true,
                duration: 5000,
              })
            } else {
              const reader = new FileReader()

              reader.onload = (event) => {
                const file = {
                  name: this.$refs.fileShareholderVerso2.files[0].name,
                  size: this.$refs.fileShareholderVerso2.files[0].size,
                  type: `IDENTITY_PROOF_SH3`,
                  infos: 'verso',
                  data: event.target.result
                    .replace('data:image/jpeg;base64,', '')
                    .replace('data:image/jpg;base64,', '')
                    .replace('data:image/png;base64,', '')
                    .replace('data:application/pdf;base64,', ''),
                }
                this.filesNameList.push(file)
              }

              reader.readAsDataURL(this.$refs.fileShareholderVerso2.files[0])
            }

            this.$forceUpdate()
          }
        }
      }
    },
    async sendKycRequest() {
      const extractBase64 = []
      for (const key in this.filesNameList) {
        extractBase64.push(this.filesNameList[key].data)
      }

      if (this.kycMustBePay) {
        this.activeKycPaymentProcess = true
      } else {
        const payload = {
          files: extractBase64,
          document_type: this.documentType,
        }

        if (
          (this.documentType === 0 && this.filesNameList.length === 1) ||
          (this.documentType === 1 && this.filesNameList.length === 2)
        ) {
          const loading = ElLoading.service({
            lock: true,
            background: 'rgba(0, 0, 0, 0.7)',
          })

          await this.kycRequest(payload)
            .then(() => {
              this.authVerification();
              loading.close();
            })
            .catch(() => {
              this.filesNameList = []

              if (this.client.address.account_type === 0) {
                this.$refs.file.value = null;
                this.$refs.otherFile.value = null;
              }
              loading.close();
            })
        } else {
          if (this.documentType === 0) {
            ElMessage({
              message: 'Vous devez ajouter un justificatif pour le Passeport',
              type: 'error',
              grouping: true,
              duration: 5000,
            })
          } else {
            ElMessage({
              message:
                "Vous devez ajouter 2 justificatifs pour la pièce d'identité",
              type: 'error',
              grouping: true,
              duration: 5000,
            })
          }
        }
      }
    },
    activePaymentProcess() {
      this.activeKycPaymentProcess = true
    },
    async checkInputsPayment(alreadyPaymentProcess) {
      const regex_card_number =
        /^(4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|3[47][0-9]{13,16})$/
      const regex_card_fullnames =
        /^([a-zA-ZÀ-ÖØ-öø-ÿ'’-]+(?:\s+[a-zA-ZÀ-ÖØ-öø-ÿ'’-]+)*)$/
      const regex_card_expiration = /^(0[1-9]|1[0-2])\/([0-9]{2})$/
      const regex_card_cvx = /^[0-9]{3,4}$/

      if (!alreadyPaymentProcess) {
        if (!regex_card_number.test(this.card.card_number)) {
          this.cardRegex.card_number = true
          ElMessage({
            message: this.$t('register.step_3.error_card_number'),
            type: 'warning',
            grouping: true,
          })
        } else {
          this.cardRegex.card_number = false
        }

        if (!regex_card_expiration.test(this.card.card_expiration_date)) {
          this.cardRegex.card_expiration_date = true
          ElMessage({
            message: this.$t('register.step_3.error_card_expiration_date'),
            type: 'warning',
            grouping: true,
          })
        } else {
          this.cardRegex.card_expiration_date = false
        }

        if (
          !regex_card_fullnames.test(this.card.fullnames) ||
          this.card.fullnames.length < 1
        ) {
          this.cardRegex.fullnames = true
          ElMessage({
            message: this.$t('register.step_3.error_card_fullnames'),
            type: 'warning',
            grouping: true,
          })
        } else {
          this.cardRegex.fullnames = false
        }

        if (!regex_card_cvx.test(this.card.card_cvx)) {
          this.cardRegex.card_cvx = true
          ElMessage({
            message: this.$t('register.step_3.error_card_cvx'),
            type: 'warning',
            grouping: true,
          })
        } else {
          this.cardRegex.card_cvx = false
        }
      }

      if (
        !alreadyPaymentProcess &&
        !this.cardRegex.card_number &&
        !this.cardRegex.card_expiration_date &&
        !this.cardRegex.card_cvx &&
        !this.cardRegex.fullnames
      ) {
        const payload = {
          fullnames: this.card.fullnames,
          card_number: this.card.card_number,
          card_expiration_date: this.card.card_expiration_date
            ? this.card.card_expiration_date.replace('/', '')
            : null,
          card_cvx: this.card.card_cvx,
          card_type: this.card.card_type,
          files: this.filesNameList,
          document_type: null,
          browserInfos: null,
        }

        payload.browserInfos = {
          AcceptHeader:
            'text/html, application/xhtml+xml, application/xml;q=0.9, /;q=0.8',
          JavaEnabled: navigator.javaEnabled(),
          Language: navigator.language,
          ColorDepth: window.screen.colorDepth,
          ScreenHeight: window.screen.height,
          ScreenWidth: window.screen.width,
          TimeZoneOffset: new Date().getTimezoneOffset(),
          UserAgent: navigator.userAgent,
          JavascriptEnabled: true,
        }

        const loading = ElLoading.service({
          lock: true,
          background: 'rgba(0, 0, 0, 0.7)',
        })

        await this.sendKycPayment(payload)
          .then(async (res) => {
            if (!res.data.secure_mode_redirect_url) {
              this.authVerification()
            }

            loading.close()
          })
          .catch(async () => {
            loading.close()
          })
      } else if (alreadyPaymentProcess) {
        const payload = {
          files: this.filesNameList,
        }

        const loading = ElLoading.service({
          lock: true,
          background: 'rgba(0, 0, 0, 0.7)',
        })

        await this.sendKycPayment(payload)
          .then(async () => {
            this.authVerification()
            loading.close()
          })
          .catch(async () => {
            loading.close()
          })
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root.scss';

.document-informations div,
.restriction-box,
.file-dropped,
.file-dropped-informations,
.file-dropped-informations div,
.back,
.drop-zone,
form {
  display: flex;
}

form {
  flex-direction: column;
  margin: auto;
  margin-top: 74px;

  h1 {
    color: white;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 34px;
  }

  .select-document {
    h2 {
      color: white;
      font-size: 16px;
      font-weight: 600;
    }

    div {
      margin-top: 16px;
      margin-bottom: 35px;

      button {
        background: transparent;
        border-radius: 50px;
        border: 1px solid $border;
        color: $subtitle;
        margin-right: 8px;
        width: 135px;
        height: 34px;
        font-size: 15px;
        font-weight: 600;
      }
    }
  }

  .drop-zone {
    flex-direction: column;
    margin-bottom: 41px;

    h2 {
      color: white;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 16px;
      max-width: 300px;
    }

    .custom-file-input {
      margin-bottom: 8px;
    }

    .document-informations {
      align-items: center;
      margin-top: 9px;

      .document-informations-list {
        align-items: center;
        margin-bottom: 5px;

        p {
          font-size: 14px;
          font-weight: 500;
          margin-left: 5px;
          margin-right: 5px;
          text-decoration: underline;
        }
      }
    }
  }

  .drop-zone-business {
    .organization-kyc,
    .owner-account,
    .shareholders {
      padding: 10px 15px;
      border-radius: 8px;
      border: 1px solid $border;
      margin-bottom: 15px;

      h3 {
        color: white;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 15px;
      }

      .drop-zone-identity-business {
        div {
          margin-bottom: 10px;

          h4 {
            margin-bottom: 15px;
            color: $subtitle;
            font-size: 15px;
            font-weight: 600;
          }
        }
      }

      .drop-zone-identity {
        h4 {
          margin-bottom: 15px;
          color: $subtitle;
          font-size: 15px;
          font-weight: 600;
        }
      }
    }
  }

  .files-dropped {
    margin-top: 5px;
    margin-bottom: 15px;
  }

  .verification-timing {
    color: $subtitle;
    font-size: 15px;
    font-weight: 500;
    margin-top: 16px;
    width: 100%;
    text-align: center;
    margin-bottom: 25px;
  }

  .continue-pro-kyc,
  .center {
    margin: auto;
    width: 100%;
  }
}

.explain-content-kyc {
  ul li {
    margin: 5px 0;
  }
}

.file-dropped {
  border-radius: 8px;
  align-items: center;
  border: 1px solid $border;
  background: #333;
  padding: 15px;
  margin-bottom: 10px;

  .file-dropped-img {
    margin-right: 16px;
    margin-bottom: 0 !important;

    img {
      background: $blue;
      border-radius: 50%;
      padding: 7px;
    }
  }

  .file-dropped-informations {
    flex-direction: column;
    width: 100%;
    margin-bottom: 0 !important;

    p {
      color: white;
      font-size: 15px;
      font-weight: 500;
    }

    div {
      display: flex;
      align-items: center;
      color: $subtitle;
      margin-bottom: 0 !important;

      p {
        color: $subtitle;
        margin-top: 4px;
      }

      .sep {
        margin: auto 5px;
      }
    }
  }

  .file-dropped-delete {
    display: flex;
    justify-content: right;
    align-items: center;
    background: transparent;
    border: 0;
  }
}

.restriction-box {
  border-radius: 8px;
  border: 1px solid $border;
  background: #222;
  margin-bottom: 62px;

  .image-container {
    margin: 16px 8px 0 11px;
  }

  .restriction-explain {
    margin-top: 16px;
    margin-bottom: 17px;

    h3 {
      color: #f59e0b;
      font-size: 16px;
      font-weight: 600;
    }

    p {
      color: #f59e0b99;
      font-size: 14px;
      font-weight: 500;
      margin: 8px 11px 0 -28px;
      width: 424px;
    }
  }
}

.kyc-validation-asked {
  max-width: 491px;
  margin: auto;
  margin-top: 74px;

  h2 {
    color: $subtitle;
    font-size: 15px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 32px;
  }

  .validation-asked-informations {
    display: flex;
    justify-content: space-between;
    margin-bottom: 71px;
    border-radius: 8px;
    border: 1px solid $border;
    background: #222;
    padding: 10px 20px;

    .validation-asked-informations-title,
    .validation-asked-informations-content {
      display: flex;
      align-items: center;
    }

    .validation-asked-informations-title {
      p {
        color: $subtitle;
        font-size: 15px;
        font-weight: 500;
      }

      img {
        margin-right: 9px;
      }
    }

    .validation-asked-informations-content {
      p {
        color: #f59e0b;
        font-size: 14px;
        font-weight: 500;
      }

      img {
        margin-left: 8px;
      }
    }
  }
}

.payment-process-kyc {
  max-width: 700px;
  margin: auto;
  margin-top: 74px;
  margin-bottom: 50px;

  .back {
    margin-bottom: 23px;

    button {
      display: flex;
      background: transparent;
      border: 0;
      color: $subtitle;
      font-size: 15px;
      font-weight: 500;
      margin-top: 0;
    }
  }

  h1 {
    margin-bottom: 34px;
  }

  button {
    margin-top: 20px;
  }

  .invoice-details {
    color: white;
    margin-top: 10px;
    padding: 0 25px;

    .invoice-details-country,
    .invoice-details-tva,
    .invoice-details-total {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;

      h4 {
        width: 80%;
        min-width: 200px;
        font-size: 13px;
      }
    }

    .invoice-details-tva,
    .invoice-details-total {
      h4 {
        color: $subtitle;
      }
    }
  }

  .margin-center {
    margin: auto;
    margin-top: 15px;
  }
}

.payment {
  border-radius: 12px;
  border: 1px solid $border;
  background: #222;
  overflow: hidden;

  .payment-sources {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px;

    .payment-sources-selection {
      display: flex;
      align-items: center;
      cursor: pointer;

      p {
        color: white;
        font-size: 16px;
        font-weight: 500;
        margin-left: 8px;
      }
    }

    .payment-sources-accepted {
      display: flex;
      justify-content: space-between;
      min-width: 130px;
    }
  }

  form {
    padding: 23px;
    background: #333;
    border-top: 1px solid $border;
    margin-top: 10px;

    .form-input {
      border-radius: 5px;
      border: 1px solid $border;
      background: #333;
      margin-bottom: 16px;
      padding-left: 15px;
    }

    div {
      .form-input:nth-child(1) {
        margin-right: 20px;
      }
    }
  }
}

.custom-file-input {
  width: 492px;
  height: 41px;
}

input[type='file']::file-selector-button {
  margin-right: 20px;
  border-radius: 8px;
  border: 1px solid $border;
  background: #333;
  background: $background;
  color: $subtitle;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  width: 492px;
  height: 41px;
}

input[type='file']::file-selector-button:hover {
  background: #444;
}

.activeType {
  background: white !important;
  color: black !important;
}

@media screen and (max-width: 500px) {
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
    max-width: 350px;
    margin-top: 30px;

    .generic-btn {
      width: 250px !important;
    }
  }

  .custom-file-input,
  input[type='file']::file-selector-button {
    width: 330px !important;
  }

  .files-dropped {
    width: 330px;
  }

  .restriction-explain {
    h3 {
      width: 200px;
    }

    p {
      width: 310px !important;
    }
  }

  .payment-process-kyc {
    margin-top: 20px;
    width: 100%;

    .back,
    h1 {
      margin-left: 10px;
    }

    .payment {
      border: 0;
    }

    .generic-btn {
      width: 220px;
    }
  }

  .kyc-validation-asked {
    margin-left: 10px;
    margin-right: 10px;
  }
}
</style>
