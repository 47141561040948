import { api } from '@/services/interceptors';
import { ElMessage } from 'element-plus';
import { reject } from 'lodash';
import i18n from '../i18n';

export default {
  state: {
    settings: null,
    folders: null,
    folder: null,
    allBottles: [],
    bottles_details_buy: {},
    bottles_details_sell: {},
    statistics: null,
    bottleList: [],
    folderToUpdateId: null,
    showQuantityZero: false,
    count_buy: 0,
    count_sell: 0,

    currency: 'EUR',
    period: 'a',
    bottlesCategoryInPortfolio: []
  },
  getters: {
    pfmGetBottleList(state) {
      return state.bottleList;
    },
    pfmGetBottlesCount(state) {
      return {
        count_buy: state.count_buy,
        count_sell: state.count_sell,
      }
    },
    pfmShowQuantityZero(state) {
      return state.showQuantityZero;
    },
    pfmFolders(state) {
      return state.folders;
    },
    pfmFolder(state) {
      return state.folder;
    },
    pfmAllBottles(state) {
      return state.allBottles;
    },
    pfmBottleDetailsBUY(state) {
      return state.bottles_details_buy;
    },
    pfmBottleDetailsSELL(state) {
      return state.bottles_details_sell;
    },
    pfmSettings(state) {
      return state.settings;
    },
    pfmStatistics(state) {
      return state.statistics;
    },
    pfmFolderToUpdateId(state) {
      return state.folderToUpdateId;
    },
    pfmStatisticsCurrency(state) {
      return state.currency;
    },
    pfmStatisticsPeriod(state) {
      return state.period;
    },
    pfmBottlesCategoryInPortfolio(state) {
      return state.bottlesCategoryInPortfolio;
    }
  },
  actions: {
    getAllFolders({ commit }) {
      return new Promise((resolve, reject) => {
        api.get('api/portfolio/folders/bottles')
          .then((res) => {
            commit('SET_MY_FOLDER', res.data.products);
            commit('SET_ALL_BOTTLES', res.data.bottlesMap);
            resolve(res.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    getFolders({ commit }) {
      api.get('api/portfolio/folders')
        .then((res) => {
          commit('SET_MY_FOLDERS', res.data.tree);
        });
    },
    getFolder({ commit }, payload) {
      return new Promise((resolve, reject) => {
        if (payload) {
          api.get('api/portfolio/folder/' + payload)
            .then((res) => {
              commit('SET_MY_FOLDER', { folder: payload, bottlesMap: res.data.bottlesMap, bottles: res.data.products });
              resolve(res.data);
            })
            .catch((e) => {
              reject(e);
            });
        } else {
          resolve();
        }
      });
    },
    getBottleDetails({ commit }, payload) {
      api.get('api/portfolio/folder/' + payload.folder_uuid + '/' + payload.ticker)
        .then((res) => {
          commit('SET_BOTTLE_DETAILS_BUY', { data: res.data.buy, id: payload.ticker });
          commit('SET_BOTTLE_DETAILS_SELL', { data: res.data.sell, id: payload.ticker });
        });
    },
    getSettings({ commit }) {
      api.get('api/portfolio/settings')
        .then((res) => {
          if (!res.data.settings.columns_arrange) {
            res.data.settings.columns_arrange = ["isbn"];
            res.data.settings.columns_options = ["isbn"];
          }

          const currentColumnArrangement= res.data.settings.columns_arrange;
            res.data.settings.columns_options?.forEach((option) => {
              if (!currentColumnArrangement.includes(option)) {
                currentColumnArrangement.push(option);
              }
            });

            const updatedColumnsArrangement = currentColumnArrangement.filter((column) => {
              return res.data.settings.columns_options.includes(column);
            });

            const newSettings = {
              currency: res.data.settings.currency,
              columns_arrange: updatedColumnsArrangement,
              columns_options: res.data.settings.columns_options,
              columns_layout: res.data.settings.columns_layout,
              columns_sort: res.data.settings.columns_sort,
              limit: res.data.settings.limit,
            };
            commit('SET_SETTINGS', newSettings);
        });
    },
    getStatistics({ commit }, payload) {
      return new Promise((resolve) => {
        api.get('api/portfolio/statistics/' + payload.currency + '/' + payload.period)
          .then((res) => {
            commit('SET_STATISTICS', res.data.statistics);
            commit('SET_STATISTICS_BOTTLES_CAT', res.data.includesCategories);
            resolve();
          });
        });
    },
    updateFolder({ commit, dispatch }, payload) {
      commit('STORE_NOTHING', null);
      api.put('api/portfolio/folder', payload)
        .then((res) => {
          if (res.status === 200) {
            dispatch('getFolders');
            ElMessage({
              message: i18n.global.t('portfolio.folder_updated'),
              type: 'success',
              grouping: true,
              duration: 5000
            });
          }
        });
    },
    updateSettings({ commit }, payload) {
      api.put('api/portfolio/settings', payload)
        .then((res) => {
          const currentColumnArrangement = res.data.settings.columns_arrange;

            res.data.settings.columns_options?.forEach((column) => {
              if (!currentColumnArrangement.includes(column)) {
                currentColumnArrangement.push(column);
              }
            });

            let updatedColumnsArrangement = currentColumnArrangement.filter((column) => {
              return res.data.settings.columns_options.includes(column);
            });

            const newSettings = {
              currency: res.data.settings.currency,
              columns_arrange: updatedColumnsArrangement,
              columns_options: res.data.settings.columns_options,
              columns_layout: res.data.settings.columns_layout,
              columns_sort: res.data.settings.columns_sort,
              limit: res.data.settings.limit,
            };
            commit('SET_SETTINGS', newSettings);
        });
    },
    createFolder({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        commit('STORE_NOTHING', null);
        api.post('api/portfolio/folder', payload)
          .then((res) => {
            if (res.status === 201) {
              dispatch('getFolders');
              ElMessage({
                message: i18n.global.t('portfolio.folder_created'),
                type: 'success',
                grouping: true,
                duration: 5000
              });
              resolve(res.data);
            } else {
              reject(new Error(i18n.global.t('portfolio.folder_created_error')));
            }
          })
          .catch((e) => {
            if (e.response?.request?.status === 401) {
              dispatch('redirect');
            }
            reject(e);
          });
      });
    },
    moveFolderInAnotherFolder({ commit, dispatch }, payload) {
      return new Promise((resolve) => {
        commit('STORE_NOTHING', null);
        api.put('api/portfolio/folders/parent', payload)
          .then((res) => {
            if (res.status === 200) {
              dispatch('getFolders');
              ElMessage({
                message: i18n.global.t('portfolio.move_folder_success'),
                type: 'success',
                grouping: true,
                duration: 5000
              });
            }
            resolve();
          });
      })
    },
    deleteFolder({ commit, dispatch }, payload) {
      return new Promise((resolve) => {
        commit('STORE_NOTHING', null);
        api.delete('api/portfolio/folder', { data: payload })
          .then((res) => {
            if (res.status === 200) {
              dispatch('getFolders');
  
              ElMessage({
                message: i18n.global.t('portfolio.folder_deleted'),
                type: 'success',
                grouping: true,
                duration: 5000
              });
            }
            resolve();
          });
      })
    },
    createBottle({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        commit('STORE_NOTHING', null);
        api.post('api/portfolio/bottle', payload)
          .then((res) => {
            if (res.status === 201) {
              dispatch('getSettings');
              ElMessage({
                message: i18n.global.t('portfolio.bottle_add'),
                type: 'success',
                grouping: true,
                duration: 5000
              });
              resolve(res);
            } else {
              reject();
            }
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    updateBottle({ commit, dispatch }, payload) {
      commit('STORE_NOTHING', null);
      return new Promise((resolve) => {
      api.put('api/portfolio/bottle', payload)
        .then((res) => {
          if (res.status === 200) {
            dispatch('getFolder', payload.folder_uuid);
            ElMessage({
              message: i18n.global.t('portfolio.bottle_updated'),
              type: 'success',
              grouping: true,
              duration: 5000
            });
          }
          resolve();
      })
        });
    },
    updateBottleFolder({ commit, dispatch }, payload) {
      commit('STORE_NOTHING', null);
      return new Promise((resolve, reject) => {
        api.put('api/portfolio/bottle/parent', payload)
          .then((res) => {
            if (res.status === 201) {
              dispatch('getFolder', payload.current_folder);
              dispatch('getFolder', payload.new_parent_folder);
              resolve();
            }
          }).catch(() => {
            reject();
          })
      })
    },
    updateBottlesFolder({ commit, dispatch }, payload) {
      commit('STORE_NOTHING', null);
      return new Promise((resolve) => {
        api.put('api/portfolio/bottles/parent', payload)
          .then((res) => {
            if (res.status === 200) {
              dispatch('getFolder', payload.current_folder);
              dispatch('getFolder', payload.new_parent_folder);
  
              ElMessage({
                message: i18n.global.t('portfolio.bottle_move'),
                type: 'success',
                grouping: true,
                duration: 5000
              });

              resolve();
            }
          });
      })
    },
    uploadDatabase({ commit }, payload) {
      commit('STORE_NOTHING');
      return new Promise((resolve) => {
        const formData = new FormData();
        formData.append('file', payload);
        api.post('api/portfolio/upload/database', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then((res) => {
            if (res.status === 201) {
              ElMessage({
                message: i18n.global.t('portfolio.file_send'),
                type: 'success',
                grouping: true
              });
            }
            resolve();
          })
          .catch(() => {
            reject();
          })
      });
    },
    setFolderToUpdateId({commit}, payload) {
      commit('SET_FOLDER_TO_UPDATE_ID', payload);
    },
    deleteBottles({ commit }, payload) {
      commit('STORE_NOTHING', null);
      return new Promise((resolve, reject) => {
        api.delete('api/portfolio/bottles', { data: payload })
          .then((res) => {
              resolve(res);
          })
          .catch((e)=> {
            reject(e);
          });
      });
    },
  },
  mutations: {
    SET_BOTTLE_LIST(currentState, value) {
      currentState.bottleList = value;
    },
    ADD_BOTTLE_TO_LIST(currentState, value) {
      value.forEach((bottle) => {
        currentState.bottleList.push(bottle);
      });
    },
    REMOVE_BOTTLE_FROM_LIST(currentState, value) {
      currentState.bottleList = currentState.bottleList.filter((bottle) => {
        return bottle.id !== value;
      });
    },
    SET_SHOW_QUANTITY_ZERO(currentState, value) {
      currentState.showQuantityZero = value;
    },
    SET_MY_FOLDERS(currentState, tree) {
      currentState.folders = tree;
    },
    SET_MY_FOLDER(currentState, tree) {
      currentState.folder = tree;
    },
    SET_ALL_BOTTLES(currentState, tree) {
      currentState.allBottles = tree;
    },
    SET_BOTTLE_DETAILS_BUY(currentState, value) {
      currentState.count_buy = value.data[0].items.length;
      currentState.bottles_details_buy[value.id] = value.data;
    },
    SET_BOTTLE_DETAILS_SELL(currentState, value) {
      currentState.count_sell = value.data[0].items.length;
      currentState.bottles_details_sell[value.id] = value.data;
    },
    SET_SETTINGS(currentState, settings) {
      currentState.settings = settings;
    },
    SET_STATISTICS(currentState, statistics) {
      currentState.statistics = statistics;
    },
    SET_STATISTICS_CURRENCY(currentState, currency) {
      currentState.currency = currency;
    },
    SET_STATISTICS_PERIOD(currentState, period) {
      currentState.period = period;
    },
    SET_STATISTICS_BOTTLES_CAT(currentState, cat) {
      currentState.bottlesCategoryInPortfolio = cat;
    },
    SET_FOLDER_TO_UPDATE_ID(currentState, value) {
      currentState.folderToUpdateId = value;
    },
    SET_SETTINGS_COLUMNS_LAYOUT(currentState, layout) {
      currentState.settings.columns_layout = layout;
    },
    SET_SETTINGS_COLUMNS_SORT(currentState, sort) {
      currentState.settings.columns_sort = sort;
    },
    STORE_NOTHING() { }
  }
};