<template>
  <div v-if="compare" class="search-compare">
    <div class="bloc-search-compare">
      <div class="search-input-compare">
        <input
          id="searchByEnterKey"
          class="input-with-select-compare"
          type="text"
          name="search"
          :placeholder="$t('home.search.search_bottle')"
          v-model="search"
          @keyup.enter="autocompleteSearch()"
          @input="autocompleteSearch()"
        />
      </div>
    </div>
    <div class="search-btn-compare">
      <button @click="forceSearch()" class="generic-btn">
        <img src="@/assets/svg/v2/search.svg" alt="Search icon" />
      </button>
    </div>
  </div>

  <div v-else-if="isSearchPortfolio" class="search-compare">
    <div class="bloc-search-compare">
      <div class="search-input-compare">
        <input
          id="searchByEnterKey"
          class="input-with-select-compare"
          type="text"
          name="search"
          :placeholder="$t('home.search.search_bottle')"
          v-model="search"
          @keyup.enter="autocompleteSearch()"
          @input="autocompleteSearch()"
        />
      </div>
    </div>
    <div class="search-btn-compare">
      <button @click="forceSearch()" class="generic-btn">
        <img src="@/assets/svg/v2/search.svg" alt="Search icon" />
      </button>
    </div>
  </div>

  <div
    v-else-if="noAuth && !routerName('Home') && !routerName('searchProducts')"
    class="search-no-auth"
  >
    <div class="bloc-search-no-auth">
      <div class="search-input-no-auth">
        <input
          id="searchByEnterKey"
          class="input-with-select-no-auth"
          type="text"
          name="search"
          :placeholder="$t('home.search.search_bottle')"
          v-model="search"
          @keyup.enter="autocompleteSearch()"
          @input="autocompleteSearch()"
        />
      </div>
    </div>
    <div class="search-btn-no-auth">
      <button @click="forceSearch()" class="generic-btn">
        <img src="@/assets/svg/v2/search.svg" alt="Search icon" />
      </button>
    </div>
  </div>

  <div v-else-if="!noAuth && !compare" class="search">
    <!-- <div class="search-barcode">
      <div v-if="openBarCodeReader" class="dialog-code-reader">
        <barcodeReader @close="closeReader" @loaded="onLoaded" @search="onDecode"></barcodeReader>
      </div>
      <img @click="openBarCodeReader = !openBarCodeReader" src="@/assets/svg/v2/products/scan.svg" alt="Scan barcode" />
    </div> -->
    <el-popover placement="bottom" width="320">
        <template #reference>
          <div class="popover-search-explain">
            <img src="@/assets/svg/v2/infos.svg" alt="Informations" />
          </div>
        </template>
        <div>
          <ul>
            <li>{{ $t('search.search_explain_1') }}</li>
            <li>{{ $t('search.search_explain_2') }}</li>
            <li>{{ $t('search.search_explain_3') }}</li>
            <li>{{ $t('search.search_explain_4') }}</li>
          </ul>
        </div>
      </el-popover>

    <div class="search-input">
      <h3>{{ $t('home.search.bottle') }}</h3>
      <input
        id="searchByEnterKey"
        class="input-with-select"
        type="text"
        name="search"
        :placeholder="origin === 'bottler' ? `${$t('home.search.search_bottle_bottler')} ${getBottler.name} : ${$t('home.search.search_bottle')}` : $t('home.search.search_bottle')"
        v-model="search"
        @keyup.enter="autocompleteSearch()"
        @input="autocompleteSearch()"
      />
    </div>
    <div class="search-btn">
      <button
        @click="forceSearch()"
        class="generic-btn"
        v-loading="searchLoad"
        element-loading-background="rgba(51, 51, 51, 0.7)"
      >
        <img src="@/assets/svg/v2/search.svg" alt="Search icon" />
        <span>{{ $t('home.search.search') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { find } from 'lodash'

export default {
  props: {
    spirittype: {
      type: Number,
      required: false,
      default: 24,
    },
    isSearchPortfolio: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSearchMultiTicker: {
      type: Boolean,
      required: false,
      default: false,
    },
    isHome: {
      type: Boolean,
      required: false,
      default: false,
    },
    compare: {
      type: Boolean,
      required: false,
      default: false,
    },
    noAuth: {
      type: Boolean,
      required: false,
      default: false,
    },
    pageKey: {
      type: String,
      required: false,
      default: '',
    },
    prefill: {
      type: String,
      required: false,
      default: null,
    },
    preType: {
      type: Number,
      required: false,
      default: null,
    },
    searchLoad: {
      type: Boolean,
      required: false,
      default: false,
    },
    origin: {
      type: String,
      required: false,
      default: null,
    },
  },
  emits: ['search'],
  data() {
    return {
      search: this.prefill || '',
      timeout: null,
      previousSearch: '',
      openBarCodeReader: false,
    }
  },
  computed: {
    ...mapGetters({
      getSearch: 'getSearch',
      getSearchType: 'getSearchType',
      getLists: 'getLists',
      getProducts: 'getProducts',
      getBottler: 'getBottler'
    }),
  },
  mounted() {
    if (this.$route.query.prefill) {
      this.search = this.$route.query.prefill.replace('xxx1', '#')

      this.forceSearch()
    } else {
      if (this.prefill !== null && this.prefill !== '') {
        this.search = this.prefill
        this.forceSearch()
      } else {
        if (
          this.getSearch[this.pageKey] !== '' ||
          this.getSearch[this.pageKey] !== null
        ) {
          this.search = this.getSearch[this.pageKey]
        }
      }
    }

    document.getElementById('searchByEnterKey').addEventListener('keydown', (event) => {
      if (event.key === 'Enter' || event.keyCode === 13) {
        this.forceSearch();
      }
    });
  },
  watch: {
    search() {
      if (this.search === '' && this.origin === 'bottler') {
        this.forceSearch();
      }
    }
  },
  methods: {
    forceSearch() {
      this.openBarCodeReader = false
      
      if (this.noAuth) {
        this.$router.push(`/search-products?prefill=${this.search || ''}`)
      } else if (!this.compare && !this.isSearchPortfolio && !this.isHome) {

        const search_params = {
          value: this.search || '',
        }

        this.$emit('search', search_params, this.pageKey)
        this.previousSearch = this.search

        const setHistoryPayload = {
          search: this.search,
        }

        const searchHistory = localStorage.getItem('searchHistory')
          ? [...JSON.parse(localStorage.getItem('searchHistory'))]
          : []

        if ((!find(searchHistory, { search: this.search }) && this.noAuth)) {
          if (this.search.length > 1) {
            searchHistory.unshift(setHistoryPayload)
            searchHistory.splice(5, searchHistory.length - 5)
            localStorage.setItem('searchHistory', JSON.stringify(searchHistory))
          }
        }
      } else if (this.compare || this.isSearchPortfolio || this.isHome) {
        const search_params = {
          value: this.search || '',
        }

        this.$emit('search', search_params, this.pageKey)
        this.previousSearch = this.search
        if (this.isHome && this.search) {
          this.$router.push(`/search-products?prefill=${this.search.replace('#', 'xxx1') || ''}`)
        }
      }
    },
    autocompleteSearch() {
      if (!this.isHome && this.search.length > 2 && this.origin !== 'bottler') {
        this.forceSearch()
      } else if (this.origin === 'bottler') {
        this.forceSearch()
      }
    },
    routerName(name) {
      return this.$route.name === name
    },
    onDecode(e) {
      this.search = e
      this.openBarCodeReader = false
      this.forceSearch()
    },
    closeReader() {
      this.openBarCodeReader = false
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/styles/root.scss';

.search {
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 76px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: #333;
  box-shadow: 0px 4px 12px -2px rgba(0, 0, 0, 0.16);
  width: 912px;

  .popover-search-explain {
    margin-left: 20px;
  }

  .search-barcode {
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;
  }

  .search-input,
  .search-btn {
    h3 {
      color: white;
      font-size: 15px;
      font-weight: 600;
    }

    button {
      height: 48px;
      width: 148px;
      margin: 15px;

      span {
        font-size: 15px;
      }
    }
  }
}

.search-input {
  width: 100%;
  margin-left: 15px;

  input {
    color: $grey40;
    margin-top: 8px;
    width: 100%;
    outline: none;
    background-color: transparent;
    border: 0;
    color: white;
  }
}

.vertical-divider-search {
  height: 50px !important;
  border-color: $border;
  margin-left: 33px;
  margin-right: 22px;
}

.hide-pc {
  display: none !important;
}

.el-input__wrapper {
  box-shadow: none !important;
}

@media screen and (max-width: 700px) {
  .search {
    flex-direction: row;
    align-items: center;
    max-width: 350px;
    border-radius: 16px;
    padding: 19px 0 !important;

    .hide-tel {
      display: none;
    }

    .hide-pc {
      display: block !important;
      border-color: $border;
    }

    .search-barcode {
      margin-right: 15px;
    }
  }

  .search-input {
    width: 210px;
    margin-left: 40px;

    input {
      color: $grey40;
      margin-top: 0px;
      width: 100%;
      outline: none;
      background-color: transparent;
      border: 0;
      color: white;
    }

    h3 {
      display: none;
    }
  }

  .search-btn {
    display: flex;
    justify-content: center;
    margin-top: 0px;

    button {
      margin-bottom: 0 !important;
      margin-top: 0 !important;
      width: 50px !important;

      span {
        display: none;
      }
    }
  }
}

.search-compare,
.search-no-auth {
  display: flex;
}

.search-compare,
.search-no-auth {
  align-items: center;
  justify-content: space-between;
  border-radius: 76px;
  margin-top: 8px;

  .bloc-search-compare,
  .bloc-search-no-auth {
    display: flex;
    border-radius: 5px;
    border: 2px solid rgb(143, 143, 143);
    background: #333;
    height: 38px;
    margin-right: 8px;
    width: 100%;
    overflow: hidden;

    .select-type-compare,
    .search-input-compare,
    .select-type-no-auth,
    .search-input-no-auth {
      h3 {
        color: white;
        font-size: 15px;
        font-weight: 600;
      }
    }
  }

  .bloc-search-no-auth {
    border: 0;
  }

  .search-btn-compare,
  .search-btn-no-auth {
    button {
      height: 42px;
      width: 50px;
      border-radius: 8px;

      img {
        margin-right: 0px;
      }
    }
  }

  .search-btn-no-auth {
    margin-right: 8px;

    button {
      border-radius: 50%;
      height: 36px;
      width: 36px;
    }
  }
}

.search-no-auth {
  border-radius: 76px;
  border: 1px solid $border;
  background: #333;
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  height: 46px;
}

.select-type-compare,
.select-type-no-auth {
  min-width: 140px;
  cursor: pointer;

  h3 {
    margin-bottom: 3px;
  }
}

.search-input-compare,
.search-input-no-auth {
  width: 100%;

  input {
    color: $grey40;
    width: 100%;
    height: 42px;
    outline: none;
    background-color: transparent;
    border: 0;
    color: white;
    padding-left: 10px;
  }
}

.input-with-select:focus::placeholder,
.input-with-select-no-auth:focus::placeholder,
.input-with-select-compare:focus::placeholder {
  color: transparent;
}
</style>
