import { api } from '@/services/interceptors';

export default {
  state: {
    bidPretradeInfos: null,
    redirect3DSecureDialog: null,
    type: null,
    transactionUUID: null
  },
  getters: {
    getbidPretradeInfos (state) {
      return state.bidPretradeInfos;
    },
    get3DSecureDialog (state) {
      return state.redirect3DSecureDialog;
    },
    getTransactionType (state) {
      return state.type;
    },
    getTransactionUUID (state) {
      return state.transactionUUID;
    },
  },
  actions: {
    subscriptionsPayment({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('STORE_NOTHING', payload);
        api.post('api/auth/subscriptions/payment', payload)
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    cancelPayment({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('STORE_NOTHING', payload);
        api.get('api/auth/payment/cancel/' + payload)
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    orderPayment({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('STORE_NOTHING', payload);
        api.post('api/auth/order/payment', payload)
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    checkTransaction({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('STORE_NOTHING', payload);
        api.get('api/payment/3dsecure/check?RessourceId=' + payload)
          .then((res) => {
            if (res.data.state === 'success') {
              resolve();
            } else {
              reject(res.data.state);
            }
          })
          .catch(() => {
            reject();
          });
      });
    },
  },
  mutations: {
    SET_PAYMENT_IN_PROGRESS(currentState, state) {
      currentState.payment_in_progress = state;
    },
    SET_PRETRADE_INFORMATIONS(currentState, infos) {
      currentState.bidPretradeInfos = infos;
    },
    SET_DIALOG_3DSECURE(currentState, state) {
      currentState.redirect3DSecureDialog = state;
    },
    SET_TRANSACTION_TYPE(currentState, type) {
      currentState.type = type;
    },
    SET_TRANSACTION_UUID(currentState, transactionUUID) {
      currentState.transactionUUID = transactionUUID;
    },
    STORE_NOTHING() { }
  }
};