<template>
  <div class="iframe-3ds-dialog">
    <div class="iframe-3ds-dialog-content">
      <div class="iframe-3ds-dialog-content-header">
        <h1>{{ $t('commons.secure3d') }}</h1>
        <button @click="close()">
          <img src="@/assets/svg/v2/close.svg" alt="Close 3ds secure" />
          {{ $t('commons.close') }}
        </button>
      </div>

      <iframe
        :src="get3DSecureDialog"
        width="100%"
        height="100%"
        frameborder="0"
      ></iframe>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import m from 'moment'
import { find } from 'lodash'

export default {
  props: {
    cart: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      get3DSecureDialog: 'get3DSecureDialog',
      getTransactionType: 'getTransactionType',
      getTransactionUUID: 'getTransactionUUID',
      kycState: 'kycState',
      subscriptions: 'getSubscriptions',
      subscriptionsInformations: 'getSubscriptionsInformations',
    }),
  },
  methods: {
    ...mapActions({
      cancelPayment: 'cancelPayment',
      authverificationActions: 'authverification',
      getSubscriptions: 'getSubscriptions',
      checkTransaction: 'checkTransaction',
    }),
    async close() {
      this.$store.commit('SET_DIALOG_3DSECURE', null)
    },
  },
  async mounted() {
    setTimeout(async() => {
      const inter = setInterval(async () => {
        if (this.get3DSecureDialog) {
          if (this.getTransactionType === 'orders') {
            await this.checkTransaction(this.getTransactionUUID).then(() => {
              this.$emit('end', 'clear')
            })
          } else {
            this.authverificationActions()
            this.getSubscriptions()
  
            if (this.kycState) {
              if (this.kycState.kyc_ask) {
                this.$store.commit('SET_DIALOG_3DSECURE', null)
              }
            }
  
            if (this.cart.length > 0) {
              const findRecentSubscription = find(this.subscriptions, (s) => {
                return (
                  s.commitment > m().format('YYYY-MM-DD HH:mm:ss') &&
                  s.type === this.cart[0].id
                )
              })
              if (findRecentSubscription) {
                this.$emit('clear')
                this.$store.commit('SET_DIALOG_3DSECURE', null)
              }
            }
          }
        } else {
          clearInterval(inter)
        }
      }, 2000)
    }, 4000);
  },
}
</script>

<style lang="scss">
@import '@/assets/styles/root.scss';

.iframe-3ds-dialog {
  z-index: 9999;
  position: relative;

  .iframe-3ds-dialog-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #444;
    border: 1px solid $border;
    width: 80%;
    height: 90%;
    border-radius: 20px;
    overflow: hidden;
    overflow-y: scroll;

    .iframe-3ds-dialog-content-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 23px;
      background: #444;
      border-radius: 20px 20px 0px 0px;
      border-bottom: 1px solid $border;

      button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: transparent;
        padding: 0 5px;
        border: 1px solid $border;
        background: #f43f5e;
        color: white;
        width: 85px;
        height: 38px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .iframe-3ds-dialog-content {
    width: 99% !important;
  }
}

.iframe-3ds-dialog-content::-webkit-scrollbar {
  width: 0px;
}

.iframe-3ds-dialog-content::-webkit-scrollbar-track {
  background: #333333;
}

.iframe-3ds-dialog-content::-webkit-scrollbar-thumb {
  background-color: #818181;
  border-radius: 10px;
}

.iframe-3ds-dialog-content::-webkit-scrollbar-thumb:hover {
  background-color: $base-color;
}
</style>
