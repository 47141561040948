export default {
  "commons": {
    "refuse_match_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отказаться от матча"])},
    "date_expire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок действия"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
    "buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купить и продать"])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обмен"])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocktrade"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Портфель"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страна"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регион"])},
    "bid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ставка"])},
    "ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спросите"])},
    "giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giver"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taker"])},
    "report_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщить об ошибке"])},
    "account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип счета"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказы"])},
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущие заказы"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад к"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применить"])},
    "product_origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Происхождение продукта"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжить"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связь"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выйти из системы"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать аккаунт"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск и торговля"])},
    "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписки"])},
    "price_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена включает НДС"])},
    "dark_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ночной режим"])},
    "light_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Понятный режим"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История сделок"])},
    "portfolio_gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Портфель"])},
    "matchs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спички"])},
    "transactions_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История сделок"])},
    "account_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки аккаунта"])},
    "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активировано"])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Деактивировано"])},
    "alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления"])},
    "alerts_clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить все"])},
    "alerts_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На данный момент нет уведомлений"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите"])},
    "no_copy_paste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы не можете щелкнуть правой кнопкой мыши"])},
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доставка"])},
    "secure3d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверка сделки"])},
    "cancel_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])},
    "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НДС"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всего"])},
    "too_many_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы делаете слишком много запросов"])},
    "see_products_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы достигли ежедневного лимита консультаций. Проверьте свою учетную запись, чтобы избежать дальнейших ограничений."])},
    "check_inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверьте свою запись:"])},
    "generic_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка, повторите попытку позже"])},
    "login_retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, войдите еще раз"])},
    "email_phone_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, подтвердите свой e-mail и телефон перед выполнением этого действия"])},
    "not_authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы не авторизованы для выполнения этого действия"])},
    "service_unaivalable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервис недоступен, пожалуйста, обратитесь в службу поддержки"])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лента новостей"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активные"])},
    "inputs_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, проверьте правильность заполнения всех обязательных полей"])},
    "lang": {
      "lang_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите язык"])},
      "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR"])},
      "fr_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Французский"])},
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EN"])},
      "en_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Английский"])},
      "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ES"])},
      "es_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Испанская"])},
      "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT"])},
      "it_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Италия"])},
      "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PL"])},
      "pl_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Польша"])},
      "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DE"])},
      "de_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немецкий"])},
      "cs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CS"])},
      "cs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чехия"])},
      "da": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DA"])},
      "da_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Датская"])},
      "el": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EL"])},
      "el_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Греция"])},
      "hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HU"])},
      "hu_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Венгерский"])},
      "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JA"])},
      "ja_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Японская"])},
      "ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KO"])},
      "ko_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корейская"])},
      "pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PT"])},
      "pt_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Португальский"])},
      "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ВЕЛИКОБРИТАНИЯ"])},
      "ru_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Русский"])},
      "sk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SK"])},
      "sk_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Словакия"])},
      "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZH"])},
      "zh_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Китайская"])}
    },
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщить о проблеме"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покупатель"])},
    "seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продавец"])},
    "code_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код отправлен"])},
    "code_time_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обратите внимание, код действителен в течение 15 минут"])},
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статьи"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профессионалы"])},
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Индивидуальный"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кошелек"])},
    "my_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кошелек"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать"])},
    "error_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, проверьте свою запись или свяжитесь со службой поддержки"])},
    "copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопировать"])},
    "copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удачная копия"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтр"])}
  },
  "major": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы должны быть старше 18 лет, чтобы посетить этот сайт"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, проверьте свой возраст для входа."])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мне больше 18 лет"])},
    "nok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мне нет 18 лет"])},
    "conditions_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заходя на этот сайт, вы соглашаетесь с тем, что"])},
    "conditions_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["правила и условия использования"])},
    "conditions_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["и"])},
    "conditions_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["политика конфиденциальности."])}
  },
  "home": {
    "revolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Революция в самом себе"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка для покупки, продажи и торговли спиртными напитками"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы любитель виски, коллекционер коньяка или профессионал, стремящийся к совершенству, то биржевая платформа для вас. Оптимальная безопасность, полная прозрачность и низкие комиссии: откройте для себя торговлю спиртными напитками с полным спокойствием."])},
    "secure_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Безопасные сделки"])},
    "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сниженная комиссия 2,5% с НДС"])},
    "verified_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% верифицированные бесплатные аккаунты"])},
    "title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будь вы частным лицом или профессионалом, изучите нашу совокупность из более чем 70 источников цен на тысячи наименований спиртных напитков!"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создайте свой бесплатный аккаунт"])},
    "buy_and_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покупка и продажа"])},
    "buy_and_sell_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Благодаря биржевой платформе вы можете покупать и продавать непрерывно. Благодаря нашим стандартизированным справочникам вы легко найдете все доступные предложения. Найдите то, что вы ищете, или выразите свой интерес, сопоставьте поиск контрагентов, сэкономьте время и торгуйте по лучшей цене!"])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Биржа"])},
    "swap_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы являемся единственной платформой, которая предлагает возможность обмена бутылок с использованием нашего залогового денежного решения. Никакого риска контрагента, ваши деньги в безопасности на платформе до момента доставки бутылок."])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блокированная торговля"])},
    "blocktrade_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Совершить сделку купли-продажи можно с помощью Block Trade - эксклюзивной для биржевой платформы функции, которая генерирует уникальную ссылку для каждой сделки."])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление портфелем"])},
    "portfolio_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для более опытных коллекционеров и инвесторов биржевая платформа предлагает инструмент управления портфелем, который позволяет отслеживать ваши позиции и их стоимость благодаря ценам на платформе в реальном времени и нашим историческим данным. Больше никаких бесконечных вопросов о стоимости ваших бутылок, объективный и непредвзятый взгляд, как никогда раньше."])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исторические данные о ценах"])},
    "price_history_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы собрали самую полную историю цен на все возможные спиртные напитки, начиная с 2002 года! Все цены стандартизированы и доступны в 18 различных валютах."])},
    "title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гарантия доверия"])},
    "secure_transactions_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежи осуществляются через нашего поставщика платежных услуг Mangopay, что обеспечивает бесперебойность сделок, отсутствие риска исчезновения денег, вы никогда не отправите их контрагенту."])},
    "low_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Самые низкие комиссии на рынке"])},
    "low_fees_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У нас самые низкие комиссии на рынке: 2,5 % с НДС для покупателя и продавца, никаких сборов за листинг или резерв. Вы платите только в случае эффективной сделки."])},
    "anonymous_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предтранзакционная анонимность"])},
    "anonymous_transactions_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Независимо от того, являетесь ли вы частным лицом или профессионалом, наша платформа - это решение всех сегодняшних проблем благодаря своей предтранзакционной анонимности."])},
    "verified_accounts_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Благодаря контролю регистрации со стороны нашего поставщика платежных услуг до подтверждения аккаунта, на платформу не попадет ни один фантомный аккаунт, мошенник или бот. Отслеживаемость и безопасность обеспечены полностью."])},
    "title_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предложение, соответствующее вашим потребностям"])},
    "join_community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Присоединяйтесь к сообществу, чтобы находить, управлять и торговать своими спиртными напитками!"])},
    "search": {
      "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип спиртных напитков"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите"])},
      "bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бутылка"])},
      "search_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тикер, название, EAN, ключевые слова..."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск"])},
      "search_bottle_bottler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск внутри"])}
    },
    "without_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Торговля доступна без подписки!"])}
  },
  "newsfeed": {
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блог и обзор"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лента новостей"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["События"])},
    "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить фильтры"])},
    "event_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
    "sort_asc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сортировать по алфавиту"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страна"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вилле"])},
    "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Даты"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Месяц"])},
    "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип спиртных напитков"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск..."])},
    "events_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтр событий"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата начала работы"])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата окончания"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["с сайта"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["на"])},
    "events_show_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["предстоящие события"])},
    "events_show_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["предстоящие события"])}
  },
  "subscriptions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Годовые подписки"])},
    "price_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Годовые тарифы"])},
    "price_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ежемесячные тарифы"])},
    "free_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 месяц бесплатно при годовой оплате"])},
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Специальное предложение"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профессиональное предложение"])},
    "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Премиальные исторические цены"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для пользователей, которым нужны полные исторические данные без временной задержки."])},
    "all_taxs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])},
    "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["в месяц"])},
    "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["в год"])},
    "per_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["по типу спиртного"])},
    "references_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ ко всем ссылкам"])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исторические цены без временной задержки"])},
    "advanced_stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расширенная статистика"])},
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписаться на спиртные напитки"])},
    "choice_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбор спиртных напитков:"])},
    "wine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вина"])},
    "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другие"])},
    "complementary_module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительный модуль"])},
    "portfolio_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для опытных коллекционеров, которые хотят получить больше данных и реальный инструмент управления."])},
    "portfolio_subtitle_pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Присоединяйтесь к сообществу специалистов, чтобы анализировать, управлять и торговать своими спиртными напитками!"])},
    "advanced_gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расширенное управление портфелем"])},
    "choice_this_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите это предложение"])},
    "add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить в корзину"])},
    "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корзина"])},
    "see_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотреть"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ежемесячный платеж"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ежегодный платеж"])},
    "sub_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Субтотал"])},
    "next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Следующая выборка"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всего"])},
    "include_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НДС (20%)"])},
    "payment_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти к оплате"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
    "already_in_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эта подписка уже в вашей корзине"])},
    "already_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уже являетесь подписчиком"])},
    "cart_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша корзина пуста"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад к подпискам"])},
    "contact_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контактная информация"])},
    "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Биллинг"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Способ оплаты"])},
    "payment_method_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакции на биржевой платформе осуществляются через нашего поставщика платежных услуг Mangopay."])},
    "banking_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кредитная карта"])},
    "card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер карты"])},
    "card_fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя на платежной карте"])},
    "card_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата истечения срока действия (ММ/ГГ)"])},
    "card_code_security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код безопасности"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завершите покупку"])},
    "soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скоро будет"])},
    "recap_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сводка платежей"])},
    "buy_commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия за покупку"])},
    "buy_commission_tva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НДС Комиссия за покупку"])},
    "already_sub_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписчик"])}
  },
  "payment": {
    "cvx_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код проверки карты (на обратной стороне карты, обычно состоит из 3 цифр)"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При отмене были учтены"])},
    "cancel_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена заказа невозможна, пожалуйста, свяжитесь со службой поддержки"])}
  },
  "login": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добро пожаловать на биржевую площадку"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получите доступ к бесперебойной, безопасной и стандартизированной торговой среде."])},
    "save_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить мой логин"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забыли пароль?"])},
    "forgot": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сброс пароля"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы получите электронное письмо с инструкциями по восстановлению пароля."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите ваш e-mail"])}
    },
    "reset": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сброс пароля"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы получите электронное письмо с инструкциями по восстановлению пароля."])},
      "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый пароль"])},
      "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить пароль"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Верификационный код"])}
    },
    "doubleFA": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Двойная аутентификация"])},
      "code_reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите код, полученный по электронной почте или телефону"])}
    },
    "password_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш пароль должен состоять из 7 символов и содержать хотя бы одну заглавную букву."])},
    "wrong_values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, заполните все поля правильно"])},
    "wrong_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите действительный код"])}
  },
  "register": {
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать аккаунт"])},
    "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civility"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
    "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата рождения"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Национальность"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регион"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страна"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
    "address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер улицы"])},
    "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почтовый индекс"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вилле"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
    "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить пароль"])},
    "mangopay_accept_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я принимаю"])},
    "mangopay_accept_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["правила и условия использования"])},
    "mangopay_accept_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mangopay."])},
    "tep_accept_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я принимаю"])},
    "tep_accept_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["правила и условия использования"])},
    "tep_accept_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["и"])},
    "tep_accept_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["политика конфиденциальности"])},
    "tep_accept_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de Биржевая платформа."])},
    "newsletter_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подпишитесь на нашу рассылку"])},
    "newsletter_enter_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите вашу электронную почту"])},
    "create_and_continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создайте мой бесплатный аккаунт и продолжайте"])},
    "end_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завершить регистрацию"])},
    "verify_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получить верифицированную учетную запись"])},
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название компании"])},
    "company_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полный адрес"])},
    "company_zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почтовый индекс"])},
    "company_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вилле"])},
    "company_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страна"])},
    "company_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профессиональная электронная почта"])},
    "company_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип компании"])},
    "company_siret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер компании"])},
    "company_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер НДС"])},
    "company_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моя компания"])},
    "company_siret_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["123 456 789 00010"])},
    "step": {
      "step_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая информация"])},
      "step_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждение 2FA"])},
      "step_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверка KYC"])},
      "step_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банковские реквизиты"])},
      "step_1_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация"])},
      "step_4_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банковские реквизиты"])}
    },
    "step_0": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получите доступ к бесперебойной, безопасной и стандартизированной торговой среде."])},
      "usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите способ использования"])},
      "account_no_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип счета не может быть изменен после завершения регистрации."])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Индивидуалы"])},
      "professionnal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профессионалы"])},
      "individual_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аккаунт для личного пользования"])},
      "professionnal_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Верифицированный профессиональный аккаунт"])}
    },
    "step_1": {
      "general_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая информация"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, заполните все поля ниже"])},
      "personnal_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Личный кабинет"])},
      "business_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корпоративный счет"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите"])},
      "business_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профессиональная категория"])},
      "business_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правовая форма"])},
      "business_part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бенефициарные владельцы, владеющие более чем 25% акций?"])},
      "business_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, укажите информацию о других бенефициарных владельцах, владеющих более 25% акций."])},
      "add_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])},
      "birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["родился на"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проживать в"])},
      "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" и национальность"])},
      "password_requirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль должен содержать не менее 8 символов, включая заглавную букву, строчную букву, цифру и специальный символ."])}
    },
    "step_2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждение вашей электронной почты и номера телефона"])},
      "subtitle_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите код, полученный по электронной почте"])},
      "subtitle_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите код, полученный по телефону"])},
      "email_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вам отправлено электронное письмо."])},
      "phone_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вам отправлено SMS."])},
      "no_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не получили код?"])},
      "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить на"])},
      "validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвержденные"])},
      "await_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидание подтверждения"])},
      "code_err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите действительный код"])}
    },
    "before_kyc": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поздравляем, у вас уже есть бесплатный доступ ко многим функциям сайта!"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хотите подтвердить свою личность прямо сейчас, чтобы насладиться всеми возможностями?"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получить верифицированную учетную запись"])},
      "continue_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получите бесплатную верифицированную учетную запись"])}
    },
    "step_3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Верификация вашей личности"])},
      "document_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите тип документа"])},
      "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Паспорт"])},
      "identity_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удостоверение личности"])},
      "import_file_passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Импортируйте фотографию из паспорта"])},
      "import_file_identity_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Импортируйте фотографию обеих сторон вашего удостоверения личности"])},
      "choice_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите файл"])},
      "condition_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Понятный и читаемый файл"])},
      "condition_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размер файла не более 7 Мб"])},
      "condition_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формат JPG, JPEG или PNG"])},
      "validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить на проверку"])},
      "verification_timing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Среднее время проверки составляет около 24 часов."])},
      "active_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ограниченная функциональность"])},
      "description_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пройдите процедуру регистрации и получите доступ ко всем функциям."])},
      "documents_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправляемые документы"])},
      "verification_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В настоящее время проверяется"])},
      "verification_in_progress_restriction_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете искать бутылки и просматривать исторические цены."])},
      "verification_in_progress_restriction_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ к транзакционной части или подпискам пока невозможен."])},
      "send_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправлено на"])},
      "kbis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выписка Kbis или эквивалентный документ давностью менее трех месяцев"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копия устава"])},
      "passport_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Паспорт или удостоверение личности, обе стороны"])},
      "document_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документ отправлен:"])},
      "documents_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправленные документы:"])},
      "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отказано"])},
      "refused_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина отказа:"])},
      "IDENTITY_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Личный кабинет"])},
      "REGISTRATION_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фирменный стиль"])},
      "ARTICLES_OF_ASSOCIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Устав"])},
      "SHAREHOLDER_DECLARATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Декларация акционеров"])},
      "ADDRESS_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверка адреса"])},
      "payment_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти к оплате"])},
      "error_card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный номер карты"])},
      "error_card_fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя и фамилия на недействительной карточке"])},
      "error_card_expiration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверная дата истечения срока действия"])},
      "error_card_cvx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный код кредитной карты"])},
      "title_payment_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сборы KYC для компаний"])},
      "kyc_documents_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документы успешно отправлены. Проверка будет произведена в течение 24 часов."])},
      "ubo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Декларация бенефициарных владельцев"])},
      "verification_timing_individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Среднее время проверки составляет всего несколько минут"])},
      "kyc_explain_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Делайте четкие, резкие фотографии:"])},
      "kycexplain_content_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Убедитесь, что удостоверение личности или паспорт полностью видны, без теней и бликов, и что все детали хорошо читаемы."])},
      "kyc_explain_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправляйте в совместимом формате:"])},
      "kycexplain_content_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Используйте формат JPEG, JPG или PNG с достаточным разрешением, чтобы информация была хорошо видна."])},
      "kyc_explain_title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ознакомьтесь с полным текстом документа:"])},
      "kycexplain_content_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Убедитесь, что все необходимые страницы включены отдельно."])}
    },
    "step_4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банковские реквизиты"])},
      "why_enter_banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите свои банковские реквизиты, чтобы получать деньги от продаж."])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваши данные будут проверены нашим отделом обслуживания клиентов. Вы получите электронное письмо, когда ваши банковские реквизиты будут подтверждены."])},
      "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "iban_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN, номер счета"])},
      "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["БИК"])},
      "bic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC, ABA"])},
      "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регион"])},
      "region_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, заполните"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])},
      "mangopay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакции на биржевой платформе осуществляются через нашего поставщика платежных услуг Mangopay."])},
      "banking_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваши банковские реквизиты сохранены"])},
      "banking_already_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваши банковские реквизиты уже отправлены"])}
    },
    "success": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поздравляем! Ваша учетная запись теперь подтверждена."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотр бутылок"])},
      "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ к моему портфелю"])},
      "unlock_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неограниченный активный режим"])},
      "unlock_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Теперь вы можете получить доступ к полному набору функций."])}
    },
    "us_phone_regulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я согласен получать сообщения от Биржевой платформы по указанному выше номеру телефона. Может взиматься плата за передачу данных, чтобы отказаться от подписки, ответьте STOP."])},
    "us_phone_regulation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы отказываетесь от SMS-сообщений 2FA, вы не сможете подтвердить свою учетную запись. Вы всегда можете отказаться от подписки после подтверждения своего номера телефона через нашу систему SMS 2FA."])},
    "birth_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Место рождения"])},
    "birth_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страна рождения"])},
    "company_siret_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер СИРЕТ"])}
  },
  "search": {
    "barcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отсканируйте или введите штрих-код"])},
    "last_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последние поиски"])},
    "delete_all_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить все"])},
    "find_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["представленный продукт"])},
    "find_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["представленные товары"])},
    "total_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["продукт найден с помощью этого поиска"])},
    "total_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["продукты, найденные с помощью этого поиска"])},
    "total_product_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["товар найден"])},
    "total_products_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["найденные продукты"])},
    "empty_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пропала бутылка?"])},
    "filter_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтр по"])},
    "see_composite_ticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Смотреть все"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купить"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продать"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taker"])},
    "giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giver"])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блокированная торговля"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
    "no_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не найдено ни одной бутылки, соответствующей вашему запросу."])},
    "history_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотреть историю поиска"])},
    "no_auth_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создайте учетную запись, чтобы получить неограниченный доступ"])},
    "no_auth_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрироваться бесплатно"])},
    "connect_access_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для использования этой функции требуется вход"])},
    "empty": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пропавшая бутылка"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название бутылки"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При необходимости уточните детали недостающей бутылки"])},
      "description_placeholder_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете описать причины отказа"])},
      "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительно"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
      "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.thexchangeplatform.io"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])},
      "missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Результаты не найдены."])}
    },
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущие заказы"])},
    "bottler_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страница боттлера"])},
    "search_explain_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете использовать комбинации ключевых слов."])},
    "search_explain_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск не чувствителен к регистру."])},
    "search_explain_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет необходимости указывать \"годы\"."])},
    "search_explain_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Написание общей категории спиртных напитков (ром, виски, коньяк и т. д.) не даст никаких результатов."])}
  },
  "orders": {
    "swap_taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обменник"])},
    "swap_giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обменник"])},
    "account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип счета"])},
    "current_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущие заказы"])},
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Направление"])},
    "creation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата создания"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цены"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество"])},
    "update_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить ордер на покупку"])},
    "update_sell_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить ордер на продажу"])},
    "update_block_trade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить блок-торговлю"])},
    "update_swap_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить своп-ордер"])},
    "display_deleted_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать удаленные ордера"])},
    "wish_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я хотел бы получать"])},
    "possess_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я владею"])},
    "in_exchange_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["в обмен на"])},
    "that_trade_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На что я хотел бы обменять"])},
    "update_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказ успешно изменен"])},
    "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказ успешно создан"])},
    "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая сумма :"])},
    "total_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество заказов :"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название, цена..."])}
  },
  "match": {
    "refuse_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина отказа"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отказаться"])}
  },
  "product_page": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])},
    "no_bottles_found_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для этой ссылки не найдено ни одной бутылки."])},
    "add_to_portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить в портфель"])},
    "btn_buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покупка/Продажа"])},
    "btn_swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обмен"])},
    "btn_blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блокчейн"])},
    "product_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация о бутылке"])},
    "ticker_composite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Составной тикер"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
    "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип спиртных напитков"])},
    "distillery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distillerie"])},
    "bottler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Боттлер"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возраст"])},
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бренд"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abv"])},
    "vintage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Винтаж"])},
    "bottling_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Год розлива"])},
    "bottle_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Содержание"])},
    "num_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер бутылки"])},
    "num_bottles_produced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество произведенных бутылок"])},
    "base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["База"])},
    "production_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Способ производства"])},
    "distillery_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус винокурни"])},
    "classification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Классификация"])},
    "empty_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет заказа на эту бутылку"])},
    "composite_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Составной тикер"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активные"])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неактивный"])},
    "search_add_bottle_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск и добавление бутылки на график"])},
    "isbn_ticker_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN, тикер или название продукта"])},
    "bottle_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бутылка, которую вы ищете, не найдена"])},
    "trades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продажи"])},
    "sources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Источники"])},
    "empty_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет изображений для этой ссылки"])},
    "cask_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер бочки"])},
    "show_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотр исторических цен"])},
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Живые заказы"])},
    "number_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество бутылок"])},
    "orders": {
      "create_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать заявку на покупку"])},
      "create_sell_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать ордер на продажу"])},
      "create_taker_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать ордер Swap Taker"])},
      "create_giver_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать ордер на своп"])},
      "create_blocktrade_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать блокчейн"])},
      "weight_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Весовая единица"])},
      "size_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размер единицы"])},
      "buy_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покупка"])},
      "sell_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продажи"])},
      "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать заказ"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Индивидуальный"])},
      "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro"])},
      "mix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mix"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В настоящее время заказы не принимаются."])},
      "buy_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказ на покупку"])},
      "buy_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена покупки"])},
      "buy_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество"])},
      "buy_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опционы"])},
      "buy_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запланировать дату и время окончания торгов?"])},
      "buy_create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать заявку на покупку"])},
      "sell_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявка на продажу"])},
      "sell_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прайс-лист"])},
      "sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество"])},
      "sell_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вес"])},
      "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Длина"])},
      "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ширина"])},
      "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Высота"])},
      "sell_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить фотографии"])},
      "sell_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опционы"])},
      "sell_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запланировать дату и время окончания торгов?"])},
      "sell_create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать ордер на продажу"])},
      "success_create_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказ успешно создан"])},
      "success_create_dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш запрос был обработан. С вами свяжутся в ближайшее время"])},
      "ft_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правила, которые необходимо соблюдать в качестве"])},
      "buy_ft_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Избегайте излишнего переполнения книги заявок"])},
      "buy_ft_1_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ограничьте свои заказы реальными сделками."])},
      "buy_ft_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Избегайте повторных отказов"])},
      "buy_ft_2_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неоднократные отказы от сделки могут быть восприняты как злонамеренные или безответственные. Убедитесь, что вы заключаете сделки только в том случае, если намерены их завершить."])},
      "buy_ft_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соблюдайте сроки восстановления"])},
      "buy_ft_3_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неоднократная неявка за посылками в срок может привести к неудобствам для продавца и дополнительным расходам."])},
      "buy_ft_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соблюдайте целостность книги заявок"])},
      "buy_ft_4_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтасовка или манипуляции с книгой ордеров, деактивация интереса после отклонения совпадения и т. д. считаются обманными действиями. Убедитесь, что ваши действия прозрачны."])},
      "buy_ft_5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будьте готовы к сопутствующим расходам"])},
      "buy_ft_5_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Могут применяться местные импортные и таможенные налоги. Убедитесь, что вы готовы заплатить их при заключении сделки."])},
      "sell_ft_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предоставление точной информации"])},
      "sell_ft_1_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Убедитесь, что описание бутылки точное и правдивое. Это поможет покупателям принять взвешенное решение. Предоставьте полную фотографию спереди и сзади."])},
      "sell_ft_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ознакомьтесь с деталями предложения"])},
      "sell_ft_2_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы обязуетесь продать бутылку, выполните это обязательство. Любое невыполненное предложение будет иметь последствия для продавца."])},
      "sell_ft_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гарантия качества упаковки и ее содержимого"])},
      "sell_ft_3_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неоднократные доставки поврежденных посылок (порча, поломка, утечка) и неправильное содержимое влияют на вас. Убедитесь, что вы тщательно упаковываете свои бутылки."])},
      "sell_ft_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соблюдайте сроки доставки"])},
      "sell_ft_4_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неоднократная несвоевременная отправка может повлиять на ваш доступ к зоне сделок. Во избежание задержек планируйте свои действия соответствующим образом."])},
      "package_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка на пакет:"])},
      "dispute_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете подробно описать свою проблему здесь, чтобы облегчить решение проблемы"])},
      "confirm_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердите получение посылки"])},
      "confirm_shipment_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждая получение посылки, вы подтверждаете, что товар соответствует вашим ожиданиям."])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активы"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалено"])},
      "match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Матч"])},
      "match_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидание"])},
      "ship_send_pending_buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доставка"])},
      "ship_send_pending_seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить на"])},
      "delivery_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доставка"])},
      "delivery_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поставляется"])},
      "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Судебная практика"])},
      "end_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завершено"])},
      "update_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать"])},
      "shipping_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Детали поставки"])},
      "track_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер отслеживания"])},
      "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платный"])},
      "track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отследить мою посылку"])},
      "shipping_details_see": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотреть детали доставки"])},
      "in_transit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В поставке"])},
      "order_create_block_by_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, примите или отклоните ваши предложения перед созданием нового ордера"])},
      "blocktrade_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка Block Trade"])},
      "blocktrade_unique_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уникальная ссылка"])},
      "blocktrade_secure_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Безопасная транзакция с нашим платежным провайдером Mangopay"])},
      "delete_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
      "shipping_download_sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать накладную"])},
      "bo_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При проверке"])},
      "anonymous_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анонимная сделка (3 EUR с НДС)"])},
      "max_min_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы должны загрузить изображения бутылки (не более 8)"])},
      "duplicate_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы не можете поместить одно и то же изображение более одного раза"])},
      "pictures_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принимаются только изображения размером менее 7 мб и с расширением .JPG, .JPEG и .png."])},
      "add_min_one_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш заказ не может быть действителен менее одного часа"])},
      "empty_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На данный момент заказы на покупку не принимаются."])},
      "empty_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На данный момент ордера на продажу не принимаются."])},
      "anonymous_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покупатель не узнает вашу личность"])},
      "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Домашняя коллекция"])},
      "pickup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посылку из вашего дома забирает UPS"])},
      "pickup_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата сбора"])},
      "pickup_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время первого забора"])},
      "pickup_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время последнего самовывоза"])},
      "not_match_preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот заказ не соответствует вашим предпочтениям по стране или предпочтениям контрагента"])},
      "load_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить изображения"])},
      "or_drag_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["или перетащить"])},
      "file_format_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Только .JPG, .PNG и .JPEG (не более 7 МБ на фотографию)"])},
      "pickup_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно в пунктах эстафеты"])},
      "kyc_not_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы получить доступ к разделу транзакций, необходимо подтвердить свою личность."])},
      "kyc_redirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверьте мою личность"])}
    },
    "price_history": {
      "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исторические цены"])},
      "compare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить бутылку"])},
      "responsability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Графики представлены только для информации. Мы не несем ответственности за любые ошибки."])},
      "data_info_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наши исторические данные объединяют"])},
      "data_info_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["продажи"])},
      "data_info_11-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["продажа"])},
      "data_info_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["с сайта"])},
      "data_info_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["разные источники."])},
      "data_info_3-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["источник."])},
      "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта"])},
      "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тарифы"])},
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Среднее"])},
      "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Топ"])},
      "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bas"])},
      "base_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["База 100"])},
      "line_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Линейный график"])},
      "candlesticks_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["График свечи"])},
      "chart_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Варианты графиков"])},
      "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сайт"])},
      "perf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Производительность"])},
      "since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["с сайта"])},
      "delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цены отображаются с задержкой"])},
      "delay_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["месяц"])},
      "no_recent_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исторические данные о ценах отсутствуют. Если мы еще не включили их, пожалуйста, свяжитесь со службой поддержки для их добавления."])},
      "current_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущий вид"])},
      "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ежедневно"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ежемесячно"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ежегодно"])},
      "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Еженедельник"])},
      "compare_dialog": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сравнить с"])},
        "no_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бутылки не выбраны"])},
        "same_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эта бутылка уже на графике"])},
        "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы достигли лимита в 6 бутылок для графика"])}
      },
      "composite": {
        "display_composite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотреть состав"])}
      }
    },
    "report": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщить об ошибке"])},
      "error_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип ошибки"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Примечания"])},
      "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительно"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опишите проблему подробнее здесь..."])},
      "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.thexchangeplatform.io"])},
      "pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скриншоты / Изображения"])},
      "pictures_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не более 2 изображений."])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])}
    },
    "shipping_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Детали поставки"])},
    "success_send_error_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш запрос принят во внимание"])},
    "release_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата выхода"])},
    "release_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цены на выходе"])}
  },
  "matchs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спички"])},
    "match_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найти покупателя"])},
    "match_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подбор продавца"])},
    "accept_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купить"])},
    "accept_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принимать и продавать"])},
    "show_transactions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Просмотреть мои ", _interpolate(_named("num_transac")), " текущие сделки"])},
    "show_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать фотографии"])},
    "buying_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мой ордер на покупку"])},
    "selling_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявка на продажу"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покупатель"])},
    "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В процессе"])},
    "buying_order_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказ на покупку"])},
    "sold_by_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контрагент"])},
    "selling_order_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мой ордер на продажу"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет совпадений для отображения."])}
  },
  "account_verification_details": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание учетной записи и функции доступны для всех"])},
    "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Особенности"])},
    "unverified_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неаудированный счет"])},
    "verified_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверенная учетная запись"])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лента новостей"])},
    "full_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бесплатный и неограниченный полный доступ"])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исторические цены"])},
    "advanced_visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продвинутая визуализация"])},
    "month_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15-месячная отсрочка"])},
    "month_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отсрочка на 12 месяцев"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Портфель"])},
    "portfolio_month_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отсроченная оценка на 15 месяцев"])},
    "portfolio_month_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отсроченная оценка на 12 месяцев"])},
    "tickers_limit_50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50 уникальных тикеров"])},
    "tickers_limit_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100 уникальных тикеров"])},
    "database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["База данных"])},
    "tickers_limit_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50 тикеров в день"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Торговля"])},
    "orders_visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотр активных ордеров"])},
    "orders_not_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Без транзакционного доступа"])},
    "kyc_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверка личности бесплатна для физических лиц и платная для профессионалов. KYC осуществляется нашим поставщиком платежных услуг Mangopay."])},
    "not_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неограниченный полный доступ"])}
  },
  "portfolio": {
    "copied_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор скопирован"])},
    "folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл"])},
    "import_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Импорт файла CSV или Excel"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])},
    "bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бутылка"])},
    "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать все"])},
    "unselect_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать все"])},
    "research_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск бутылки"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Портфель"])},
    "to_guide_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы сориентировать вас,"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать"])},
    "load_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить файл"])},
    "or_drag_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["или перетащить"])},
    "file_csv_and_xlsx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Только файлы CSV и XLSX"])},
    "an_import_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["импортная модель"])},
    "columns_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колонки"])},
    "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])},
    "average_holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Средний период владения"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Портфель"])},
    "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переименовать"])},
    "move_in_another_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переместить в другую папку"])},
    "rename_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переименовать папку"])},
    "empty_folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя файла обязательно"])},
    "max_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальное количество символов не должно превышать 30"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Импортер"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметры"])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статистика"])},
    "column_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Столбцы таблицы"])},
    "options_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таблица опционов"])},
    "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опционы"])},
    "foldAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свернуть все"])},
    "unfoldAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Развернуть все"])},
    "show_sell_stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать проданные акции"])},
    "bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бутылки"])},
    "tickers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тикеры"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация"])},
    "th_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
    "th_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество"])},
    "th_net_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чистая цена"])},
    "th_gross_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валовая цена"])},
    "price_with_commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия"])},
    "th_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тарифы"])},
    "th_storage_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Место хранения"])},
    "th_holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Холдинг"])},
    "th_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вложения"])},
    "th_buy_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Где купить"])},
    "add_folder_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить файл"])},
    "add_bottle_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить бутылку"])},
    "name_parent_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя родительской папки"])},
    "additional_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительные колонки"])},
    "column_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказ колонны"])},
    "my_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои бутылки"])},
    "purchaseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата покупки"])},
    "addPriceOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить варианты цен"])},
    "sellDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата продажи"])},
    "swap_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата проведения биржи"])},
    "sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продажа"])},
    "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купить"])},
    "no_bottles_found_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не найдено ни одной бутылки, соответствующей вашему запросу."])},
    "select_parent_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите родительскую папку"])},
    "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя файла"])},
    "create_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать аккаунт"])},
    "modify_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сменить бутылку"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
    "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В процессе"])},
    "bottle_on_hold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бутылки в наличии"])},
    "bottle_on_hold_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бутылки в вашем распоряжении"])},
    "unique_references_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уникальные ссылки"])},
    "on_position_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество бутылок в собственности, умноженное на соответствующую цену покупки"])},
    "AUM_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество бутылок в собственности, умноженное на рыночную стоимость"])},
    "running_pnl_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus valeur en cours, разница между рыночной стоимостью и ценой покупки, умноженная на количество"])},
    "running_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущие показатели"])},
    "running_year_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущая годовая доходность"])},
    "average_price_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Средняя цена всех бутылок"])},
    "average_holding_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Средняя продолжительность нахождения в портфеле покупателя"])},
    "bought_bottles_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваши бутылки, купленные на данный момент"])},
    "sold_bottles_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваши бутылки, проданные на данный момент"])},
    "total_spent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая сумма расходов на сегодняшний день"])},
    "total_sold_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общее количество продаж на сегодняшний день"])},
    "total_pnl_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Накопленный доход с первого дня по сегодняшний день"])},
    "total_pnl_period_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реализованная прибыль за выбранный период"])},
    "realized_total_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доходность рассчитывается с первого дня (учитываются только проданные бутылки)"])},
    "realized_year_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эффективная годовая доходность (проданных бутылок)"])},
    "unique_references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уникальные ссылки"])},
    "on_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начальная стоимость позиции"])},
    "running_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущие показатели"])},
    "running_year_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Годовая доходность"])},
    "realized_year_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Историческая годовая доходность"])},
    "realized_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Историческая реализованная доходность"])},
    "average_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Средняя цена"])},
    "historical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История"])},
    "bought_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купленные бутылки"])},
    "unique_ticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уникальный тикер"])},
    "AUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценка"])},
    "running_pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Латентная прибыль"])},
    "sold_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проданные бутылки"])},
    "total_spent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общие расходы"])},
    "total_pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая реализованная прибыль"])},
    "total_pnl_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реализованная прибыль (период)"])},
    "realized_total_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Историческая реализованная доходность"])},
    "total_sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общий объем продаж"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата покупки/продажи"])},
    "net_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чистая цена"])},
    "price_without_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цены без учета."])},
    "price_with_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цены с комиссией"])},
    "price_with_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цены с НДС"])},
    "price_net": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чистая цена"])},
    "price_gross_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Брутто-цена покупки"])},
    "price_gross_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валовая цена продажи"])},
    "storage_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Место хранения"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество"])},
    "boughts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покупка"])},
    "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продажи"])},
    "details_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Справочная информация"])},
    "are_you_sure_delete_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены, что хотите удалить этот файл?"])},
    "xlsx_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл Excel"])},
    "file_optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Советы для вашего файла excel"])},
    "displayed_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отображаемые столбцы"])},
    "check_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать все"])},
    "stockage_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Используемое пространство"])},
    "add_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать аккаунт"])},
    "add_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить бутылку"])},
    "import_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Импорт файла"])},
    "current_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущая бутылка"])},
    "current_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущие бутылки"])},
    "current_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущая ссылка"])},
    "current_references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущие ссылки"])},
    "current_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущее досье"])},
    "move_to_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переместить в папку"])},
    "move_bottles_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бутылки в папке"])},
    "move_references_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ссылки в досье"])},
    "move_bottle_to_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переместить в папку"])},
    "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тарифы"])},
    "holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Держатели (день)"])},
    "files_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вложения"])},
    "buy_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Где купить"])},
    "sell_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Место продажи"])},
    "sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество проданного"])},
    "wab_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена WAB"])},
    "wasd_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Награды WASD"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория"])},
    "sub_asset_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Класс активов"])},
    "see_product_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти к описанию продукта"])},
    "last_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последняя цена"])},
    "volatility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Волатильность"])},
    "uncheck_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить выбор"])},
    "selected_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["выбранная бутылка (бутылки)"])},
    "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
    "delete_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить бутылку"])},
    "tel_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В настоящее время портфель доступен только на ПК, но скоро будет доступен и на мобильных устройствах."])},
    "volatility_portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Волатильность портфеля"])},
    "volatility_spirits_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Волатильность спиртных напитков"])},
    "volatility_distillery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Волатильность ликероводочных заводов"])},
    "volatility_vintage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Волатильность винтажа"])},
    "volatility_age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возраст волатильности"])},
    "portfolio_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Премия за портфель скоро появится"])},
    "see_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["См. подробнее"])},
    "go_to_product_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти на страницу продукта"])},
    "folder_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Созданный файл"])},
    "folder_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл был изменен"])},
    "folder_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл был удален"])},
    "folder_created_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка при создании файла"])},
    "bottle_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бутылка была добавлена"])},
    "bottle_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бутылка была модифицирована"])},
    "bottle_move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бутылка была перемещена"])},
    "file_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл успешно импортирован"])},
    "move_modal_title_origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оригинальный файл :"])},
    "move_modal_content_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вам предстоит переместить эту папку и все бутылки в ней."])},
    "move_folder_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш файл был перемещен"])},
    "folder_contain_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невозможно удалить, так как в этой папке находятся бутылки"])}
  },
  "bid": {
    "send_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить предложение"])}
  },
  "create_order": {
    "file_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешены только файлы .jpg, .jpeg и .png размером менее 3 Мб."])}
  },
  "payment_process": {
    "transaction_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Детали сделки"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сводка заказов"])},
    "shipping_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стоимость доставки"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налог"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купить"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продать"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отказаться"])},
    "sold_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продавец"])},
    "delivery_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стоимость доставки"])},
    "selling_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссионные за транзакции"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общее количество"])},
    "price_tva_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["С учетом НДС по цене"])},
    "shipping_ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стоимость доставки UPS"])},
    "payment_type_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Способ оплаты"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Способ оплаты"])},
    "inssurance_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страхование UPS"])},
    "ups_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИБП дома"])},
    "ups_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS point relais"])},
    "relay_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступны пункты вывода средств"])},
    "save_relay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохраните этот адрес для будущих покупок"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция не удалась. Пожалуйста, попробуйте еще раз"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сделка успешно завершена"])},
    "payment_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш платеж проходит проверку"])},
    "payment_please_wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, подождите несколько секунд"])}
  },
  "settings": {
    "title_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки аккаунта"])},
    "title_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление подпиской"])},
    "my_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои подписки"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Способ оплаты"])},
    "invoice_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История счетов"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мой счет"])},
    "buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покупка и продажа"])},
    "bank_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банковские реквизиты"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Безопасность и 2FA"])},
    "personnal_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Компания"])},
    "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civility"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Национальность"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
    "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата рождения"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефона"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
    "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почтовый индекс"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вилле"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регион"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страна"])},
    "other_delivery_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить другой адрес доставки"])},
    "delivery_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес доставки"])},
    "delivery_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить адрес доставки"])},
    "billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес для выставления счетов"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать"])},
    "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправлено в"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет информации"])},
    "fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Владелец счета"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправлено на"])},
    "update_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сменить РИБ"])},
    "2fa_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Двойная аутентификация"])},
    "2fa_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получайте код при каждом входе в систему и при каждом изменении в учетной записи"])},
    "2fa_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Двойная аутентификация по электронной почте"])},
    "2fa_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Двойная аутентификация по SMS"])},
    "login_history_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последние подключения"])},
    "login_history_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просматривайте историю последних 10 подключений к вашему аккаунту на всех устройствах."])},
    "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получать рассылку"])},
    "card_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дебетовая карта №."])},
    "card_created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавлена на"])},
    "card_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать"])},
    "card_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок действия истекает"])},
    "card_update_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поменяйте мою банковскую карту"])},
    "card_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы еще не зарегистрировали платежную карту."])},
    "delivery_choice_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Установите этот адрес по умолчанию"])},
    "delivery_is_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предпочтительный адрес доставки"])},
    "update_dialog": {
      "title_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение адреса электронной почты"])},
      "actual_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущий адрес электронной почты"])},
      "new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый адрес электронной почты"])},
      "title_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение номера телефона"])},
      "actual_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущий номер телефона"])},
      "new_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый телефонный номер"])},
      "title_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить адрес доставки"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрироваться"])},
      "err_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверная электронная почта"])},
      "err_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный номер телефона"])},
      "err_email_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас уже есть этот адрес электронной почты"])},
      "err_phone_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас уже есть этот номер телефона"])}
    },
    "subscriptions": {
      "last_month_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расходы за текущий месяц"])},
      "sub_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписчик с"])},
      "never_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы не являетесь подписчиком"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Годовые подписки"])},
      "next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Следующая дата сбора"])},
      "subscription_term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписка оформлена до"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отписаться от рассылки"])},
      "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изучите доступные подписки на исторические цены"])},
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отписаться от рассылки"])},
      "cancel_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок действия вашей подписки истекает"])},
      "cancel_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписка"])},
      "cancel_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить отмену"])},
      "reactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписка на резюме"])},
      "reactive_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжить подписку"])},
      "reactive_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Следующий платеж будет произведен на"])},
      "reactive_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписка"])},
      "reactive_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выплаты будут производиться каждый"])},
      "reactive_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["по цене"])},
      "reactive_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждение восстановления"])},
      "commitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обязательства до"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["месяц"])},
      "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ans"])},
      "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Премиальные исторические цены"])}
    },
    "invoices": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История счетов"])},
      "download_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать все"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цены"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
      "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписка"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать счет-фактуру"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платный"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас нет счета-фактуры"])},
      "delivery_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Детали поставки"])},
      "track_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отслеживать пакет"])},
      "delivery_estimation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предполагаемая доставка"])},
      "transaction_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° сделки"])}
    },
    "confirm": {
      "subtitle_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите код, полученный по электронной почте"])},
      "subtitle_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите код, полученный по телефону"])},
      "new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новая электронная почта"])},
      "new_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый телефон"])},
      "code_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите код, полученный по электронной почте"])},
      "code_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите код, полученный по телефону"])}
    },
    "billing_address_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет-фактура и адрес доставки"])},
    "preferences_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваши предпочтения успешно зарегистрированы"])},
    "birth_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Город рождения"])},
    "birth_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страна рождения"])},
    "preferences_mails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления"])},
    "preference_t_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание заявки на покупку"])},
    "preference_t_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание ордера на продажу"])},
    "preference_t_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание своп-ордера"])},
    "preference_t_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание ордера на блокчейн"])},
    "preference_t_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покупатель отклонил предложение"])},
    "preference_t_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ордер на продажу ожидает подтверждения"])},
    "preference_t_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авторизуйте получение SMS (проверка учетной записи и/или 2FA)"])},
    "preferences": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коммуникационные предпочтения"])}
    },
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
    "relay_ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Релейная точка ИБП"])},
    "buySellPreferences": {
      "buying_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Варианты покупки"])},
      "selling_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Варианты продаж"])},
      "buying_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наглядность покупки"])},
      "selling_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видимость продаж"])},
      "country_exclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страны для исключения"])},
      "country_inclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включенные страны"])},
      "preferences_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопировать мои предпочтения по покупке для продажи"])},
      "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваши изменения были учтены"])}
    }
  },
  "footer": {
    "social_medias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Социальные сети"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поддержка"])},
    "legals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Юридическая"])},
    "help_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Справочный центр"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "recruitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рекрутинг"])},
    "bug_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщить об ошибке"])},
    "service_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус сервисов"])},
    "legal_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Юридическая информация"])},
    "cgu_cgv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ПРАВИЛА И УСЛОВИЯ"])},
    "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление печеньем"])},
    "tep_footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Компания зарегистрирована во Франции"])}
  },
  "paiment_process": {
    "shipping_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стоимость доставки"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налоги"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купить"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отказаться"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сводка заказов"])},
    "sold_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продаваемая часть :"])},
    "delivery_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стоимость доставки"])},
    "selling_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расходы на продажу"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общее количество"])}
  },
  "transactions": {
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sens"])},
    "dispute_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжающиеся судебные разбирательства"])},
    "dispute_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Объявить спор"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сделки не проводятся."])}
  },
  "blog": {
    "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить фильтры"])},
    "published_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опубликовано на :"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменено на :"])},
    "articles_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтр статей"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не найдено ни одного товара."])}
  },
  "wallet": {
    "available_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступная сумма"])},
    "waiting_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В режиме ожидания"])},
    "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общее количество"])},
    "withdraw_money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вывод средств"])},
    "money_movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Движения"])},
    "payin_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произведенный платеж"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сделки не отображаются."])},
    "transfert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевести"])},
    "transfert_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Входящий перевод"])},
    "transfert_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исходящий перевод"])},
    "payout_seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата продавцу"])},
    "bank_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вывод средств"])},
    "bank_payout_in_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вывод средств на мой банковский счет"])},
    "payout_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вывод доступных средств"])},
    "payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить вывод средств"])},
    "success_payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевод на ваш банковский счет успешно завершен"])},
    "failed_payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевод средств на ваш счет не удался. Если проблема сохраняется, пожалуйста, обратитесь в службу поддержки клиентов"])},
    "order_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мой кошелек (0%)"])},
    "available_after_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма, оставшаяся после сделки:"])},
    "payin_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полученный платеж"])},
    "transactions_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ к моей истории торговли"])},
    "send_iban_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переводите деньги напрямую на свой кошелек, чтобы избежать комиссии за платежи"])},
    "wallet_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Владелец"])},
    "wallet_iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN :"])},
    "wallet_bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC :"])},
    "wallet_await_funds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваши средства будут доступны в течение 2-3 рабочих дней."])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Элемент успешно скопирован"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопировать"])},
    "wallet_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предоставление кошелька"])}
  },
  "filters": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расширенные фильтры"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтр"])},
    "filters_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для поиска без ключевых слов требуется 2 фильтра"])}
  }
}