export default {
  state: {
    selectedArticle: null
  },
  mutations: {
    setSelectedArticle(state, article) {
      state.selectedArticle = article;
    }
  },
  actions: {
    selectArticle({ commit }, article) {
      commit('setSelectedArticle', article);
    }
  },
  getters: {
    getSelectedArticle: state => state.selectedArticle
  }
};