
import { api } from "@/services/interceptors";

export default {
  state: {
    bottler: null,
  },
  getters: {
    getBottler(state) {
      return state.bottler;
    },
  },
  actions: {
    bottler({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api.get('api/bottler/' + payload).then((res) => {
          commit('SET_BOTTLER', res.data.bottler);
          resolve();
        }).catch(() => {
          reject();
        })
      })
    },
  },
  mutations: {
    SET_BOTTLER(currentState, bottler) {
      currentState.bottler = bottler;
    },
    STORE_NOTHING() {},
  },
};
