<template>
  <div class="account-verification-informations">
    <img
      v-if="!homePage"
      class="avi-background"
      src="@/assets/svg/v2/home/background.svg"
      alt="TEP background"
    />

    <h2 v-if="homePage">{{ $t('account_verification_details.title') }}</h2>

    <div class="account-verification-informations-card">
      <img
        v-if="homePage"
        class="avi-background"
        src="@/assets/svg/v2/home/background.svg"
        alt="TEP background"
      />

      <div class="avi-header">
        <h4 class="features">
          {{ $t('account_verification_details.features') }}
        </h4>
        <h4 class="unverified">
          {{ $t('account_verification_details.unverified_account') }}
        </h4>
        <h4 class="verified">
          {{ $t('account_verification_details.verified_account') }}
          <span>1</span>
          <img
            src="@/assets/svg/v2/accountVerificationInformations/kyc_account.svg"
            alt="KYC verified"
          />
        </h4>
      </div>

      <div class="avi-details-container">
        <div class="avi-trading">
          <div class="avi-trading-title">
            <img
              src="@/assets/svg/v2/accountVerificationInformations/trading.svg"
              :alt="$t('account_verification_details.newsfeed')"
            />
            <h5>{{ $t('account_verification_details.trading') }}</h5>
          </div>
          <ul class="avi-not-kyc">
            <li>
              {{ $t('account_verification_details.orders_visualization') }}
            </li>
            <li>{{ $t('account_verification_details.orders_not_access') }}</li>
          </ul>
          <ul class="avi-kyc">
            <li>{{ $t('account_verification_details.full_access') }}</li>
          </ul>
        </div>
        <div class="avi-price-history">
          <div class="avi-price-history-title">
            <img
              src="@/assets/svg/v2/accountVerificationInformations/price_history.svg"
              :alt="$t('account_verification_details.newsfeed')"
            />
            <h5>{{ $t('account_verification_details.price_history') }}</h5>
          </div>
          <ul class="avi-not-kyc">
            <li>
              {{ $t('account_verification_details.advanced_visualization') }}
            </li>
            <li>{{ $t('account_verification_details.month_15') }}</li>
          </ul>
          <ul class="avi-kyc">
            <li>
              {{ $t('account_verification_details.advanced_visualization') }}
            </li>
            <li>{{ $t('account_verification_details.month_12') }}</li>
          </ul>
        </div>
        <div class="avi-portfolio">
          <div class="avi-portfolio-title">
            <img
              src="@/assets/svg/v2/accountVerificationInformations/portfolio.svg"
              :alt="$t('account_verification_details.newsfeed')"
            />
            <h5>{{ $t('account_verification_details.portfolio') }}</h5>
          </div>
          <ul class="avi-not-kyc">
            <li>{{ $t('account_verification_details.portfolio_month_15') }}</li>
            <li>{{ $t('account_verification_details.tickers_limit_50') }}</li>
          </ul>
          <ul class="avi-kyc">
            <li>{{ $t('account_verification_details.portfolio_month_12') }}</li>
            <li>{{ $t('account_verification_details.tickers_limit_100') }}</li>
          </ul>
        </div>
        <div class="avi-database">
          <div class="avi-database-title">
            <img
              src="@/assets/svg/v2/accountVerificationInformations/database.svg"
              :alt="$t('account_verification_details.newsfeed')"
            />
            <h5>{{ $t('account_verification_details.database') }}</h5>
          </div>
          <ul class="avi-not-kyc">
            <li>
              {{ $t('account_verification_details.tickers_limit_daily') }}
            </li>
          </ul>
          <ul class="avi-kyc">
            <li>{{ $t('account_verification_details.full_access') }}</li>
          </ul>
        </div>
        <div class="avi-newsfeed">
          <div class="avi-newsfeed-title">
            <img
              src="@/assets/svg/v2/accountVerificationInformations/newsfeed.svg"
              :alt="$t('account_verification_details.newsfeed')"
            />
            <h5>{{ $t('account_verification_details.newsfeed') }}</h5>
          </div>
          <ul class="avi-not-kyc">
            <li>{{ $t('account_verification_details.full_access') }}</li>
          </ul>
          <ul class="avi-kyc">
            <li>{{ $t('account_verification_details.full_access') }}</li>
          </ul>
        </div>
      </div>

      <div class="avi-kyc-infos">
        <span>1</span>
        <img src="@/assets/svg/v2/registerProcess/document.svg" alt="KYC" />
        <p>{{ $t('account_verification_details.kyc_infos') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    homePage: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {}
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root.scss';

.avi-newsfeed,
.avi-price-history,
.avi-portfolio,
.avi-database,
.avi-trading {
  display: flex;
  border-bottom: 1px solid $subtitle;
  padding-bottom: 10px;
  padding-top: 10px;

  .avi-newsfeed-title,
  .avi-price-history-title,
  .avi-portfolio-title,
  .avi-database-title,
  .avi-trading-title {
    width: 250px;
  }

  div {
    display: flex;
    align-items: center;

    h5 {
      color: white;
      font-weight: 400;
      font-size: 15px;
      margin-left: 8px;
    }
  }

  ul {
    li {
      font-weight: 400;
      font-size: 15px;
      list-style: initial;
    }
  }
}

.avi-newsfeed {
  border-bottom: 0;
}

.avi-background {
  position: absolute;
  top: -190px;
  right: -320px;
  width: 850px;
}

.account-verification-informations {
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;

  h2 {
    color: white;
    font-family: Hyperwave;
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 32px;
    text-align: center;
    margin-bottom: 40px;
  }

  .account-verification-informations-card {
    position: relative;
    overflow: hidden;
    padding: 24px 34px;
    border-radius: 12px;
    border: 1px solid $border;
    background: #333;
    max-width: 807px;
    margin: auto;

    .avi-header {
      display: flex;
      color: white;
      background: #444444;
      border: 1px solid $border;
      border-radius: 12px;
      padding: 15px;
      margin-bottom: 15px;

      h4 {
        font-weight: 600;
      }

      .features {
        width: 217px;
        margin-left: 15px;
      }

      .unverified {
        width: 300px;
      }

      .verified {
        display: flex;
        align-items: flex-start;

        span {
          font-size: 12px;
        }

        img {
          margin-left: 3px;
        }
      }
    }

    .avi-kyc-infos {
      display: flex;
      align-items: flex-start;
      color: $subtitle;
      margin-top: 20px;
      font-size: 13px;

      img {
        margin-right: 8px;
      }
    }
  }
}

.avi-not-kyc {
  color: $subtitle;
  width: 300px;
}

.avi-kyc {
  color: white;
}

@media screen and (max-width: 500px) {
  .account-verification-informations {

    h2 {
      font-size: 30px;
      margin: auto 10px;
      margin-bottom: 20px;
    }
  }

  .account-verification-informations-card {
    overflow-x: scroll !important;
    width: 300px;
    padding: 24px !important;
  }

  .avi-header,
  .avi-details-container,
  .avi-kyc-infos {
    width: 780px;
  }

  .avi-header {
    padding: 15px 0px !important;

    .features {
      margin-left: 30px !important;
    }
  }

  .avi-background {
    display: none;
  }

  .account-verification-informations-card::-webkit-scrollbar {
    height: 10px;
  }

  .account-verification-informations-card::-webkit-scrollbar-track {
    background: $background;
  }

  .account-verification-informations-card::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 10px;
  }

  .account-verification-informations-card::-webkit-scrollbar-thumb:hover {
    background-color: $grey40;
  }
}
</style>
