import { api } from '@/services/interceptors';

export default {
  state: {
    payment_in_progress: false,
    payment_status: null
  },
  getters: {},
  actions: {
    subscriptions({ commit }, payload) {
      commit('STORE_NOTHING', payload);
      api.post('api/payments/subscriptions', payload)
        .catch((res) => {
          if (res.response.data.redirect) {
            window.location.replace(res.response.data.redirect);
          }
        });
    },
    checkStatus({ commit }, payload) {
      commit('STORE_NOTHING', payload);
      api.get('api/payments/check/' + payload)
        .then((res) => {
          commit('SET_PAYMENT_IN_PROGRESS', true);
          if (res.data.check) {
            commit('SET_PAYMENT_STATUS', res.data.check);
          }
        });
    },
  },
  mutations: {
    SET_PAYMENT_IN_PROGRESS(currentState, state) {
      currentState.payment_in_progress = state;
    },
    SET_PAYMENT_STATUS(currentState, state) {
      currentState.payment_status = state;
    },
    STORE_NOTHING() { }
  }
};