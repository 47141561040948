import { api } from '@/services/interceptors';

export default {
  state: {},
  getters: {},
  actions: {
    sendResponseAction({ commit }, payload) {
      commit('STORE_NOTHING');
      api.post('api/auth/orders/trade-confirmation/' + payload.action, payload);
    },
  },
  mutations: {
    STORE_NOTHING() { }
  }
};