<template>
  <div class="nav-top">
    <div class="bloc-logo-navigation">
      <div @click.stop="$router.push('/')" class="logo">
        <img
          width="130"
          src="@/assets/img/tep.webp"
          alt="The exchange platform - Logo"
        />
      </div>

      <div class="navigation-auth">
        <ul>

          <li
            @click.stop="redirect('/search-products')"
            :style="routerName('searchProducts') ? 'color: white;' : null"
          >
            <img
              v-if="!routerName('searchProducts')"
              src="@/assets/svg/v2/trading.svg"
              :alt="$t('commons.trading') + 'icon menu navigation'"
            />
            <img
              v-if="routerName('searchProducts')"
              src="@/assets/svg/v2/tradingActive.svg"
              :alt="$t('commons.trading') + 'icon menu navigation'"
            />
            <p class="hide-min-tel">{{ $t('commons.trading') }}</p>
            <div
              :class="{ 'active-trading': routerName('searchProducts') }"
            ></div>
          </li>

          <li
            @click.stop="redirect('/auth/portfolio-management')"
            :style="routerName('portfolio') ? 'color: white;' : null"
          >
            <img
              v-if="!routerName('portfolio')"
              src="@/assets/svg/v2/portfolio.svg"
              :alt="$t('commons.portfolio') + 'icon menu navigation'"
            />
            <img
              v-if="routerName('portfolio')"
              src="@/assets/svg/v2/portfolioActive.svg"
              :alt="$t('commons.portfolio') + 'icon menu navigation'"
            />
            <p class="hide-min-tel">{{ $t('commons.portfolio') }}</p>
            <div :class="{ 'active-portfolio': routerName('portfolio') }"></div>
          </li>
        
          <li
            v-if="!isNoAuth"
            @click.stop="redirect('/auth/matchs')"
            :style="routerName('matchs') ? 'color: white;' : null"
          >
            <img
              v-if="!isNoAuth && !routerName('matchs')"
              src="@/assets/svg/v2/matchs/matchs.svg"
              :alt="$t('commons.matchs') + 'icon menu navigation'"
            />
            <img
              v-if="!isNoAuth && routerName('matchs')"
              src="@/assets/svg/v2/matchs/matchsActive.svg"
              :alt="$t('commons.matchs') + 'icon menu navigation'"
            />
            <p class="hide-min-tel">{{ $t('commons.matchs') }}</p>
            <div class="nav-alert-length">
              <p>{{ alerts.length }}</p>
            </div>
            <div
              v-if="alerts.length === 0"
              :class="{ 'active-matchs': routerName('matchs') }"
            ></div>
            <div
              v-else
              :class="{ 'active-matchs-alerts': routerName('matchs') }"
            ></div>
          </li>

          <li
            @click.stop="redirect('/newsfeed')"
            :style="routerName('newsfeed') ? 'color: white;' : null"
          >
            <img
              v-if="!routerName('newsfeed')"
              src="@/assets/svg/v2/fullCoverage.svg"
              :alt="$t('commons.newsfeed') + 'icon menu navigation'"
            />
            <img
              v-if="routerName('newsfeed')"
              src="@/assets/svg/v2/fullCoverageActive.svg"
              :alt="$t('commons.newsfeed') + 'icon menu navigation'"
            />
            <p class="hide-min-tel">{{ $t('commons.newsfeed') }}</p>
            <div :class="{ 'active-newsfeed': routerName('newsfeed') }"></div>
          </li>

          <li
            @click.stop="redirect('/blog')"
            :style="routerName('blog') ? 'color: white;' : null"
          >
            <img
              v-if="!routerName('blog')"
              src="@/assets/svg/v2/articles.svg"
              :alt="$t('commons.blog') + 'icon menu navigation'"
            />
            <img
              v-if="routerName('blog')"
              src="@/assets/svg/v2/articles-actif.svg"
              :alt="$t('commons.blog') + 'icon menu navigation'"
            />
            <p class="hide-min-tel">{{ $t('commons.blog') }}</p>
            <div :class="{ 'active-newsfeed': routerName('blog') }"></div>
          </li>

        </ul>
      </div>
    </div>

    <div class="bloc-actions">
      <a
        v-if="positionState != 'Login' && isNoAuth"
        @click="resetStateForgotPassword(), redirect('login')"
        class="hide-tel"
        >{{ $t('commons.login') }}</a
      >
      <a
        v-if="positionState != 'Register' && isNoAuth"
        @click="redirect('register')"
        class="hide-tel"
        >{{ $t('commons.registration') }}</a
      >

      <!-- <div class="alerts" v-if="!isNoAuth" @click.stop="openMenuAlerts()">
        <img src="@/assets/svg/v2/alert.svg" alt="Notifications">

        <div class="alerts-active" v-if="isOpenMenuAlerts">
          <div class="alerts-active-title">
            <p>{{ $t('commons.alerts') }}</p>
            <button>{{ $t('commons.alerts_clear') }}</button>
          </div>

          <div v-if="alerts.length > 0" class="alerts-active-body">
            <div v-for="(alert, n) in alerts" :key="n" class="alert">
              <div class="alert-image">
                <img v-if="alert.alert_type === 1" src="@/assets/svg/v2/matchs/matchsActive.svg" alt="Match (Buy)" class="match-buy" />
              </div>
              <div class="alert-content">
                <div>
                  <h4 v-if="alert.alert_type === 1">{{ $t('matchs.match_buy') }}</h4>
                  <p>{{ returnAlertDate(alert.created_at) }}</p>
                </div>
                <p v-if="alert.alert_type === 1" class="alert-match-title">{{ alert.title }}</p>
              </div>
            </div>
          </div>

          <div v-else class="alerts-active-empty">
            <img class="background" src="@/assets/svg/v2/home/background.svg" alt="TEP background" />
            <img src="@/assets/svg/v2/alertsEmpty.svg" alt="Empty alerts" width="70" />
            <p>{{ $t('commons.alerts_empty') }}</p>
          </div>
        </div>
      </div> -->

      <div class="lang-choice" @click.stop="openMenuLang()">
        <img class="lang-img" src="@/assets/svg/v2/lang.svg" alt="Lang icon menu navigation" />
        <span>{{ returnLanguageChoice.toUpperCase() }}</span>

        <div class="lang-choice-active" v-if="isOpenMenuLang">
          <p>{{ $t('commons.lang.lang_choice') }}</p>

          <button
            @click="changeLang('de')"
            :class="{ activeLang: returnLanguageChoice === 'de' }"
          >
            <div class="flag-lang">
              <img
                width="30"
                height="20"
                src="@/assets/svg/v2/flags/de.svg"
                alt="flag of Germany lang choice"
              />
              {{ $t('commons.lang.de_label') }}
            </div>
            <img
              src="@/assets/svg/v2/checked.svg"
              alt="Checked"
              v-if="returnLanguageChoice === 'de'"
            />
          </button>

          <button
            @click="changeLang('en-GB')"
            :class="{ activeLang: returnLanguageChoice === 'en-GB' }"
          >
            <div class="flag-lang">
              <img
                width="30"
                height="20"
                src="@/assets/svg/v2/flags/gb.svg"
                alt="flag of United Kingdom lang choice"
              />
              {{ $t('commons.lang.en_label') }}
            </div>
            <img
              src="@/assets/svg/v2/checked.svg"
              alt="Checked"
              v-if="returnLanguageChoice === 'en'"
            />
          </button>

          <button
            @click="changeLang('zh')"
            :class="{ activeLang: returnLanguageChoice === 'zh' }"
          >
            <div class="flag-lang">
              <img
                width="30"
                height="20"
                src="@/assets/svg/v2/flags/cn.svg"
                alt="flag of China lang choice"
              />
              {{ $t('commons.lang.zh_label') }}
            </div>
            <img
              src="@/assets/svg/v2/checked.svg"
              alt="Checked"
              v-if="returnLanguageChoice === 'zh'"
            />
          </button>

          <button
            @click="changeLang('ko')"
            :class="{ activeLang: returnLanguageChoice === 'ko' }"
          >
            <div class="flag-lang">
              <img
                width="30"
                height="20"
                src="@/assets/svg/v2/flags/kr.svg"
                alt="flag of South Korea lang choice"
              />
              {{ $t('commons.lang.ko_label') }}
            </div>
            <img
              src="@/assets/svg/v2/checked.svg"
              alt="Checked"
              v-if="returnLanguageChoice === 'ko'"
            />
          </button>

          <button
            @click="changeLang('da')"
            :class="{ activeLang: returnLanguageChoice === 'da' }"
          >
            <div class="flag-lang">
              <img
                width="30"
                height="20"
                src="@/assets/svg/v2/flags/dk.svg"
                alt="flag of Denmark lang choice"
              />
              {{ $t('commons.lang.da_label') }}
            </div>
            <img
              src="@/assets/svg/v2/checked.svg"
              alt="Checked"
              v-if="returnLanguageChoice === 'da'"
            />
          </button>

          <button
            @click="changeLang('es')"
            :class="{ activeLang: returnLanguageChoice === 'es' }"
          >
            <div class="flag-lang">
              <img
                width="30"
                height="20"
                src="@/assets/svg/v2/flags/es.svg"
                alt="flag of Spain lang choice"
              />
              {{ $t('commons.lang.es_label') }}
            </div>
            <img
              src="@/assets/svg/v2/checked.svg"
              alt="Checked"
              v-if="returnLanguageChoice === 'es'"
            />
          </button>

          <button
            @click="changeLang('fr')"
            :class="{ activeLang: returnLanguageChoice === 'fr' }"
          >
            <div class="flag-lang">
              <img
                width="30"
                height="20"
                src="@/assets/svg/v2/flags/fr.svg"
                alt="flag of France lang choice"
              />
              {{ $t('commons.lang.fr_label') }}
            </div>
            <img
              src="@/assets/svg/v2/checked.svg"
              alt="Checked"
              v-if="returnLanguageChoice === 'fr'"
            />
          </button>

          <button
            @click="changeLang('el')"
            :class="{ activeLang: returnLanguageChoice === 'el' }"
          >
            <div class="flag-lang">
              <img
                width="30"
                height="20"
                src="@/assets/svg/v2/flags/gr.svg"
                alt="flag of Greece lang choice"
              />
              {{ $t('commons.lang.el_label') }}
            </div>
            <img
              src="@/assets/svg/v2/checked.svg"
              alt="Checked"
              v-if="returnLanguageChoice === 'el'"
            />
          </button>

          <button
            @click="changeLang('hu')"
            :class="{ activeLang: returnLanguageChoice === 'hu' }"
          >
            <div class="flag-lang">
              <img
                width="30"
                height="20"
                src="@/assets/svg/v2/flags/hu.svg"
                alt="flag of Hungary lang choice"
              />
              {{ $t('commons.lang.hu_label') }}
            </div>
            <img
              src="@/assets/svg/v2/checked.svg"
              alt="Checked"
              v-if="returnLanguageChoice === 'hu'"
            />
          </button>

          <button
            @click="changeLang('it')"
            :class="{ activeLang: returnLanguageChoice === 'it' }"
          >
            <div class="flag-lang">
              <img
                width="30"
                height="20"
                src="@/assets/svg/v2/flags/it.svg"
                alt="flag of Italy lang choice"
              />
              {{ $t('commons.lang.it_label') }}
            </div>
            <img
              src="@/assets/svg/v2/checked.svg"
              alt="Checked"
              v-if="returnLanguageChoice === 'it'"
            />
          </button>

          <button
            @click="changeLang('ja')"
            :class="{ activeLang: returnLanguageChoice === 'ja' }"
          >
            <div class="flag-lang">
              <img
                width="30"
                height="20"
                src="@/assets/svg/v2/flags/jp.svg"
                alt="flag of Japan lang choice"
              />
              {{ $t('commons.lang.ja_label') }}
            </div>
            <img
              src="@/assets/svg/v2/checked.svg"
              alt="Checked"
              v-if="returnLanguageChoice === 'ja'"
            />
          </button>

          <button
            @click="changeLang('pl')"
            :class="{ activeLang: returnLanguageChoice === 'pl' }"
          >
            <div class="flag-lang">
              <img
                width="30"
                height="20"
                src="@/assets/svg/v2/flags/pl.svg"
                alt="flag of Poland lang choice"
              />
              {{ $t('commons.lang.pl_label') }}
            </div>
            <img
              src="@/assets/svg/v2/checked.svg"
              alt="Checked"
              v-if="returnLanguageChoice === 'pl'"
            />
          </button>

          <button
            @click="changeLang('pt-PT')"
            :class="{ activeLang: returnLanguageChoice === 'pt-PT' }"
          >
            <div class="flag-lang">
              <img
                width="30"
                height="20"
                src="@/assets/svg/v2/flags/pt.svg"
                alt="flag of Portugal lang choice"
              />
              {{ $t('commons.lang.pt_label') }}
            </div>
            <img
              src="@/assets/svg/v2/checked.svg"
              alt="Checked"
              v-if="returnLanguageChoice === 'pt'"
            />
          </button>

          <button
            @click="changeLang('ru')"
            :class="{ activeLang: returnLanguageChoice === 'ru' }"
          >
            <div class="flag-lang">
              <img
                width="30"
                height="20"
                src="@/assets/svg/v2/flags/ru.svg"
                alt="flag of Russia lang choice"
              />
              {{ $t('commons.lang.ru_label') }}
            </div>
            <img
              src="@/assets/svg/v2/checked.svg"
              alt="Checked"
              v-if="returnLanguageChoice === 'ru'"
            />
          </button>

          <button
            @click="changeLang('sk')"
            :class="{ activeLang: returnLanguageChoice === 'sk' }"
          >
            <div class="flag-lang">
              <img
                width="30"
                height="20"
                src="@/assets/svg/v2/flags/sk.svg"
                alt="flag of Slovakia lang choice"
              />
              {{ $t('commons.lang.sk_label') }}
            </div>
            <img
              src="@/assets/svg/v2/checked.svg"
              alt="Checked"
              v-if="returnLanguageChoice === 'sk'"
            />
          </button>

          <button
            @click="changeLang('cs')"
            :class="{ activeLang: returnLanguageChoice === 'cs' }"
          >
            <div class="flag-lang">
              <img
                width="30"
                height="20"
                src="@/assets/svg/v2/flags/cs.svg"
                alt="flag of the czech republic lang choice"
              />
              {{ $t('commons.lang.cs_label') }}
            </div>
            <img
              src="@/assets/svg/v2/checked.svg"
              alt="Checked"
              v-if="returnLanguageChoice === 'cs'"
            />
          </button>
        </div>
      </div>

      <div class="user-menu" @click.stop="openMenu()">
        <img
          v-if="!isOpenMenu"
          width="20"
          height="20"
          src="@/assets/svg/v2/menu.svg"
          alt="Menu"
        />
        <img
          v-else
          width="20"
          height="20"
          src="@/assets/svg/v2/menuActive.svg"
          alt="Menu is active"
        />
        <img
          v-if="!isOpenMenu"
          class="hide-min-tel"
          width="32"
          height="32"
          src="@/assets/svg/v2/account.svg"
          alt="Account"
        />
        <img
          v-else
          width="32"
          class="hide-min-tel"
          height="32"
          src="@/assets/svg/v2/accountActive.svg"
          alt="Account"
        />

        <div class="menu-user-logout" v-if="menuOpenDisconnectUser">
          <ul>
            <li @click.stop="redirect('/register')">
              {{ $t('commons.registration') }}
            </li>
            <li @click.stop="redirect('/login')">{{ $t('commons.login') }}</li>
            <el-divider class="menu-disconnect-divider" />
            <li @click.stop="redirect('/subscriptions')">
              {{ $t('commons.subscriptions') }}
            </li>
            <el-divider class="menu-disconnect-divider" />
            <li class="li-dark-mode">
              <div>
                <img src="@/assets/svg/v2/darkMode.svg" alt="Dark mode" />
                {{ $t('commons.dark_mode') }}
              </div>
              <div class="switch-dark-mode">
                <el-switch
                  :model-value="darkmode"
                  disabled
                  style="
                    --el-switch-on-color: #2667ff;
                    --el-switch-off-color: #333333;
                  "
                />
              </div>
            </li>
          </ul>
        </div>

        <div class="menu-user-login" v-if="menuOpenConnectedUser">
          <ul>
            <li
              @click.stop="redirect('/auth/account/details')"
              class="menu-user-identity"
            >
              <div>
                <img
                  src="@/assets/svg/v2/accountFirstLetters.svg"
                  alt="Complete names"
                />
                <p class="user-first-letters">
                  <img
                    v-if="client?.identity.account_type === 0"
                    src="@/assets/svg/v2/personnal.svg"
                    alt="Personnal"
                  />
                  <img v-else src="@/assets/svg/v2/pro.svg" alt="Pro" />
                </p>
              </div>
              <div class="menu-user-identity-infos">
                <p class="names">
                  <span v-if="client?.identity.account_type === 0"
                    >{{ client?.identity.firstname }}
                    {{ client?.identity.lastname.toUpperCase() }}</span
                  >
                  <span v-else>{{
                    client?.address.organization.name.toUpperCase()
                  }}</span>
                  <img
                    v-if="isAccountTypeSelected === 4"
                    class="success-img"
                    src="@/assets/svg/v2/registerProcess/success.svg"
                    alt="Success registration"
                    width="28"
                    height="28"
                  />
                </p>
                <p v-if="client?.identity.account_type === 0">
                  {{ $t('register.step_0.individual') }}
                </p>
                <p v-else>{{ $t('register.step_0.professionnal') }}</p>
              </div>
            </li>
            <li
              @click.stop="redirect('/register')"
              v-if="isAccountTypeSelected < 4"
              class="registration-progress"
            >
              <div>
                <div class="registration-progress-explain">
                  {{ $t('register.end_register') }}
                  <img
                    src="@/assets/svg/v2/arrowToRight.svg"
                    alt="Right arrow"
                  />
                </div>
                <div>
                  {{ $t('register.verify_account') }}
                </div>
              </div>
              <div>
                <img
                  v-if="isAccountTypeSelected === 1"
                  src="@/assets/svg/v2/accountStep/2.svg"
                  alt="Confirm email & phone"
                />
                <img
                  v-if="isAccountTypeSelected === 2"
                  src="@/assets/svg/v2/accountStep/3.svg"
                  alt="KYC"
                />
                <img
                  v-if="isAccountTypeSelected === 3"
                  src="@/assets/svg/v2/accountStep/4.svg"
                  alt="Banking informations"
                />
              </div>
            </li>

            <el-divider class="menu-connect-divider" />

            <li class="li-dark-mode">
              <div>
                <img src="@/assets/svg/v2/darkMode.svg" alt="Dark mode" />
                {{ $t('commons.dark_mode') }}
              </div>
              <div class="switch-dark-mode">
                <el-switch
                  :model-value="darkmode"
                  disabled
                  style="
                    --el-switch-on-color: #2667ff;
                    --el-switch-off-color: #333333;
                  "
                />
              </div>
            </li>

            <el-divider class="menu-connect-divider" />

            <li @click.stop="redirect('/auth/transactions/history')">
              {{ $t('commons.dashboard') }}
            </li>

            <li @click.stop="redirect('/auth/order-list')">
              {{ $t('commons.live_orders') }}
            </li>

            <li @click.stop="redirect('/auth/account/wallet')">
              {{ $t('commons.my_wallet') }}
            </li>

            <li @click.stop="redirect('/auth/account/details')">
              {{ $t('commons.account_settings') }}
            </li>

            <el-divider class="menu-connect-divider" />

            <li
              @click.stop="redirect('/subscriptions')"
              class="menu-connect-subscriptions"
            >
              {{ $t('commons.subscriptions') }}
            </li>

            <el-divider class="menu-connect-divider" />

            <li @click="logout()" class="logout">{{ $t('commons.logout') }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import m from 'moment'

export default {
  props: {
    menuOpenConnectedUser: {
      type: Boolean,
      required: true,
    },
    menuOpenDisconnectUser: {
      type: Boolean,
      required: true,
    },
    menuLangChoice: {
      type: Boolean,
      required: true,
    },
    menuAlerts: {
      type: Boolean,
      required: false,
      default: true,
    },
    darkmode: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      drawerNavbar: false,
    }
  },
  watch: {
    menuOpenConnectedUser() {
      if (this.menuOpenConnectedUser) {
        this.getClient()
      }
    },
  },
  computed: {
    ...mapGetters({
      client: 'getUserDetails',
      alerts: 'getAlerts',
      getAuthStatus: 'getIsAuth',
    }),
    isNoAuth() {
      return !this.$store.state.auth.auth && this.$store.state.auth.two_fa === 0
    },
    positionState() {
      return this.$route.name
    },
    isOpenMenu() {
      return this.menuOpenConnectedUser || this.menuOpenDisconnectUser
    },
    isOpenMenuLang() {
      return this.menuLangChoice
    },
    isOpenMenuAlerts() {
      return this.menuAlerts
    },
    returnLanguageChoice() {
      return this.$i18n.locale
    },
    isAccountTypeSelected() {
      return this.$store.state.auth.register_step - 1
    },
  },
  methods: {
    ...mapActions({
      getClient: 'getAccountDetails',
      logoutAPI: 'logout',
      setLang: 'setLang',
    }),
    resetStateForgotPassword() {
      this.$store.commit('SET_FORGOT_PASSWORD', 0)
    },
    openMenu() {
      if (this.isNoAuth) {
        this.$emit('menuState')
      } else {
        this.$emit('menuState')
      }
      this.$emit('menuStateLang', 'x')
    },
    openMenuLang() {
      this.$emit('menuStateLang')
      this.$emit('menuStateAlerts', 'x')
      this.$emit('menuState', 'x')
    },
    openMenuAlerts() {
      this.$emit('menuStateAlerts')
    },
    redirect(path) {
      this.$router.push(path)
      this.$emit('menuState', 'x')
      this.$emit('menuStateLang', 'x')
      this.$emit('menuStateAlerts', 'x')
    },
    async logout() {
      await this.logoutAPI().then(() => {
        localStorage.removeItem('token')
        this.$store.commit('RESET_SEARCH', {
          key: 'searchProduct',
          products: [],
        })
        this.$store.commit('SET_AUTH', false)
        this.$store.commit('EMAIL_IS_CONFIRM', false)
        this.$store.commit('CHANGE_REGISTER_STEP', 1)
        this.$store.commit('CHANGE_2FA_STEP', 0)
        this.redirect('/');
      })
    },
    routerName(name) {
      return this.$route.name === name
    },
    changeLang(lang) {
      localStorage.setItem('lang', lang)
      this.$i18n.locale = lang

      let doubleLang = null

      if (lang === 'en-GB') {
        doubleLang = 'en'
      } else if (lang === 'pt-PT') {
        doubleLang = 'pt'
      } else {
        doubleLang = lang
      }

      const payload = {
        code: doubleLang,
      }
      if (this.getAuthStatus) {
        this.setLang(payload)
      }
    },
    returnAlertDate(date) {
      return m(date).format('DD/MM/YYYY HH:mm')
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root.scss';

.menu-user-identity {
  .img-text {
    display: flex;

    div {
      margin-right: 10px;
    }
  }
}

.nav-responsive-bottom {
  .menu-user-identity {
    padding-bottom: 0px;
  }
}

.nav-responsive-bottom .logout {
  color: #f43f5e;
}

.nav-responsive-bottom .registration-progress {
  background-color: $blue;
  border-radius: 8px;
  margin: 0 0px;
  padding-left: 14px;
  padding-right: 14px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.el-drawer__body {
  background-color: #333 !important;
}

.nav-responsive-bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}

.cart-drawer-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.nav-all-links {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.nav-responsive-bottom .user-first-letters {
  margin-top: -38px;
  margin-left: 8px;
}

.nav-responsive-bottom ul {
  li {
    color: #fff;
    font-size: 16px;
    padding: 10px 0px 10px 0px;
    margin-bottom: 6px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button {
      border-radius: 6px;
      border: 1px solid $blue;
      background: $blue;
      color: white;
      padding: 6px;
    }
  }

  .li-dark-mode {
    img {
      margin-right: 8px;
    }

    .switch-dark-mode {
      margin-left: 51px;
    }
  }
}

.li-responsive {
  display: flex !important;
}

.nav-responsive ul {
  display: flex;
  flex-direction: column;
  gap: 30px;
  color: white;
  font-size: 18px;
  margin-top: 10px;

  li {
    img {
      margin-right: 20px;
    }
  }
}

.cart-drawer-content {
  .cart-drawer-content-title {
    display: flex;
    justify-content: space-between;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border: 0;
      border-radius: 76px;
      border: 1px solid $border;
      background: #444;
      height: 38px;
      width: 38px;
    }
  }
}

.nav-top,
.bloc-actions,
.user-menu,
.lang-choice,
.bloc-logo-navigation,
.navigation-auth ul,
.navigation-auth ul li,
.li-dark-mode,
.li-dark-mode div,
.menu-user-identity,
.registration-progress-explain,
.registration-progress,
.flag-lang,
.menu-connect-subscriptions {
  display: flex;
}

.nav-top,
.user-menu,
.bloc-actions,
.navigation-auth ul li,
.li-dark-mode,
.li-dark-mode div,
.registration-progress-explain,
.registration-progress,
.flag-lang,
.menu-connect-subscriptions {
  align-items: center;
}

.flag-lang {
  gap: 3px;
}

.bloc-actions a,
.lang-choice {
  font-size: 15px;
}

.nav-top {
  height: 76px;
  background: #222222;
  color: #ffffff99;
  justify-content: space-between;
  padding-left: 66px;
  padding-right: 66px;
  border-bottom: 1px solid $border;

  .bloc-logo-navigation {
    display: flex;
    align-items: center;

    .logo {
      cursor: pointer;
      min-width: 30px;
      margin-right: 14px;
    }

    .navigation-auth {
      ul {
        margin-left: 30px;

        li {
          position: relative;
          margin-right: 15px;
          cursor: pointer;

          img {
            margin-right: 8px;
          }

          .nav-alert-length {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 4px;
            border-radius: 50%;
            background: $blue;
            color: white;
            width: 20px;
            height: 20px;

            p {
              font-size: 15px;
            }
          }
        }
      }
    }
  }

  .bloc-actions {
    a {
      color: #ffffff99;
      margin-right: 15px;
    }

    .lang-choice {
      position: relative;
      margin-left: 10px;
      cursor: pointer;

      img {
        margin-right: 5px;
      }

      .lang-choice-active {
        position: absolute;
        top: 43px;
        right: 0px;
        width: 180px;
        max-height: 300px;
        overflow-y: scroll;
        padding: 20px 15px;
        border-radius: 12px;
        border: 1px solid $border;
        background: #333;
        box-shadow: 0px 4px 12px -2px rgba(0, 0, 0, 0.16);
        cursor: default;

        p {
          color: white;
          margin-bottom: 8px;
        }

        button {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 0 8px;
          border-radius: 8px;
          height: 38px;
          background: transparent;
          border: 0;
          color: $subtitle;
        }
      }
    }

    .user-menu {
      position: relative;
      background-color: $blue;
      border-radius: 21px;
      width: 72px;
      height: 40px;
      justify-content: space-evenly;
      margin-left: 21px;
      cursor: pointer;

      .menu-user-logout,
      .menu-user-login {
        cursor: default;
        position: absolute;
        top: 55px;
        right: 0px;
        border-radius: 12px;
        border: 1px solid $border;
        background: #333;
        box-shadow: 0px 4px 12px -2px rgba(0, 0, 0, 0.16);
        width: 269px;
        height: 212px;

        ul {
          padding-top: 15px;
          padding-bottom: 28px;

          li {
            color: #fff;
            font-size: 16px;
            padding: 8px 16px 8px 16px;
            margin-bottom: 6px;
            cursor: pointer;
          }

          .li-dark-mode {
            img {
              margin-right: 8px;
            }

            .switch-dark-mode {
              margin-left: 51px;
            }
          }
        }
      }

      .menu-user-login {
        width: 332px;
        height: max-content;

        ul {
          padding-bottom: 0;

          li {
            color: white;
          }

          .menu-user-identity {
            .menu-user-identity-infos {
              margin-left: 12px;

              .names {
                display: flex;
                justify-content: left;
                align-items: center;
              }

              p:nth-child(2) {
                color: rgba(255, 255, 255, 0.6);
              }
            }
          }

          .registration-progress {
            background-color: $blue;
            border-radius: 8px;
            margin: 0 17px;
            padding: 8px 0;
            padding-left: 14px;

            div {
              .registration-progress-explain {
                margin-bottom: 7px;
                margin-top: 2px;
                margin-right: 10px;
              }

              div:nth-child(2) {
                font-size: 14px;
                color: rgba(255, 255, 255, 0.6);
              }
            }
          }

          .menu-connect-subscriptions {
            justify-content: space-between;
          }

          .li-dark-mode {
            justify-content: space-between;
          }

          .logout {
            color: #f43f5e;
            font-weight: 500;
          }
        }
      }
    }

    .alerts {
      position: relative;
      margin-top: 5px;
      cursor: pointer;

      .alerts-active {
        overflow: hidden;
        cursor: default;
        position: absolute;
        right: 0;
        top: 40px;
        border-radius: 12px;
        border: 1px solid $border;
        background: #333;
        box-shadow: 0px 4px 12px -2px rgba(0, 0, 0, 0.16);
        width: 393px;
        height: 358px;
        padding: 22px 0;

        .alerts-active-title {
          display: flex;
          justify-content: space-between;
          padding: 0 16px;

          button {
            background: transparent;
            border: 0;
            color: $grey40;
            z-index: 1;
          }
        }

        .alerts-active-body {
          margin-top: 30px;

          .alert {
            display: flex;
            align-items: center;
            border-radius: 12px;
            cursor: pointer;
            padding: 5px 8px;
            margin: 0 8px;

            .alert-image {
              display: flex;
              justify-content: center;
              align-items: center;
              background: $blue;
              width: 42px;
              height: 39px;
              border-radius: 50%;
              margin-right: 11px;
            }

            .alert-content {
              display: flex;
              flex-direction: column;

              div {
                display: flex;
                justify-content: space-between;

                h4 {
                  color: white;
                  font-size: 16px;
                  font-weight: 600;
                }
              }

              .alert-match-title {
                margin-top: 5px;
                font-weight: 500;
                font-size: 15px;
              }
            }
          }

          .alert:hover {
            background: #d9d9d929;
          }
        }

        .alerts-active-empty {
          position: relative;
          height: 350px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .background {
            position: absolute;
            width: 700px;
            right: -160px;
            top: -50px;
            z-index: 0;
          }

          p {
            margin-top: 25px;
          }
        }
      }
    }
  }

  .menu-disconnect-divider,
  .menu-connect-divider {
    margin: 8px auto;
    width: 88%;
  }

  .menu-user-identity:nth-child(1) {
    position: relative;

    .user-first-letters {
      position: absolute;
      top: 17px;
      left: 25px;
      font-size: 18px;
      font-weight: 500;
    }
  }

  .active-trading:before {
    content: ' ';
  }

  .active-portfolio,
  .active-newsfeed,
  .active-matchs,
  .active-matchs-alerts,
  .active-trading {
    position: absolute;
    top: 45px;
    height: 2px;
    width: 100%;
    border-bottom: 2px solid $blue;
  }

  .active-portfolio:before,
  .active-newsfeed:before {
    content: ' ';
  }
}

.activeLang {
  background: #555 !important;
  color: white !important;
  font-weight: 600 !important;
}

.hide-min-tel {
  flex-wrap: nowrap;
  color: #ffffff;
}

.button-burger {
  display: none;

  img {
    width: 35px;
  }
}

@media screen and (max-width: 1090px) {
  .nav-top {
    padding: 0 40px;

    .bloc-actions {
      .hide-tel {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 975px) {
  .active-portfolio,
  .active-trading,
  .hide-min-tel,
  .active-newsfeed {
    display: none;
  }

  .li-responsive .hide-min-tel {
    display: block !important;
  }

  .li-responsive {
    display: flex !important;
  }
}

@media screen and (max-width: 720px) {
  .li-responsive {
    display: flex !important;
  }

  .li-responsive img {
    display: block !important;
  }

  .nav-top {
    padding: 0 10px;
    height: 60px;

    .bloc-logo-navigation {
      .logo {
        margin-right: 5px;

        img {
          width: 85px;
        }
      }
    }
  }

  .button-burger {
    display: flex;
  }

  .user-menu {
    width: 40px !important;
    margin-left: 8px !important;
  }

  .lang-choice {
    margin-left: 0 !important;

    .lang-img {
      display: none;
    }
  }

  .navigation-auth {
    ul {
      margin-left: 10px !important;

      li {
        margin-right: 10px !important;

        img {
          margin-right: 0 !important;
        }
      }
    }
  }

  .nav-responsive {
    display: flex !important;
  }
}
</style>
