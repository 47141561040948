import { createStore } from 'vuex';
import router from '../router/index';
import { api } from '@/services/interceptors';

import auth from './auth';
import account from './accountCheck';
import account_client from './accountClient';
import account_security from './accountSecurity';
import wallet from './wallet';
import lists from './lists';
import searchProduct from './searchProduct';
import portfolio from './portfolio';
import product from './products';
import blog from './blog';
import bottler from './bottler';

import subscriptions from './subscriptions';
import payments from './payments';

import dashboard from './dashboard';
import notifications from './notifications';

export default createStore({
  state: {
    position: null,
    lang: 'FR',
    lang_data: null,
    two_fa: 0,
  },
  getters: {},
  actions: {
    redirect({ commit }) {
      localStorage.removeItem('token');
      router.push('/login');
      commit('SET_AUTH', false);
    },
    setLang({ commit }, payload) {
      api.post('api/select/lang', payload)
        .then((res) => {
          commit('CHANGE_LANG', res.data);
      });
    },
    newsletter({ commit }, payload) {
      commit('STORE_NOTHING');
      return new Promise((resolve, reject) => {
        api.post('api/newsletter', payload)
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
  },
  mutations: {
    SET_POSITION(state, payload) {
      state.position = payload;
    },
    CHANGE_LANG(currentState, lang) {
      currentState.lang = lang.lang;
      currentState.lang_data = lang.lang_data;
    },
    CHANGE_2FA_STEP(currentState, step) {
      currentState.two_fa = step;
    },
  },
  modules: {
    auth,
    account,
    account_client,
    account_security,
    wallet,
    subscriptions,
    lists,
    payments,
    notifications,
    dashboard,
    portfolio,
    blog,
    bottler,
    searchProduct,
    product,
  }
});
