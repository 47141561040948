import { ElLoading } from 'element-plus';
import { api } from '@/services/interceptors';

export default {
  state: {
    dialog_2fa: false,
    dialog_disabled_2fa: false
  },
  getters: {},
  actions: {
    setEmail2FA({ commit }, payload) {
      commit('STORE_NOTHING');
      const loading = ElLoading.service({
        lock: true,
        background: 'rgba(0, 0, 0, 0.7)',
      });
      api.post('api/auth/manage/2fa', payload)
        .then(() => {
          commit('SET_DIALOG_2FA', false);
          loading.close();
        })
        .catch(() => {
          commit('SET_DIALOG_2FA', false);
          loading.close();
        });
    },
    sendEmailCode2FA({ commit }, payload) {
      commit('STORE_NOTHING', payload);
      api.get('api/auth/manage/2fa')
        .then(() => {
          commit('SET_DIALOG_DISABLED_2FA', true);
        });
    },
  },
  mutations: {
    SET_DIALOG_2FA(currentState, dialog) {
      currentState.dialog_2fa = dialog;
    },
    SET_DIALOG_DISABLED_2FA(currentState, disabled) {
      currentState.dialog_disabled_2fa = disabled;
    },
    STORE_NOTHING() { }
  }
};