import { api } from '@/services/interceptors';

export default {
  state: {
    lists: null
  },
  getters: {
    getLists(state) {
      return state.lists;
    },
  },
  actions: {
    getLists({ commit }) {
      commit('STORE_NOTHING');
      api.get('api/lists').then((res) => {
        commit('ADD_LISTS', res.data.lists);
      });
    }
  },
  mutations: {
    ADD_LISTS(currentState, lists) {
      currentState.lists = lists;
    },
    STORE_NOTHING() { }
  }
};