<template>
  <div class="success-register">
    <img
      class="success-img"
      src="@/assets/svg/v2/registerProcess/success.svg"
      alt="Success registration"
    />
    <h1>{{ $t('register.success.title') }}</h1>

    <button @click="$router.push('/search-products')" class="search-bottles">
      <img
        src="@/assets/svg/v2/registerProcess/search.svg"
        alt="Search bottles"
      />
      <p>{{ $t('register.success.search') }}</p>
    </button>

    <div class="restriction-box">
      <div class="image-container">
        <img
          src="@/assets/svg/v2/registerProcess/unlock.svg"
          alt="Unlock account"
        />
      </div>
      <div class="restriction-explain">
        <h3>{{ $t('register.success.unlock_title') }}</h3>
        <p>{{ $t('register.success.unlock_description') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  computed: {},
  methods: {},
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root.scss';

.success-register {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .success-img {
    margin-top: 137px;
  }

  h1 {
    max-width: 492px;
    text-align: center;
    margin-top: 34px;
    margin-bottom: 29px;
  }

  .search-bottles {
    display: flex;
    border-radius: 8px;
    border: 1px solid $border;
    background: #333;
    padding: 5px 12px;
    margin-bottom: 15px;

    p {
      color: $subtitle;
      font-size: 15px;
      font-weight: 500;
      margin-left: 5px;
    }
  }

  .portfolio {
    background: transparent;
    border: 0;
    color: $subtitle;
    font-size: 15px;
    font-weight: 500;
  }
}

.restriction-box {
  display: flex;
  margin: auto;
  border-radius: 8px;
  border: 1px solid $border;
  background: #222;
  margin-bottom: 62px;
  max-height: 100px;
  max-width: 491px;
  margin-top: 44px;

  .image-container {
    margin: 16px 8px 0 11px;
  }

  .restriction-explain {
    margin-top: 16px;
    margin-bottom: 17px;

    h3 {
      color: #84cc16;
      font-size: 16px;
      font-weight: 600;
    }

    p {
      color: #84cc1699;
      font-size: 14px;
      font-weight: 500;
      margin-top: 8px;
      width: 424px;
    }
  }
}

@media screen and (max-width: 500px) {
  .success-img {
    margin-top: 30px !important;
  }

  .restriction-box {
    .restriction-explain {
      p {
        width: 300px;
      }
    }
  }
}
</style>
