<template>
  <div class="reset">
    <form @submit.prevent="checkAllInputs()">
      <h1>{{ $t('login.reset.title') }}</h1>
      <p>{{ $t('login.reset.subtitle') }}</p>

      <div class="reset-container">
        <label for="email">
          <h4>{{ $t('register.email') }}</h4>
          <el-input
            class="input"
            type="email"
            minlength="5"
            maxlength="75"
            v-model="data.email"
            :class="{ errorInput: regex.email }"
            focus
            :placeholder="$t('register.email')"
            clearable
            autocomplete="username"
          />

          <h4>{{ $t('login.reset.code') }}</h4>
          <el-input
            class="input"
            type="text"
            minlength="6"
            maxlength="6"
            v-model="data.email_code"
            :class="{ errorInput: regex.email_code }"
            focus
            :placeholder="$t('login.reset.code')"
            clearable
          />
        </label>
        <label for="password-one">
          <h4>{{ $t('login.reset.new_password') }}</h4>
          <el-input
            class="input"
            type="password"
            minlength="7"
            v-model="data.password"
            clearable
            :class="{ errorInput: regex.password }"
            show-password
            autocomplete="new-password"
            :placeholder="$t('login.reset.new_password')"
          />
        </label>
        <label for="password-two">
          <h4>{{ $t('login.reset.confirm_password') }}</h4>
          <el-input
            class="input"
            type="password"
            minlength="7"
            v-model="data.password_two"
            clearable
            :class="{ errorInput: regex.password_two }"
            show-password
            autocomplete="new-password"
            :placeholder="$t('login.reset.confirm_password')"
          />
        </label>
      </div>

      <div class="continue-reset">
        <button class="reset-connect">{{ $t('commons.continue') }}</button>
      </div>
    </form>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'

export default {
  data() {
    return {
      data: {
        email: null,
        email_code: null,
        password: null,
        password_two: null,
      },

      regex: {
        email: false,
        email_code: false,
        password: false,
        password_two: false,
      },

      wrong_values: 'Veuillez remplir correctement tous les champs',
      password_match: 'Les 2 mots de passe doivent correspondre',
    }
  },
  methods: {
    checkAllInputs() {
      this.checkEmail()
      this.checkCode()
      this.checkPassword()

      this.checkValues()
    },
    checkEmail() {
      const regex_email =
        //eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      if (!regex_email.test(this.data.email)) {
        this.regex.email = true
      } else {
        this.regex.email = false
      }
    },
    checkCode() {
      //eslint-disable-next-line
      const regex_code = /^[a-zA-Z0-9]{6}$/

      if (!regex_code.test(this.data.email_code)) {
        this.regex.email_code = true
      } else {
        this.regex.email_code = false
      }
    },
    checkPassword() {
      const regex_password =
        //eslint-disable-next-line
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,80}$/

      if (!regex_password.test(this.data.password)) {
        this.regex.password = true
      } else {
        this.regex.password = false
      }

      if (!regex_password.test(this.data.password_two)) {
        this.regex.password_two = true
      } else {
        this.regex.password_two = false
      }
    },
    checkValues() {
      let count_value = 0
      let its_ok = 0
      for (const value in this.regex) {
        count_value++
        if (this.regex[value] === false) {
          its_ok++
        }

        if (count_value === 4 && its_ok === 4) {
          this.changeMyPassword()
        } else if (count_value === 4 && its_ok < 4) {
          if (this.data.password !== this.data.password_two) {
            this.regex.password = true
            this.regex.password_two = true
            ElMessage({
              message: this.password_match,
              type: 'warning',
              grouping: true,
            })
          } else {
            ElMessage({
              message: this.wrong_values,
              type: 'warning',
              grouping: true,
            })
          }
        }
      }
    },
    changeMyPassword() {
      this.$store.dispatch('resetPassword', this.data)
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/auth.scss';

form {
  display: flex;
  flex-direction: column;
  margin-top: 10%;
  max-width: 492px;

  p {
    color: $subtitle;
    margin-top: 13px;
    margin-bottom: 34px;
  }

  .reset-container {
    color: white;

    label h4 {
      font-weight: 600;
      margin-bottom: 8px;
      margin-top: 20px;
    }
  }
}

.continue-reset button {
  display: flex;
  width: 492px;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 52px;
  background: $blue;
  margin-top: 47px;
}

@media screen and (max-width: 500px) {
  form {
    padding: 0 15px;
  }

  .reset-container {
    max-width: 320px;
  }

  .reset-container {
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;

    label {
      width: 280px;
    }
  }

  .continue-reset button {
    width: 200px;
    margin: auto;
    margin-top: 30px;
  }
}
</style>
