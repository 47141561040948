import axios from 'axios';
import { ElMessage } from 'element-plus';
import Store from '@/store/index';
import Router from '@/router/index';
import Locale from '../i18n';

if (process.env.NODE_ENV === 'production') {
  axios.defaults.withCredentials = true;
}

const apiInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
});

apiInstance.interceptors.request.use(
  async (config) => {
    const jwtTokenRegex = /^eyJ[a-zA-Z0-9_-]+?\.[a-zA-Z0-9_-]+?(\.[a-zA-Z0-9_-]+)?$/;

    if (config.url.indexOf('/subscriptions/payment')) {
      Store.commit('SET_TRANSACTION_TYPE', 'subscriptions');
    }

    if (config.url.indexOf('/kyc/pro/payment')) {
      Store.commit('SET_TRANSACTION_TYPE', 'kyc');
    }

    if (config.url.indexOf('/order/payment')) {
      Store.commit('SET_TRANSACTION_TYPE', 'orders');
    }

    const accessToken = await getAccessToken();
    if (jwtTokenRegex.test(accessToken)) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiInstance.interceptors.response.use(  
  async (response) => {

    if (response.data.limit?.max_check_product) {
      Store.commit('IS_MAX_CHECK_PRODUCT', true);

      ElMessage({
        message: Locale.global.t('commons.see_products_limit'),
        type: 'warning',
        grouping: true,
        duration: 6000
      });

      Router.go(-1);
    } else {
      Store.commit('IS_MAX_CHECK_PRODUCT', false);
    }

    if (response.data.message) {
      ElMessage({
        message: response.data.message,
        type: 'success',
        grouping: true,
        duration: 6000
      });
    }
    return response;
  },
  (error) => {
      if (error.response.status === 301) {
  
        if (error.response.data.secure_mode_redirect_url) {
          Store.commit('SET_DIALOG_3DSECURE', error.response.data.secure_mode_redirect_url);
        }
  
        if (error.response.data.transaction_uuid) {
          Store.commit('SET_TRANSACTION_UUID', error.response.data.transaction_uuid);
        }
  
      } else if (error.response.status === 400) {
        ElMessage({
          message: error.response.data.errors ? Locale.global.t('commons.check_inputs') + error.response.data.errors : error.response.data.message ? error.response.data.message : Locale.global.t('commons.generic_error'),
          type: 'warning',
          grouping: true,
          duration: 6000
        });
      } else if (error.response.status === 401) {
        localStorage.removeItem('token');
        Store.commit('SET_AUTH', false);
        Store.commit('CHANGE_2FA_STEP', 0);
      } else if (error.response.status === 403) {
        if (error.response.data.cause.missing_email_phone_verification) {
          ElMessage({
            message: Locale.global.t('commons.email_phone_validation'),
            type: 'warning',
            grouping: true,
            duration: 6000
          });
        }
      } else if (error.response.status === 406) {
        if (error.response.data.message) {
          ElMessage({
            message: error.response.data.message,
            type: 'warning',
            grouping: true,
            duration: 6000
          });
        } else {
          ElMessage({
            message: Locale.global.t('commons.not_authorized'),
            type: 'warning',
            grouping: true,
            duration: 6000
          });
        }
        
      } else if (error.response.status === 429) {
        ElMessage({
          message: Locale.global.t('commons.too_many_request'),
          type: 'warning',
          grouping: true,
          duration: 6000
        });
      } else if (error.response.status === 500) {
        ElMessage({
          message: Locale.global.t('commons.service_unaivalable'),
          type: 'error',
          grouping: true,
          duration: 6000
        });
      }
    return Promise.reject(error);
  }
);

const getAccessToken = async () => {
  let accessToken = '';

  if (process.env.NODE_ENV !== 'production') {
    let tokenLS = localStorage.getItem('token');
  
    if (tokenLS) {
      accessToken = tokenLS;
    } else {
      accessToken = '';
    }
  }

  return accessToken.replace('undefined', '');
};

export const api = apiInstance;