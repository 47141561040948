const WebSocketManager = {
    socket: null,

    async isConnected() {
      return this.socket.readyState;
    },
  
    async connectWebSocket() {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.close();
      }

      const accessToken = await getAccessToken();
      const headers =  {
        'Authorization': `Bearer ${accessToken}`
      };
      this.socket = new WebSocket(process.env.VUE_APP_BASE_URL_WS, null, JSON.stringify(headers));
  
      this.socket.onopen = () => {
        // console.log('WebSocket OK');
      };
  
      // this.socket.onmessage = (event) => {
        // console.log('R.', event.data);
      // };
  
      this.socket.onclose = (event) => {
        console.log('WebSocket closed:', event);
      };
  
      this.socket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
    },
  
    sendMessage(message) {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(message);
        // console.log('S.', message);
      } else {
        console.error('WebSocket connection not open');
        this.connectWebSocket();
      }
    }
  };

const getAccessToken = async () => {
    let accessToken = '';
  
    if (process.env.NODE_ENV !== 'production') {
      let tokenLS = localStorage.getItem('token');
    
      if (tokenLS) {
        accessToken = tokenLS;
      } else {
        accessToken = '';
      }
    }
  
    return accessToken.replace('undefined', '');
  };
  
  export default WebSocketManager;