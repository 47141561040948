<template>
  <div class="register">
    <div class="register-step">
      <el-steps
        v-if="widthScreen > 500"
        :active="isAccountTypeSelected"
        align-center
        finish-status="success"
      >
        <el-step :title="$t('register.step.step_1')" />
        <el-step :title="$t('register.step.step_2')" />
        <el-step :title="$t('register.step.step_3')" />
        <el-step :title="$t('register.step.step_4')" />
      </el-steps>
      <el-steps
        v-else
        :active="isAccountTypeSelected"
        align-center
        finish-status="success"
      >
        <el-step :title="$t('register.step.step_1_phone')" />
        <el-step title="2FA" />
        <el-step title="KYC" />
        <el-step :title="$t('register.step.step_4_phone')" />
      </el-steps>
    </div>
    <step_0
      v-if="accountType === null && isAccountTypeSelected === 0"
      @accountTypeSelect="selectAccountType"
    ></step_0>
    <step_1
      v-if="accountType !== null && isAccountTypeSelected === 0"
      :account="accountType"
      @back="changeRegisterStep(1)"
    ></step_1>
    <step_2 v-if="isAccountTypeSelected === 1"></step_2>
    <step_beforeKyc
      v-if="isAccountTypeSelected === 2 && stepBeforeKYC"
      @goToKYC="switchToKYC()"
    ></step_beforeKyc>
    <step_3 v-if="isAccountTypeSelected === 2 && !stepBeforeKYC"></step_3>
    <step_4 v-if="isAccountTypeSelected === 3"></step_4>
    <completeRegister v-if="isAccountTypeSelected === 4"></completeRegister>
  </div>
</template>

<script>
import step_0 from '@/components/auth/0_accountTypeChoice.vue'
import step_1 from '@/components/auth/1_registerForm.vue'
import step_2 from '@/components/auth/2_phoneEmailConfirm.vue'
import step_beforeKyc from '@/components/auth/beforeKYC.vue'
import step_3 from '@/components/auth/3_kyc.vue'
import step_4 from '@/components/auth/4_banking.vue'
import completeRegister from '@/components/auth/5_success.vue'

import { mapGetters } from 'vuex'

export default {
  components: {
    step_0,
    step_1,
    step_2,
    step_beforeKyc,
    step_3,
    step_4,
    completeRegister,
  },
  data() {
    return {
      accountType: null,
      stepBeforeKYC: true,
    }
  },
  computed: {
    ...mapGetters({
      getLists: 'getLists',
    }),
    country() {
      return this.getLists?.country
    },
    isAccountTypeSelected() {
      return this.$store.state.auth.register_step - 1
    },
    widthScreen() {
      return window.outerWidth
    },
  },
  methods: {
    selectAccountType(type) {
      if (type === 1) {
        this.accountType = true
      } else {
        this.accountType = false
      }
    },
    changeRegisterStep(step) {
      if (step === 1) {
        this.accountType = null
      }

      this.$store.commit('CHANGE_REGISTER_STEP', step)
    },
    switchToKYC() {
      this.stepBeforeKYC = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/auth.scss';

.hide-pc {
  display: none;
}

.hide-tel {
  display: flex;
}

.register {
  display: flex;
  flex-direction: column;
}

.register {
  background: #222;

  .register-step {
    background: #333;
    width: 100%;
    padding-top: 38px;
    padding-bottom: 43px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  }
}

@media screen and (max-width: 500px) {
  .hide-tel {
    display: none;
  }

  .hide-pc {
    display: flex;
  }
}
</style>
