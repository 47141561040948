import { ElMessage } from 'element-plus';
import { api } from '@/services/interceptors';
import i18n from '../i18n';

export default {
  state: {
    email_is_confirm: false,
    phone_is_confirm: false,
    banking_is_already_send: false,
  },
  getters: {
    emailIsConfirm(state) {
      return state.email_is_confirm;
    },
    phoneIsConfirm(state) {
      return state.phone_is_confirm;
    },
  },
  actions: {
    getEmailCode({ commit }, payload) {
      commit('STORE_NOTHING', payload);
      api.get('api/check/email');
    },
    sendEmailCode({ commit, dispatch }, payload) {
      commit('STORE_NOTHING');
      api.post('api/check/email', payload)
        .then(() => {
          dispatch('getPhoneEmailCheck');
        });
    },
    getPhoneCode({ commit }, payload) {
      commit('STORE_NOTHING', payload);
      api.get('api/check/phone');
    },
    sendPhoneCode({ commit, dispatch }, payload) {
      commit('STORE_NOTHING');
      api.post('api/check/phone', payload)
        .then(() => {
          dispatch('getPhoneEmailCheck');
        });
    },
    getPhoneEmailCheck({ commit }, payload) {
      commit('STORE_NOTHING', payload);
      api.get('api/check/all')
        .then((res) => {
          if (res.data.email_is_verified) {
            commit('EMAIL_IS_CONFIRM', res.data.email_is_verified);
          }

          if (res.data.phone_is_verified) {
            commit('PHONE_IS_CONFIRM', res.data.phone_is_verified);
          }

          if (res.data.email_is_verified && res.data.phone_is_verified) {
            commit('CHANGE_REGISTER_STEP', 3);
          }
        });
    },
    sendKycRequest({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('STORE_NOTHING');
        api.post('api/register/kyc', payload)
          .then(async() => {
            resolve();
            ElMessage({
              message: i18n.global.t('register.step_3.kyc_documents_send'),
              type: 'success',
              grouping: true,
              duration: 10000
            });
          })
          .catch(() => {
            reject();
          });
      });
    },
    sendKycPayment({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('STORE_NOTHING');
        api.post('api/register/kyc/pro/payment', payload)
          .then((res) => {
            ElMessage({
              message: i18n.global.t('register.step_3.kyc_documents_send'),
              type: 'success',
              grouping: true,
              duration: 10000
            });
            resolve(res);
          })
          .catch(() => {
            reject();
          });
      });
    },
    sendBankingInformations({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('STORE_NOTHING');
        api.post('api/banking-informations', payload)
          .then(() => {
            resolve();
            ElMessage({
              message: i18n.global.t('register.step_4.banking_save'),
              type: 'success',
              grouping: true
            });
          })
          .catch(() => {
            reject();
          });
      });
    },
    getAlreadyBankingSend({ commit }) {
      commit('STORE_NOTHING');
      api.get('api/banking-informations')
        .then((res) => {
          if (res.data.banking_is_already_send) {
            ElMessage({
              message: i18n.global.t('register.step_4.banking_already_send'),
              type: 'warning',
              grouping: true
            });
            commit('BANKING_IS_ALREADY_SEND', true);
          }
        });
    },
  },
  mutations: {
    EMAIL_IS_CONFIRM(currentState, valid) {
      currentState.email_is_confirm = valid;
    },
    PHONE_IS_CONFIRM(currentState, valid) {
      currentState.phone_is_confirm = valid;
    },
    BANKING_IS_ALREADY_SEND(currentState, state) {
      currentState.banking_is_already_send = state;
    },
    STORE_NOTHING() { }
  }
};