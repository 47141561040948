<template>
  <form @submit.prevent="checkAllInputs()" class="banking-informations">
    <h1>{{ $t('register.step_4.title') }}</h1>
    <p>{{ $t('register.step_4.why_enter_banking') }}</p>
    <div class="banking-form">
      <div class="identity-informations">
        <el-input
          class="input"
          type="text"
          :size="size"
          :value="returnFullnames"
          disabled
        />
      </div>
      <div>
        <h4>{{ $t('register.step_4.iban') }}</h4>

        <div class="iban-info">
          <el-select
            class="select-input input"
            :placeholder="$t('register.step_1.select')"
            filterable
            v-model="banking_informations.country"
            :size="size"
            effect="dark"
          >
            <el-option
              v-for="item in country"
              :key="item.nom_en_gb"
              :label="item.nom_en_gb"
              :value="item.alpha2"
            />
          </el-select>

          <el-input
            class="input"
            type="text"
            minlength="5"
            maxlength="35"
            :size="size"
            v-model="banking_informations.iban"
            clearable
            :class="{ errorInput: regex.iban }"
            :placeholder="$t('register.step_4.iban_placeholder')"
          />
        </div>

        <h4
          v-if="
            banking_informations.country !== 'CA' &&
            banking_informations.country !== 'GB'
          "
        >
          {{ $t('register.step_4.bic') }}
        </h4>
        <el-input
          v-if="
            banking_informations.country !== 'CA' &&
            banking_informations.country !== 'GB'
          "
          class="input bic-info"
          type="text"
          minlength="3"
          maxlength="20"
          :size="size"
          v-model="banking_informations.bic"
          clearable
          :class="{ errorInput: regex.bic }"
          :placeholder="$t('register.step_4.bic_placeholder')"
        />

        <el-select
          v-if="banking_informations.country === 'US'"
          class="region"
          :size="size"
          :class="{ errorInput: regex.deposit_account_type }"
          v-model="banking_informations.deposit_account_type"
          effect="dark"
        >
          <el-option label="Checking" :value="0"></el-option>
          <el-option label="Savings" :value="1"></el-option>
        </el-select>

        <h4 v-if="banking_informations.country === 'CA'" class="region">
          {{ $t('register.step_4.institution_number') }}
        </h4>
        <el-input
          v-if="banking_informations.country === 'CA'"
          class="input"
          :size="size"
          v-model="banking_informations.institution_number"
          :class="{ errorInput: regex.institution_number }"
          clearable
          :placeholder="$t('register.step_4.institution_number')"
        />

        <h4 v-if="banking_informations.country === 'CA'" class="region">
          {{ $t('register.step_4.branch_code') }}
        </h4>
        <el-input
          v-if="banking_informations.country === 'CA'"
          class="input"
          type="text"
          :size="size"
          v-model="banking_informations.branch_code"
          :class="{ errorInput: regex.branch_code }"
          clearable
          :placeholder="$t('register.step_4.branch_code')"
        />

        <h4 v-if="banking_informations.country === 'CA'" class="region">
          {{ $t('register.step_4.bankname') }}
        </h4>
        <el-input
          v-if="banking_informations.country === 'CA'"
          class="input"
          type="text"
          :size="size"
          v-model="banking_informations.bank_name"
          :class="{ errorInput: regex.bank_name }"
          clearable
          :placeholder="$t('register.step_4.bankname')"
        />

        <h4 v-if="banking_informations.country === 'GB'" class="region">
          Code
        </h4>
        <el-input
          v-if="banking_informations.country === 'GB'"
          class="input"
          type="text"
          :size="size"
          v-model="banking_informations.short_code"
          :class="{ errorInput: regex.short_code }"
          clearable
          placeholder="320889"
        />

        <h4 class="region">{{ $t('register.step_4.region') }}</h4>
        <el-input
          class="input"
          type="text"
          minlength="3"
          maxlength="20"
          :size="size"
          v-model="banking_informations.region"
          :class="{ errorInput: regex.region }"
          clearable
          :placeholder="$t('register.step_4.region_placeholder')"
        />
      </div>
    </div>

    <button class="banking-validation generic-btn">
      {{ $t('register.step_4.submit') }}
    </button>
    <button
      @click.prevent="$router.push('/search-products')"
      class="search-bottles"
    >
      <img
        src="@/assets/svg/v2/registerProcess/search.svg"
        alt="Search bottles"
      />
      <p>{{ $t('register.success.search') }}</p>
    </button>

    <div class="mangopay-transaction">
      <div class="mangopay-transaction-svg">
        <img
          src="@/assets/svg/v2/registerProcess/mangopaySecure.svg"
          alt="Mangopay transactions"
        />
      </div>
      <p>{{ $t('register.step_4.mangopay') }}</p>
    </div>

    <div class="mangopay-logo">
      <img src="@/assets/svg/mangopayGrey.svg" alt="Mangopay" />
    </div>

    <div class="restriction-box">
      <div class="image-container">
        <img
          src="@/assets/svg/v2/registerProcess/lock.svg"
          alt="Lock account"
        />
      </div>
      <div class="restriction-explain">
        <h3>{{ $t('register.step_3.active_restriction') }}</h3>
        <p>{{ $t('register.step_3.description_restriction') }}</p>
      </div>
    </div>
  </form>
</template>

<script>
import { ElMessage } from 'element-plus'
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      can_submit: false,
      size: 'large',

      banking_informations: {
        country: 'FR',
        iban: null,
        bic: null,
        region: null,

        deposit_account_type: 0,

        institution_number: null,
        branch_code: null,
        bank_name: null,

        short_code: null,
      },

      regex: {
        bic: null,
        iban: null,
        region: null,

        deposit_account_type: null,

        institution_number: null,
        branch_code: null,
        bank_name: null,

        short_code: null,
      },
    }
  },
  computed: {
    ...mapGetters({
      getUserDetails: 'getUserDetails',
      getLists: 'getLists',
    }),
    returnFullnames() {
      let name
      if (this.getUserDetails?.address.account_type === 0) {
        name = `${this.getUserDetails?.identity.lastname.toUpperCase()} ${this.getUserDetails?.identity.firstname.toUpperCase()}`
      } else if (this.getUserDetails?.address.account_type === 1) {
        name = `${this.getUserDetails?.address.organization.name.toUpperCase()}`
      }
      return name
    },
    country() {
      return this.getLists?.country
    },
  },
  watch: {
    'banking_informations.iban': function () {
      if (this.banking_informations.iban) {
        this.banking_informations.iban = this.banking_informations.iban.replaceAll(' ', '');
      }
    },
    'banking_informations.bic': function () {
      const regex_bic = /^[0-9a-zA-Z]{1,11}$/
      if (!regex_bic.test(this.banking_informations.bic)) {
        this.banking_informations.bic = null
      }
    },
  },
  methods: {
    ...mapActions({
      sendBankingInformations: 'sendBankingInformations',
      getClient: 'getAccountDetails',
      authVerification: 'authverification',
    }),
    checkAllInputs() {
      this.checkIBAN()
      this.checkBIC()
      this.checkRegion()

      if (this.banking_informations.country === 'CA') {
        this.checkBranchCode()
        this.checkInstitution()
        this.checkBankName()
      }

      if (this.banking_informations.country === 'GB') {
        this.checkShortCode()
      }

      this.checkValues()
    },
    checkIBAN() {
      const regex_iban = /^[A-Z]{2}(?:[ ]?[0-9]){15,30}$/

      if (
        !regex_iban.test(this.banking_informations.iban) ||
        !this.banking_informations.iban
      ) {
        this.regex.iban = true
      } else {
        this.regex.iban = false
      }
    },
    checkBIC() {
      const regex_bic = /^[0-9a-zA-Z]{8,11}$/

      if (
        !regex_bic.test(this.banking_informations.bic) ||
        !this.banking_informations.bic
      ) {
        this.regex.bic = true
      } else {
        this.regex.bic = false
      }
    },
    checkRegion() {
      if (this.banking_informations.region) {
        this.regex.region = false
      } else {
        this.regex.region = true
      }
    },
    checkInstitution() {
      const regex_institution_number = /^[0-9a-zA-Z]{3}$/

      if (
        !regex_institution_number.test(
          this.banking_informations.institution_number
        ) ||
        !this.banking_informations.institution_number
      ) {
        this.regex.institution_number = true
      } else {
        this.regex.institution_number = false
      }
    },
    checkBranchCode() {
      const regex_branch_code = /^[0-9a-zA-Z]{5}$/

      if (
        !regex_branch_code.test(this.banking_informations.branch_code) ||
        !this.banking_informations.branch_code
      ) {
        this.regex.branch_code = true
      } else {
        this.regex.branch_code = false
      }
    },
    checkShortCode() {
      const regex_short_code = /^[0-9a-zA-Z]{6}$/

      if (
        !regex_short_code.test(this.banking_informations.short_code) ||
        !this.banking_informations.short_code
      ) {
        this.regex.short_code = true
      } else {
        this.regex.short_code = false
      }
    },
    checkBankName() {
      const regex_bank_name = /[a-zA-Z-'àâäéèêëîïôöùûüÿçÀÂÄÉÈÊËÎÏÔÖÙÛÜŸÇ]{2,50}/

      if (
        !regex_bank_name.test(this.banking_informations.bank_name) ||
        !this.banking_informations.bank_name
      ) {
        this.regex.bank_name = true
      } else {
        this.regex.bank_name = false
      }
    },
    async checkValues() {
      if (this.banking_informations.country === 'US') {
        if (
          (!this.regex.iban &&
            !this.regex.bic &&
            !this.regex.region &&
            this.banking_informations.deposit_account_type === 0) ||
          (!this.regex.iban &&
            !this.regex.bic &&
            this.banking_informations.deposit_account_type === 1)
        ) {
          await this.sendBankingInformations(this.banking_informations).then(
            async () => {
              this.authVerification()
            }
          )
        } else {
          ElMessage({
            message: 'Please check inputs',
            type: 'warning',
            grouping: true,
          })
        }
      } else if (this.banking_informations.country === 'GB') {
        if (!this.regex.iban && !this.regex.region && !this.regex.short_code) {
          await this.sendBankingInformations(this.banking_informations).then(
            async () => {
              this.authVerification()
            }
          )
        } else {
          ElMessage({
            message: 'Please check inputs',
            type: 'warning',
            grouping: true,
          })
        }
      } else if (this.banking_informations.country === 'CA') {
        if (
          !this.regex.iban &&
          !this.regex.region &&
          !this.regex.bank_name &&
          !this.regex.branch_code &&
          !this.regex.institution_number
        ) {
          await this.sendBankingInformations(this.banking_informations).then(
            async () => {
              this.authVerification()
            }
          )
        } else {
          ElMessage({
            message: 'Please check inputs',
            type: 'warning',
            grouping: true,
          })
        }
      } else {
        if (!this.regex.iban && !this.regex.bic && !this.regex.region) {
          await this.sendBankingInformations(this.banking_informations).then(
            async () => {
              this.authVerification()
            }
          )
        } else {
          ElMessage({
            message: 'Please check inputs',
            type: 'warning',
            grouping: true,
          })
        }
      }
    },
  },
  mounted() {
    if (!this.getUserDetails) {
      this.getClient()
    }
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root.scss';

.banking-informations,
.iban-info,
.mangopay-transaction,
.restriction-box {
  display: flex;
}

.banking-informations {
  flex-direction: column;
}

form {
  max-width: 492px;
  margin: auto;
  margin-top: 74px;

  h1 {
    margin-bottom: 8px;
  }

  p {
    color: $subtitle;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 41px;
  }

  .banking-form {
    .identity-informations {
      margin-bottom: 20px;
    }

    div {
      h4 {
        color: white;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 8px;
      }

      .region {
        margin-top: 20px;
      }

      .iban-info {
        margin-bottom: 20px;

        .select-input {
          background: $el-bg-color;
          max-width: 20%;
        }

      }
      .bic-info {
        max-width: 50%;
      }
    }
  }

  .banking-validation {
    margin-top: 56px;
  }

  .mangopay-transaction {
    margin-top: 16px;

    div {
      margin-right: 8px;
    }
  }

  .mangopay-logo {
    display: flex;
    justify-content: center;
    margin-bottom: 54px;
  }
}

.search-bottles {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid $border;
  background: #333;
  padding: 5px 12px;
  margin: auto;
  margin-top: 20px;
  width: 240px;
  height: 35px;

  p {
    color: $subtitle;
    font-size: 15px;
    font-weight: 500;
    margin-left: 5px;
    margin-bottom: 0;
  }
}

.restriction-box {
  border-radius: 8px;
  border: 1px solid $border;
  background: #222;
  margin-bottom: 62px;
  max-height: 100px;

  .image-container {
    margin: 16px 8px 0 11px;
  }

  .restriction-explain {
    margin-top: 16px;
    margin-bottom: 17px;

    h3 {
      color: #f59e0b;
      font-size: 16px;
      font-weight: 600;
    }

    p {
      color: #f59e0b99;
      font-size: 14px;
      font-weight: 500;
      margin-top: 8px;
      width: 424px;
    }
  }
}

@media screen and (max-width: 500px) {
  .banking-informations {
    margin: auto 10px;
    margin-top: 30px;

    .banking-form {
      width: 100%;
    }

    .banking-validation {
      width: 250px;
      margin: auto;
      margin-top: 15px;
    }
  }

  .restriction-box {
    .restriction-explain {
      p {
        width: 320px;
      }
    }
  }
}
</style>
