<template>
  <div class="register-form">
    <div class="register-center">
      <div @click="$emit('back')" class="register-account-type">
        <img
          v-if="register.account_type"
          src="@/assets/svg/v2/proActive.svg"
          alt="Pro active"
        />
        <img
          v-else
          src="@/assets/svg/v2/personnalActive.svg"
          alt="Personnal active"
        />

        <p v-if="register.account_type">
          {{ $t('register.step_1.business_account') }}
        </p>
        <p v-else>{{ $t('register.step_1.personnal_account') }}</p>
      </div>
      <h1>{{ $t('register.step_1.general_informations') }}</h1>
      <p>{{ $t('register.step_1.subtitle') }}</p>

      <form>
        <div class="bloc-1">
          <h4>{{ $t('register.civility') }}</h4>
          <el-select
            :placeholder="$t('register.civility')"
            filterable
            v-model="register.civility"
            :size="size"
            :class="{ errorInput: regex.civility }"
            effect="dark"
          >
            <el-option
              v-for="item in civility"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>

        <div class="bloc-2">
          <label for="firstname" class="mr">
            <h4>{{ $t('register.firstname') }}</h4>
            <el-input
              :placeholder="$t('register.firstname')"
              type="text"
              minlength="2"
              maxlength="50"
              autofocus
              v-model="register.firstname"
              clearable
              :class="{ errorInput: regex.firstname }"
              :size="size"
            />
          </label>
          <label for="lastname">
            <h4>{{ $t('register.lastname') }}</h4>
            <el-input
              :placeholder="$t('register.lastname')"
              type="text"
              minlength="2"
              maxlength="50"
              v-model="register.lastname"
              clearable
              :class="{ errorInput: regex.lastname }"
              :size="size"
            />
          </label>
        </div>

        <div class="bloc-2 birthdate">
          <label for="birthday" class="mr">
            <h4>{{ $t('register.birthdate') }}</h4>
            <el-input
              class="birthdate"
              v-model="register.birth_unformated"
              placeholder="DD/MM/YYYY"
              :style="!register.account_type ? 'width: 237px' : null"
              clearable
              :size="size"
              format="YYYY/MM/DD"
              value-format="YYYY-MM-DD"
              :class="{ errorInput: regex.birth }"
            />
          </label>
          <label v-if="register.account_type" for="birth_city">
            <h4>{{ $t('register.birth_city') }}</h4>
            <el-input
              :placeholder="$t('register.birth_city')"
              type="text"
              minlength="2"
              maxlength="100"
              autofocus
              v-model="register.birth_city"
              clearable
              :class="{ errorInput: regex.birth_city }"
              :size="size"
            />
          </label>
        </div>

        <div v-if="register.account_type" class="bloc-2 nationality-country">
          <label for="birth_country">
            <h4>{{ $t('register.birth_country') }}</h4>
            <el-select
              :placeholder="$t('register.step_1.select')"
              filterable
              v-model="register.birth_country"
              :size="size"
              effect="dark"
            >
              <el-option
                v-for="item in country"
                :key="item.nom_en_gb"
                :label="item.nom_en_gb"
                :value="item.alpha2"
              />
            </el-select>
          </label>
        </div>

        <div class="bloc-2 nationality-country">
          <label for="nationality">
            <h4>{{ $t('register.nationality') }}</h4>
            <el-select
              :placeholder="$t('register.step_1.select')"
              filterable
              v-model="register.nationality"
              :size="size"
              effect="dark"
            >
              <el-option
                v-for="item in country"
                :key="item.nom_en_gb"
                :label="item.nom_en_gb"
                :value="item.alpha2"
              />
            </el-select>
          </label>
          <label for="country">
            <h4>{{ $t('register.country') }}</h4>
            <el-select
              :placeholder="$t('register.step_1.select')"
              filterable
              v-model="register.country"
              :size="size"
              effect="dark"
            >
              <el-option
                v-for="item in country"
                :key="item.nom_en_gb"
                :label="item.nom_en_gb"
                :value="item.alpha2"
              />
            </el-select>
          </label>
        </div>

        <div class="bloc-2">
          <label for="region">
            <h4>{{ $t('register.region') }}</h4>

            <el-select
              v-if="register.country === 'US'"
              :placeholder="$t('register.region')"
              filterable
              v-model="register.region"
              :size="size"
              effect="dark"
            >
              <el-option
                v-for="item in getLists.states_us"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>

            <el-select
              v-else-if="register.country === 'CA'"
              :placeholder="$t('register.region')"
              filterable
              v-model="register.region"
              :size="size"
              effect="dark"
            >
              <el-option
                v-for="item in getLists.states_ca"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>

            <el-input
              v-else
              :placeholder="$t('register.region')"
              type="text"
              minlength="2"
              maxlength="50"
              v-model="register.region"
              clearable
              :class="{ errorInput: regex.region }"
              :size="size"
            />
          </label>
        </div>

        <el-divider class="form-divider" v-if="!register.account_type" />

        <div class="bloc-2">
          <label for="home-address">
            <h4>{{ $t('register.address') }}</h4>
            <el-input
              :placeholder="$t('register.address_placeholder')"
              type="text"
              minlength="2"
              maxlength="80"
              clearable
              v-model="register.address"
              :size="size"
              :class="{ errorInput: regex.address }"
            />
          </label>
        </div>

        <div class="bloc-2">
          <label for="zip" class="mr">
            <h4>{{ $t('register.zipcode') }}</h4>
            <el-input
              :placeholder="$t('register.zipcode')"
              type="text"
              minlength="4"
              maxlength="7"
              v-model="register.zip"
              clearable
              :class="{ errorInput: regex.zip }"
              :size="size"
            />
          </label>
          <label for="city">
            <h4>{{ $t('register.city') }}</h4>
            <el-input
              :placeholder="$t('register.city')"
              type="text"
              minlength="2"
              maxlength="50"
              v-model="register.city"
              clearable
              :class="{ errorInput: regex.city }"
              :size="size"
            />
          </label>
        </div>

        <el-divider class="form-divider" />

        <div class="bloc-2">
          <label for="email" class="tel-width">
            <h4>{{ $t('register.email') }}</h4>
            <el-input
              :placeholder="$t('register.email')"
              type="email"
              minlength="2"
              maxlength="75"
              v-model="register.email"
              :size="size"
              clearable
              :class="{ errorInput: regex.email }"
            />
          </label>
        </div>

        <div class="bloc-2">
          <label for="phone" class="tel-width">
            <h4>{{ $t('register.phone') }}</h4>
            <el-input
              v-model="register.phone"
              placeholder="06 01 02 03 04"
              :class="{ errorInput: regex.phone }"
              :size="size"
              autocomplete="phone"
              clearable
            >
              <template #prepend>
                <el-select
                  :placeholder="$t('register.step_1.select')"
                  class="tel-choice-coutry"
                  filterable
                  v-model="register.phone_country"
                  :size="size"
                  clearable
                  effect="dark"
                >
                  <el-option
                    v-for="item in country"
                    :key="item.nom_en_gb"
                    :label="item.nom_en_gb + '(+' + item.phone_code + ')'"
                    :value="item.phone_code"
                  >
                    <span>
                      {{ item.nom_en_gb + '(+' + item.phone_code + ')' }}
                    </span>
                  </el-option>
                </el-select>
              </template>
            </el-input>
          </label>
        </div>

        <div class="bloc-2">
          <label for="password-one" class="tel-width">
            <h4>{{ $t('register.password') }}</h4>
            <el-input
              type="password"
              :placeholder="$t('register.password')"
              v-model="register.password"
              clearable
              :size="size"
              :class="{ errorInput: regex.password }"
              show-password
              autocomplete="new-password"
            />
          </label>
        </div>

        <div class="bloc-2">
          <label for="password-two" class="tel-width">
            <h4>{{ $t('register.confirm_password') }}</h4>
            <el-input
              type="password"
              :placeholder="$t('register.confirm_password')"
              v-model="register.password_two"
              clearable
              :size="size"
              :class="{ errorInput: regex.password_two }"
              show-password
              autocomplete="new-password"
            />
          </label>
        </div>
        <p class="password-requirement">
          {{ $t('register.step_1.password_requirement') }}
        </p>

        <div v-if="register.account_type">
          <el-divider class="form-divider" />

          <div class="bloc-2" v-if="register.account_type">
            <label for="company-name">
              <h4>{{ $t('register.company_name') }}</h4>
              <el-input
                class="input"
                type="text"
                minlength="2"
                maxlength="50"
                v-model="register.organization.name"
                :size="size"
                clearable
                :class="{ errorInput: regex.company_name }"
                :placeholder="$t('register.company_name_placeholder')"
              />
            </label>
          </div>
          <div class="bloc-2" v-if="register.account_type">
            <label for="company-address">
              <h4>{{ $t('register.company_address') }}</h4>
              <el-input
                class="input"
                type="text"
                minlength="2"
                maxlength="80"
                clearable
                :placeholder="$t('register.address_placeholder')"
                v-model="register.organization.address"
                :size="size"
                :class="{ errorInput: regex.company_address }"
              />
            </label>
          </div>
          <div class="bloc-2" v-if="register.account_type">
            <label for="company-zip" class="mr">
              <h4>{{ $t('register.company_zipcode') }}</h4>
              <el-input
                class="input"
                type="text"
                minlength="4"
                maxlength="7"
                v-model="register.organization.zip"
                :size="size"
                clearable
                :class="{ errorInput: regex.company_zip }"
                :placeholder="$t('register.zipcode')"
              />
            </label>
            <label for="company-city">
              <h4>{{ $t('register.company_city') }}</h4>
              <el-input
                class="input"
                type="text"
                minlength="2"
                maxlength="50"
                v-model="register.organization.city"
                :size="size"
                clearable
                :class="{ errorInput: regex.company_city }"
                :placeholder="$t('register.city')"
              />
            </label>
          </div>
          <div class="bloc-2" v-if="register.account_type">
            <label for="region">
              <h4>{{ $t('register.region') }}</h4>
              <el-input
                :placeholder="$t('register.region')"
                type="text"
                minlength="2"
                maxlength="50"
                v-model="register.organization.region"
                clearable
                :class="{ errorInput: regex.company_region }"
                :size="size"
              />
            </label>
          </div>
          <div class="bloc-2 nationality-country" v-if="register.account_type">
            <label for="company-country">
              <h4>{{ $t('register.company_country') }}</h4>
              <el-select
                class="select-list"
                no-match-text="No data"
                placeholder="..."
                filterable
                v-model="register.organization.country"
                size="large"
                effect="dark"
                clearable
              >
                <el-option
                  v-for="item in country"
                  :key="item.nom_en_gb"
                  :label="item.nom_en_gb"
                  :value="item.alpha2"
                />
              </el-select>
            </label>
            <label for="company-type">
              <h4>{{ $t('register.company_type') }}</h4>
              <el-select
                class="select-list"
                effect="dark"
                no-match-text="No data"
                placeholder="..."
                filterable
                v-model="register.organization.organization_type"
                size="large"
                :class="{ errorInput: regex.company_type }"
                clearable
              >
                <el-option
                  v-for="item in getLists.organization_type"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </label>
          </div>
          <div class="bloc-2" v-if="register.account_type">
            <label
              v-if="register.organization.country == 'GB'"
              for="company-siret"
              class="mr"
            >
              <h4>{{ $t('register.company_siret_GB') }}</h4>
              <el-input
                class="input"
                type="text"
                minlength="14"
                maxlength="14"
                v-model="register.organization.siret"
                :size="size"
                clearable
                :class="{ errorInput: regex.company_siret }"
                :placeholder="$t('register.company_siret_placeholder_8')"
              />
            </label>
            <label
              v-else-if="register.organization.country == 'FR'"
              for="company-siret"
              class="mr"
            >
              <h4>{{ $t('register.company_siret_FR') }}</h4>
              <el-input
                class="input"
                type="text"
                minlength="14"
                maxlength="14"
                v-model="register.organization.siret"
                :size="size"
                clearable
                :class="{ errorInput: regex.company_siret }"
                :placeholder="$t('register.company_siret_placeholder')"
              />
            </label>
            <label
              v-else-if="register.organization.country == 'AT'"
              for="company-siret"
              class="mr"
            >
              <h4>{{ $t('register.company_siret_AT') }}</h4>
              <el-input
                class="input"
                type="text"
                minlength="14"
                maxlength="14"
                v-model="register.organization.siret"
                :size="size"
                clearable
                :class="{ errorInput: regex.company_siret }"
                :placeholder="$t('register.company_siret_placeholder_AT')"
              />
            </label>
            <label
              v-else-if="register.organization.country == 'BE'"
              for="company-siret"
              class="mr"
            >
              <h4>{{ $t('register.company_siret') }}</h4>
              <el-input
                class="input"
                type="text"
                minlength="14"
                maxlength="14"
                v-model="register.organization.siret"
                :size="size"
                clearable
                :class="{ errorInput: regex.company_siret }"
                :placeholder="$t('register.company_siret_placeholder_10')"
              />
            </label>
            <label
              v-else-if="register.organization.country == 'BG'"
              for="company-siret"
              class="mr"
            >
              <h4>{{ $t('register.company_siret_BG') }}</h4>
              <el-input
                class="input"
                type="text"
                minlength="14"
                maxlength="14"
                v-model="register.organization.siret"
                :size="size"
                clearable
                :class="{ errorInput: regex.company_siret }"
                :placeholder="$t('register.company_siret_placeholder_9')"
              />
            </label>
            <label
              v-else-if="register.organization.country == 'HR'"
              for="company-siret"
              class="mr"
            >
              <h4>{{ $t('register.company_siret_HR') }}</h4>
              <el-input
                class="input"
                type="text"
                minlength="14"
                maxlength="14"
                v-model="register.organization.siret"
                :size="size"
                clearable
                :class="{ errorInput: regex.company_siret }"
                :placeholder="$t('register.company_siret_placeholder_11')"
              />
            </label>
            <label
              v-else-if="register.organization.country == 'CY'"
              for="company-siret"
              class="mr"
            >
              <h4>{{ $t('register.company_siret') }}</h4>
              <el-input
                class="input"
                type="text"
                minlength="14"
                maxlength="14"
                v-model="register.organization.siret"
                :size="size"
                clearable
                :class="{ errorInput: regex.company_siret }"
                :placeholder="$t('register.company_siret_placeholder_CY')"
              />
            </label>
            <label
              v-else-if="register.organization.country == 'CZ'"
              for="company-siret"
              class="mr"
            >
              <h4>{{ $t('register.company_siret_CZ') }}</h4>
              <el-input
                class="input"
                type="text"
                minlength="14"
                maxlength="14"
                v-model="register.organization.siret"
                :size="size"
                clearable
                :class="{ errorInput: regex.company_siret }"
                :placeholder="$t('register.company_siret_placeholder_8')"
              />
            </label>
            <label
              v-else-if="register.organization.country == 'DK'"
              for="company-siret"
              class="mr"
            >
              <h4>{{ $t('register.company_siret_DK') }}</h4>
              <el-input
                class="input"
                type="text"
                minlength="14"
                maxlength="14"
                v-model="register.organization.siret"
                :size="size"
                clearable
                :class="{ errorInput: regex.company_siret }"
                :placeholder="$t('register.company_siret_placeholder_8')"
              />
            </label>
            <label
              v-else-if="register.organization.country == 'EE'"
              for="company-siret"
              class="mr"
            >
              <h4>{{ $t('register.company_siret_EE') }}</h4>
              <el-input
                class="input"
                type="text"
                minlength="14"
                maxlength="14"
                v-model="register.organization.siret"
                :size="size"
                clearable
                :class="{ errorInput: regex.company_siret }"
                :placeholder="$t('register.company_siret_placeholder_8')"
              />
            </label>
            <label
              v-else-if="register.organization.country == 'FI'"
              for="company-siret"
              class="mr"
            >
              <h4>{{ $t('register.company_siret_FI') }}</h4>
              <el-input
                class="input"
                type="text"
                minlength="14"
                maxlength="14"
                v-model="register.organization.siret"
                :size="size"
                clearable
                :class="{ errorInput: regex.company_siret }"
                :placeholder="$t('register.company_siret_placeholder_8')"
              />
            </label>
            <label
              v-else-if="register.organization.country == 'DE'"
              for="company-siret"
              class="mr"
            >
              <h4>{{ $t('register.company_siret') }}</h4>
              <el-input
                class="input"
                type="text"
                minlength="14"
                maxlength="14"
                v-model="register.organization.siret"
                :size="size"
                clearable
                :class="{ errorInput: regex.company_siret }"
                :placeholder="$t('register.company_siret_placeholder_DE')"
              />
            </label>
            <label
              v-else-if="register.organization.country == 'GR'"
              for="company-siret"
              class="mr"
            >
              <h4>{{ $t('register.company_siret_GR') }}</h4>
              <el-input
                class="input"
                type="text"
                minlength="14"
                maxlength="14"
                v-model="register.organization.siret"
                :size="size"
                clearable
                :class="{ errorInput: regex.company_siret }"
                :placeholder="$t('register.company_siret_placeholder_GR')"
              />
            </label>
            <label
              v-else-if="register.organization.country == 'HU'"
              for="company-siret"
              class="mr"
            >
              <h4>{{ $t('register.company_siret_HU') }}</h4>
              <el-input
                class="input"
                type="text"
                minlength="14"
                maxlength="14"
                v-model="register.organization.siret"
                :size="size"
                clearable
                :class="{ errorInput: regex.company_siret }"
                :placeholder="$t('register.company_siret_placeholder_10')"
              />
            </label>
            <label
              v-else-if="register.organization.country == 'IE'"
              for="company-siret"
              class="mr"
            >
              <h4>{{ $t('register.company_siret_IE') }}</h4>
              <el-input
                class="input"
                type="text"
                minlength="14"
                maxlength="14"
                v-model="register.organization.siret"
                :size="size"
                clearable
                :class="{ errorInput: regex.company_siret }"
                :placeholder="$t('register.company_siret_placeholder_GB')"
              />
            </label>
            <label
              v-else-if="register.organization.country == 'IT'"
              for="company-siret"
              class="mr"
            >
              <h4>{{ $t('register.company_siret_IT') }}</h4>
              <el-input
                class="input"
                type="text"
                minlength="14"
                maxlength="14"
                v-model="register.organization.siret"
                :size="size"
                clearable
                :class="{ errorInput: regex.company_siret }"
                :placeholder="$t('register.company_siret_placeholder_11')"
              />
            </label>
            <label
              v-else-if="register.organization.country == 'LV'"
              for="company-siret"
              class="mr"
            >
              <h4>{{ $t('register.company_siret_LV') }}</h4>
              <el-input
                class="input"
                type="text"
                minlength="14"
                maxlength="14"
                v-model="register.organization.siret"
                :size="size"
                clearable
                :class="{ errorInput: regex.company_siret }"
                :placeholder="$t('register.company_siret_placeholder_11')"
              />
            </label>
            <label
              v-else-if="register.organization.country == 'LT'"
              for="company-siret"
              class="mr"
            >
              <h4>{{ $t('register.company_siret_LT') }}</h4>
              <el-input
                class="input"
                type="text"
                minlength="14"
                maxlength="14"
                v-model="register.organization.siret"
                :size="size"
                clearable
                :class="{ errorInput: regex.company_siret }"
                :placeholder="$t('register.company_siret_placeholder_9')"
              />
            </label>
            <label
              v-else-if="register.organization.country == 'LU'"
              for="company-siret"
              class="mr"
            >
              <h4>{{ $t('register.company_siret_LU') }}</h4>
              <el-input
                class="input"
                type="text"
                minlength="14"
                maxlength="14"
                v-model="register.organization.siret"
                :size="size"
                clearable
                :class="{ errorInput: regex.company_siret }"
                :placeholder="$t('register.company_siret_placeholder_LU')"
              />
            </label>
            <label
              v-else-if="register.organization.country == 'MT'"
              for="company-siret"
              class="mr"
            >
              <h4>{{ $t('register.company_siret_MT') }}</h4>
              <el-input
                class="input"
                type="text"
                minlength="14"
                maxlength="14"
                v-model="register.organization.siret"
                :size="size"
                clearable
                :class="{ errorInput: regex.company_siret }"
                :placeholder="$t('register.company_siret_placeholder_MT')"
              />
            </label>
            <label
              v-else-if="register.organization.country == 'NL'"
              for="company-siret"
              class="mr"
            >
              <h4>{{ $t('register.company_siret_NL') }}</h4>
              <el-input
                class="input"
                type="text"
                minlength="14"
                maxlength="14"
                v-model="register.organization.siret"
                :size="size"
                clearable
                :class="{ errorInput: regex.company_siret }"
                :placeholder="$t('register.company_siret_placeholder_8')"
              />
            </label>
            <label
              v-else-if="register.organization.country == 'NO'"
              for="company-siret"
              class="mr"
            >
              <h4>{{ $t('register.company_siret_NO') }}</h4>
              <el-input
                class="input"
                type="text"
                minlength="14"
                maxlength="14"
                v-model="register.organization.siret"
                :size="size"
                clearable
                :class="{ errorInput: regex.company_siret }"
                :placeholder="$t('register.company_siret_placeholder_9')"
              />
            </label>
            <label
              v-else-if="register.organization.country == 'PL'"
              for="company-siret"
              class="mr"
            >
              <h4>{{ $t('register.company_siret_PL') }}</h4>
              <el-input
                class="input"
                type="text"
                minlength="14"
                maxlength="14"
                v-model="register.organization.siret"
                :size="size"
                clearable
                :class="{ errorInput: regex.company_siret }"
                :placeholder="$t('register.company_siret_placeholder_8')"
              />
            </label>
            <label
              v-else-if="register.organization.country == 'PT'"
              for="company-siret"
              class="mr"
            >
              <h4>{{ $t('register.company_siret_PT') }}</h4>
              <el-input
                class="input"
                type="text"
                minlength="14"
                maxlength="14"
                v-model="register.organization.siret"
                :size="size"
                clearable
                :class="{ errorInput: regex.company_siret }"
                :placeholder="$t('register.company_siret_placeholder_9')"
              />
            </label>
            <label
              v-else-if="register.organization.country == 'RO'"
              for="company-siret"
              class="mr"
            >
              <h4>{{ $t('register.company_siret') }}</h4>
              <el-input
                class="input"
                type="text"
                minlength="14"
                maxlength="14"
                v-model="register.organization.siret"
                :size="size"
                clearable
                :class="{ errorInput: regex.company_siret }"
                :placeholder="$t('register.company_siret_placeholder_8')"
              />
            </label>
            <label
              v-else-if="register.organization.country == 'SK'"
              for="company-siret"
              class="mr"
            >
              <h4>{{ $t('register.company_siret') }}</h4>
              <el-input
                class="input"
                type="text"
                minlength="14"
                maxlength="14"
                v-model="register.organization.siret"
                :size="size"
                clearable
                :class="{ errorInput: regex.company_siret }"
                :placeholder="$t('register.company_siret_placeholder_8')"
              />
            </label>
            <label
              v-else-if="register.organization.country == 'SI'"
              for="company-siret"
              class="mr"
            >
              <h4>{{ $t('register.company_siret_SI') }}</h4>
              <el-input
                class="input"
                type="text"
                minlength="14"
                maxlength="14"
                v-model="register.organization.siret"
                :size="size"
                clearable
                :class="{ errorInput: regex.company_siret }"
                :placeholder="$t('register.company_siret_placeholder_10')"
              />
            </label>
            <label
              v-else-if="register.organization.country == 'ES'"
              for="company-siret"
              class="mr"
            >
              <h4>{{ $t('register.company_siret_ES') }}</h4>
              <el-input
                class="input"
                type="text"
                minlength="14"
                maxlength="14"
                v-model="register.organization.siret"
                :size="size"
                clearable
                :class="{ errorInput: regex.company_siret }"
                :placeholder="$t('register.company_siret_placeholder_ES')"
              />
            </label>
            <label
              v-else-if="register.organization.country == 'SE'"
              for="company-siret"
              class="mr"
            >
              <h4>{{ $t('register.company_siret_SE') }}</h4>
              <el-input
                class="input"
                type="text"
                minlength="14"
                maxlength="14"
                v-model="register.organization.siret"
                :size="size"
                clearable
                :class="{ errorInput: regex.company_siret }"
                :placeholder="$t('register.company_siret_placeholder_10')"
              />
            </label>
            <label
              v-else-if="register.organization.country == 'CH'"
              for="company-siret"
              class="mr"
            >
              <h4>{{ $t('register.company_siret_CH') }}</h4>
              <el-input
                class="input"
                type="text"
                minlength="14"
                maxlength="14"
                v-model="register.organization.siret"
                :size="size"
                clearable
                :class="{ errorInput: regex.company_siret }"
                :placeholder="$t('register.company_siret_placeholder_CH')"
              />
            </label>
            <label v-else for="company-siret" class="mr">
              <h4>{{ $t('register.company_siret') }}</h4>
              <el-input
                class="input"
                type="text"
                minlength="14"
                maxlength="14"
                v-model="register.organization.siret"
                :size="size"
                clearable
                :class="{ errorInput: regex.company_siret }"
                :placeholder="$t('register.company_siret_placeholder')"
              />
            </label>
            <label for="company-vat">
              <h4>{{ $t('register.company_vat') }}</h4>
              <el-input
                class="input"
                type="text"
                minlength="14"
                maxlength="14"
                v-model="register.organization.vat"
                :size="size"
                clearable
                :class="{ errorInput: regex.company_vat }"
                placeholder="FR13918380254"
              />
            </label>
          </div>
          <div class="bloc-2" v-if="register.account_type">
            <label for="company-category">
              <h4>{{ $t('register.step_1.business_type') }}</h4>
              <el-select
                class="select-list"
                no-match-text="No data"
                :placeholder="$t('register.step_1.business_type')"
                filterable
                v-model="register.organization.business_type"
                size="large"
                :class="{ errorInput: regex.company_category }"
                effect="dark"
                clearable
              >
                <el-option
                  v-for="item in getLists.business_type"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </label>
            <label for="company-status" style="margin-left: 15px">
              <h4>{{ $t('register.step_1.business_status') }}</h4>
              <el-input
                class="input"
                type="text"
                minlength="14"
                maxlength="14"
                v-model="register.organization.status"
                :size="size"
                clearable
                :class="{ errorInput: regex.company_status }"
                :placeholder="$t('register.step_1.business_status')"
              />
            </label>
          </div>
        </div>

        <div class="business-multi-identity" v-if="register.account_type">
          <el-divider class="form-divider" />

          <div class="bloc-2 multi-identity">
            <label for="company-country">
              <el-checkbox
                v-model="register.business_multi_identity"
                :size="size"
                text-color="#fff9"
                :disabled="businessType"
                :label="$t('register.step_1.business_part')"
              />
            </label>
          </div>

          <div
            v-if="register.business_multi_identity"
            class="form-multi-identity"
          >
            <div v-if="register.shareholders.length < 3" class="bloc-1">
              <h4>{{ $t('register.civility') }}</h4>
              <el-select
                :placeholder="$t('register.step_1.select')"
                filterable
                v-model="shareholders.civility"
                :class="{ errorInput: shareholdersRegex.civility }"
                :size="size"
                effect="dark"
              >
                <el-option
                  v-for="item in civility"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </div>

            <div v-if="register.shareholders.length < 3" class="bloc-2">
              <label for="firstname" class="mr">
                <h4>{{ $t('register.firstname') }}</h4>
                <el-input
                  :placeholder="$t('register.firstname')"
                  type="text"
                  minlength="2"
                  maxlength="50"
                  autofocus
                  v-model="shareholders.firstname"
                  clearable
                  :class="{ errorInput: shareholdersRegex.firstname }"
                  :size="size"
                />
              </label>
              <label for="lastname">
                <h4>{{ $t('register.lastname') }}</h4>
                <el-input
                  :placeholder="$t('register.lastname')"
                  type="text"
                  minlength="2"
                  maxlength="50"
                  v-model="shareholders.lastname"
                  clearable
                  :class="{ errorInput: shareholdersRegex.lastname }"
                  :size="size"
                />
              </label>
            </div>

            <div
              v-if="register.shareholders.length < 3"
              class="bloc-2 birthdate"
            >
              <label for="birthday" class="mr">
                <h4>{{ $t('register.birthdate') }}</h4>
                <el-date-picker
                  class="birthdate"
                  :placeholder="$t('register.birthdate')"
                  style="width: 237px"
                  v-model="shareholders.birth"
                  type="date"
                  clearable
                  :size="size"
                  format="YYYY/MM/DD"
                  value-format="YYYY-MM-DD"
                  :class="{ errorInput: shareholdersRegex.birth }"
                />
              </label>
              <label for="birth_city">
                <h4>{{ $t('register.birth_city') }}</h4>
                <el-input
                  :placeholder="$t('register.birth_city')"
                  type="text"
                  minlength="2"
                  maxlength="100"
                  autofocus
                  v-model="shareholders.birth_city"
                  clearable
                  :class="{ errorInput: regex.birth_city }"
                  :size="size"
                />
              </label>
            </div>

            <div v-if="register.account_type && register.shareholders.length < 3" class="bloc-2 nationality-country">
              <label for="birth_country">
                <h4>{{ $t('register.birth_country') }}</h4>
                <el-select
                  :placeholder="$t('register.step_1.select')"
                  filterable
                  v-model="shareholders.birth_country"
                  :size="size"
                  effect="dark"
                >
                  <el-option
                    v-for="item in country"
                    :key="item.nom_en_gb"
                    :label="item.nom_en_gb"
                    :value="item.alpha2"
                  />
                </el-select>
              </label>
            </div>

            <div
              v-if="register.shareholders.length < 3"
              class="bloc-2 nationality-country"
            >
              <label for="nationality">
                <h4>{{ $t('register.nationality') }}</h4>
                <el-select
                  :placeholder="$t('register.step_1.select')"
                  filterable
                  v-model="shareholders.nationality"
                  :size="size"
                  effect="dark"
                >
                  <el-option
                    v-for="item in country"
                    :key="item.nom_en_gb"
                    :label="item.nom_en_gb"
                    :value="item.alpha2"
                  />
                </el-select>
              </label>
              <label for="country">
                <h4>{{ $t('register.country') }}</h4>
                <el-select
                  :placeholder="$t('register.step_1.select')"
                  filterable
                  v-model="shareholders.country"
                  :size="size"
                  effect="dark"
                >
                  <el-option
                    v-for="item in country"
                    :key="item.nom_en_gb"
                    :label="item.nom_en_gb"
                    :value="item.alpha2"
                  />
                </el-select>
              </label>
            </div>

            <el-divider
              v-if="register.shareholders.length < 3"
              class="form-divider"
            />

            <div v-if="register.shareholders.length < 3" class="bloc-2">
              <label for="address">
                <h4>{{ $t('register.address') }}</h4>
                <el-input
                  :placeholder="$t('register.address_placeholder')"
                  type="text"
                  minlength="2"
                  maxlength="80"
                  clearable
                  v-model="shareholders.address"
                  :size="size"
                  :class="{ errorInput: shareholdersRegex.address }"
                />
              </label>
            </div>

            <div v-if="register.shareholders.length < 3" class="bloc-2">
              <label for="zip" class="mr">
                <h4>{{ $t('register.zipcode') }}</h4>
                <el-input
                  :placeholder="$t('register.zipcode')"
                  type="text"
                  minlength="4"
                  maxlength="7"
                  v-model="shareholders.zip"
                  clearable
                  :class="{ errorInput: shareholdersRegex.zip }"
                  :size="size"
                />
              </label>
              <label for="city">
                <h4>{{ $t('register.city') }}</h4>
                <el-input
                  :placeholder="$t('register.city')"
                  type="text"
                  minlength="2"
                  maxlength="50"
                  v-model="shareholders.city"
                  clearable
                  :class="{ errorInput: shareholdersRegex.city }"
                  :size="size"
                />
              </label>
            </div>

            <div v-if="register.shareholders.length < 3" class="bloc-2">
              <label for="region">
                <h4>{{ $t('register.region') }}</h4>
                <el-input
                  :placeholder="$t('register.region')"
                  type="text"
                  minlength="2"
                  maxlength="50"
                  v-model="shareholders.region"
                  clearable
                  :class="{ errorInput: regex.region }"
                  :size="size"
                />
              </label>
            </div>

            <el-divider
              v-if="register.shareholders.length < 3"
              class="form-divider"
            />

            <div v-if="register.shareholders.length < 3" class="bloc-2">
              <label for="email">
                <h4>{{ $t('register.email') }}</h4>
                <el-input
                  :placeholder="$t('register.email')"
                  type="email"
                  minlength="2"
                  maxlength="75"
                  v-model="shareholders.email"
                  :size="size"
                  clearable
                  :class="{ errorInput: shareholdersRegex.email }"
                />
              </label>
            </div>

            <div v-if="register.shareholders.length < 3" class="bloc-2">
              <label for="phone" class="tel-width">
                <h4>{{ $t('register.phone') }}</h4>
                <el-input
                  v-model="shareholders.phone"
                  placeholder="06 01 02 03 04"
                  :class="{ errorInput: shareholdersRegex.phone }"
                  :size="size"
                  autocomplete="phone"
                  clearable
                >
                  <template #prepend>
                    <el-select
                      :placeholder="$t('register.step_1.select')"
                      class="tel-choice-coutry"
                      filterable
                      v-model="shareholders.phone_country"
                      :size="size"
                      clearable
                      effect="dark"
                    >
                      <el-option
                        v-for="item in country"
                        :key="item.nom_en_gb"
                        :label="item.nom_en_gb + '(+' + item.phone_code + ')'"
                        :value="item.phone_code"
                      >
                        <span>
                          {{ item.nom_en_gb + '(+' + item.phone_code + ')' }}
                        </span>
                      </el-option>
                    </el-select>
                  </template>
                </el-input>
              </label>
            </div>

            <button
              v-if="register.shareholders.length < 3"
              @click.prevent="addShareHolder()"
              class="generic-btn add-person"
            >
              {{ $t('register.step_1.add_person') }}
            </button>

            <div
              v-if="register.shareholders.length > 0"
              class="shareholders-list"
            >
              <div
                v-for="(person, n) in register.shareholders"
                :key="n"
                class="shareholders-list-informations"
              >
                <div class="shareholders-list-informations-person">
                  <p>
                    <strong
                      >{{ person.firstname }} {{ person.lastname }}</strong
                    >
                  </p>
                  <p>
                    {{ $t('register.step_1.birth') }}
                    <strong>{{ person.birth }}</strong>
                  </p>
                  <p>
                    {{ $t('register.step_1.country') }}
                    <strong>{{ person.country }}</strong>
                    {{ $t('register.step_1.nationality') }}
                    <strong>{{ person.nationality }}</strong>
                  </p>
                  <p>
                    <strong>{{ person.address }}</strong
                    >, <strong>{{ person.zip }}</strong> (<strong>{{
                      person.city
                    }}</strong
                    >)
                  </p>
                  <p>
                    <strong>{{ person.email }}</strong
                    >,
                    <strong
                      >+({{ person.phone_country }}) {{ person.phone }}</strong
                    >
                  </p>
                </div>
                <button @click.prevent="deleteShareHolder(n)">
                  <img
                    src="@/assets/svg/v2/registerProcess/delete.svg"
                    alt="Delete person"
                  />
                </button>
              </div>

              <el-divider class="form-divider" />
            </div>
          </div>
        </div>

        <div
          class="mangopay-conditions"
          :class="{ errorCheckbox: regex.mongopay }"
        >
          <el-checkbox
            class="checkbox-accept"
            v-model="register.mangopay_conditions"
            :size="size"
          />
          <p>{{ $t('register.mangopay_accept_p1') }}</p>
          <a @click="$router.push('/legal/mangopay')">{{
            $t('register.mangopay_accept_p2')
          }}</a>
          <p>{{ $t('register.mangopay_accept_p3') }}</p>
        </div>

        <div class="tep-conditions" :class="{ errorCheckbox: regex.tep }">
          <el-checkbox
            class="checkbox-accept"
            v-model="register.tep_conditions"
            :size="size"
          />
          <p>
            {{ $t('register.tep_accept_p1')
            }}<a @click="$router.push('/legal/tep')">{{
              $t('register.tep_accept_p2')
            }}</a
            >{{ $t('register.tep_accept_p3')
            }}<a @click="$router.push('/legal/tep')">{{
              $t('register.tep_accept_p4')
            }}</a
            >{{ $t('register.tep_accept_p5') }}
          </p>
        </div>
        <div class="tep-conditions hide-tel">
          <p class="tep-conditions-line-2"></p>
        </div>
        <div class="tep-newsletter">
          <el-checkbox
            class="checkbox-accept"
            v-model="register.newsletter"
            :size="size"
          />
          <p>{{ $t('register.newsletter_register') }}</p>
        </div>

        <div v-if="register.country === 'US'" class="tep-us-phone-regulation">
          <el-checkbox
            class="checkbox-accept"
            v-model="register.us_phone_regulation"
            :size="size"
          />
          <p>{{ $t('register.us_phone_regulation') }}</p>
        </div>
        
        <div v-if="register.country === 'US' && !register.us_phone_regulation" class="tep-us-phone-regulation tep-us-phone-regulation-warning">
          <p>{{ $t('register.us_phone_regulation_warning') }}</p>
        </div>

        <button @click.prevent="checkAllInputs()" class="generic-btn">
          {{ $t('register.create_and_continue') }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import m from 'moment'
import { find } from 'lodash'

export default {
  props: {
    account: {
      Type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      size: 'large',

      register: {
        account_type: false,
        civility: null,
        firstname: null,
        lastname: null,
        birth_unformated: null,
        birth: null,
        birth_city: null,
        birth_country: null,
        nationality: 'FR',
        region: '',
        country: 'FR',
        address: null,
        zip: null,
        city: null,
        other_delivery_address_ckeck: false,
        other_delivery_address: null,
        other_delivery_zip: null,
        other_delivery_city: null,
        email: null,
        phone_country: null,
        phone: null,
        password: null,
        password_two: null,
        organization: {
          name: null,
          siret: null,
          vat: null,
          email: null,
          organization_type: null,
          address: null,
          zip: null,
          city: null,
          region: null,
          country: 'FR',
          business_type: null,
          status: null,
        },
        business_multi_identity: false,
        shareholders: [],
        mangopay_conditions: false,
        tep_conditions: false,
        newsletter: false,
        us_phone_regulation: true
      },

      shareholders: {
        civility: null,
        firstname: null,
        lastname: null,
        birth: null,
        birth_city: null,
        birth_country: null,
        nationality: 'FR',
        country: 'FR',
        region: null,
        address: null,
        zip: null,
        city: null,
        email: null,
        phone_country: null,
        phone: null,
      },

      shareholdersRegex: {
        civility: false,
        firstname: false,
        lastname: false,
        birth: false,
        birth_city: false,
        birth_country: false,
        address: false,
        zip: false,
        city: false,
        region: false,
        email: false,
        phone_country: false,
        phone: false,
      },

      regex: {
        civility: false,
        firstname: false,
        lastname: false,
        birth: false,
        birth_city: false,
        birth_country: false,
        nationality: false,
        region: false,
        country: false,
        account_type: false,
        zip: false,
        city: false,
        address: false,
        other_delivery_address_ckeck: false,
        other_delivery_zip: false,
        other_delivery_city: false,
        other_delivery_address: false,
        email: false,
        phone: false,
        phone_country: false,
        password: false,
        password_two: false,

        company_name: false,
        company_address: false,
        company_zip: false,
        company_city: false,
        company_region: null,
        company_country: false,
        company_siret: false,
        company_vat: false,
        company_email: false,
        company_status: false,
        company_category: false,
        company_type: false,

        mongopay: false,
        tep: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      getLists: 'getLists',
    }),
    country() {
      return this.getLists.country
    },
    civility() {
      if (this.getLists.civility) {
        return this.getLists.civility
      } else {
        return [
          {
            label: 'Monsieur',
            value: 0,
          },
          {
            label: 'Madame',
            value: 1,
          },
        ]
      }
    },
    businessType() {
      if (this.register.organization.organization_type === 3) {
        return true
      }
      return false
    },
  },
  watch: {
    account() {
      this.register.account_type = this.account
    },
    'register.phone': function () {
      const regex_phone = /^[0-9]{1,15}$/
      if (!regex_phone.test(this.register.phone)) {
        this.register.phone = null
      }
    },
    'register.birth_unformated': function () {
      if (this.register.birth_unformated) {
        let slashCount = 0
        for (var i = 0; i < this.register.birth_unformated.length; i++) {
          if (this.register.birth_unformated[i] === '/') {
            slashCount++
          }
        }

        if (slashCount > 2) {
          this.register.birth_unformated = ''
        }

        if (this.register.birth_unformated.length === 2) {
          this.register.birth_unformated += '/'
        } else if (this.register.birth_unformated.length === 5) {
          this.register.birth_unformated += '/'
        }
      }
    },
    'register.country': function() {
      this.register.region = null;
    }
  },
  methods: {
    ...mapActions({
      registerMe: 'register',
    }),
    checkAllInputs() {
      if (this.register.account_type) {
        this.register.organization.email = this.register.email

        this.checkCompanyName()
        this.checkCompanyAddress()
        this.checkCompanyZip()
        this.checkCompanyCity()
        this.checkCompanyRegion()
        this.checkCompanyCountry()
        this.checkCompanySiret()
        this.checkCompanyEmail()
        this.checkCompanyStatus()
        this.checkCompanyCategory()
        this.checkCompanyType()
        this.checkCompanyVAT()
      }

      this.checkCivility()
      this.checkOtherDeliveryAddress()
      this.checkFirstnameLastname()
      this.checkBirth()
      this.checkZip()
      this.checkCity()
      this.checkAddress()
      this.checkRegion()
      this.checkEmail()
      this.checkPhone()
      this.checkPasswords()
      this.checkConditions()

      this.checkValues()
    },
    addShareHolder() {
      if (this.register.shareholders.length < 3) {
        const regex_firstname_lastname =
          /^[\w'\-,.][^0-9_!¡?÷?¿\\+=@#$%&*(){}|~<>;:[\]]{2,50}$/
        const regex_address = /^[\w'\-,.0-9][^_\\#$%&*{}|~<>[\]]{2,80}$/
        const regex_zip = /^[0-9]{4,6}$/
        const regex_city = /^[\w'\-,.0-9][^_\\#$%&*{}|~<>[\]]{2,50}$/
        const regex_company_email =
          //eslint-disable-next-line
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const regex_phone = /^[0-9]{8,13}$/
        const regex_phone_code = /^[0-9]{1,5}$/
        const regex_birthday =
          //eslint-disable-next-line
          /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/

        if (
          this.shareholders.civility !== 0 &&
          this.shareholders.civility !== 1
        ) {
          this.shareholdersRegex.civility = true
        } else {
          this.shareholdersRegex.civility = false
        }

        if (
          !regex_firstname_lastname.test(this.shareholders.firstname) ||
          this.shareholders.firstname === null
        ) {
          this.shareholdersRegex.firstname = true
        } else {
          this.shareholdersRegex.firstname = false
        }

        if (
          !regex_firstname_lastname.test(this.shareholders.lastname) ||
          this.shareholders.lastname === null
        ) {
          this.shareholdersRegex.lastname = true
        } else {
          this.shareholdersRegex.lastname = false
        }

        if (
          !regex_address.test(this.shareholders.address) ||
          this.shareholders.address === null
        ) {
          this.shareholdersRegex.address = true
        } else {
          this.shareholdersRegex.address = false
        }

        if (!regex_zip.test(this.shareholders.zip)) {
          this.shareholdersRegex.zip = true
        } else {
          this.shareholdersRegex.zip = false
        }

        if (
          !regex_city.test(this.shareholders.city) ||
          this.shareholders.city === null
        ) {
          this.shareholdersRegex.city = true
        } else {
          this.shareholdersRegex.city = false
        }

        if (!this.shareholders.region) {
          this.shareholdersRegex.region = true
        } else {
          this.shareholdersRegex.region = false
        }

        if (
          !regex_company_email.test(this.shareholders.email) ||
          this.shareholders.email === null
        ) {
          this.shareholdersRegex.email = true
        } else {
          this.shareholdersRegex.email = false
        }

        if (!regex_phone.test(this.shareholders.phone)) {
          this.shareholdersRegex.phone = true
        } else {
          this.shareholdersRegex.phone = false
        }

        if (!regex_phone_code.test(this.shareholders.phone_country)) {
          this.shareholdersRegex.phone_country = true
        } else {
          this.shareholdersRegex.phone_country = false
        }

        if (!regex_birthday.test(this.shareholders.birth)) {
          this.shareholdersRegex.birth = true
        } else {
          this.shareholdersRegex.birth = false
        }

        if (
          !this.shareholdersRegex.civility &&
          !this.shareholdersRegex.firstname &&
          !this.shareholdersRegex.lastname &&
          !this.shareholdersRegex.address &&
          !this.shareholdersRegex.zip &&
          !this.shareholdersRegex.city &&
          !this.shareholdersRegex.email &&
          !this.shareholdersRegex.phone_country &&
          !this.shareholdersRegex.phone &&
          !this.shareholdersRegex.birth
        ) {
          this.register.shareholders.push(this.shareholders)
          this.shareholders = {
            civility: null,
            firstname: null,
            lastname: null,
            birth: null,
            nationality: 'FR',
            country: 'FR',
            region: null,
            address: null,
            zip: null,
            city: null,
            email: null,
            phone_country: null,
            phone: null,
          }
        }
      }
    },
    deleteShareHolder(index) {
      this.register.shareholders.splice(index, 1)
    },
    checkCompanyAddress() {
      const regex_company_address = /^[\w'\-,.0-9][^_\\#$%&*{}|~<>[\]]{2,80}$/

      if (
        !regex_company_address.test(this.register.organization.address) ||
        this.register.organization.address === null
      ) {
        this.regex.company_address = true
      } else {
        this.regex.company_address = false
      }
    },
    checkCompanyName() {
      const regex_name = /^[\w'\-,.][^0-9_!¡?÷?¿\\+=@#$%*(){}|~<>;:[\]]{2,50}$/

      if (
        !regex_name.test(this.register.organization.name) ||
        this.register.organization.name === null
      ) {
        this.regex.company_name = true
      } else {
        this.regex.company_name = false
      }
    },
    checkCompanyStatus() {
      const regex_status =
        /^[\w'\-,.][^0-9_!¡?÷?¿\\+=@#$%&*(){}|~<>;:[\]]{2,50}$/
      if (
        !regex_status.test(this.register.organization.status) ||
        this.register.organization.status === null
      ) {
        this.regex.company_status = true
      } else {
        this.regex.company_status = false
      }
    },
    checkCompanyCategory() {
      if (typeof this.register.organization.business_type !== 'number') {
        this.regex.company_category = true
      } else {
        this.regex.company_category = false
      }
    },
    checkCompanyType() {
      if (
        this.register.organization.organization_type !== 0 &&
        this.register.organization.organization_type !== 1 &&
        this.register.organization.organization_type !== 2 &&
        this.register.organization.organization_type !== 3
      ) {
        this.regex.company_type = true
      } else {
        this.regex.company_type = false
      }
    },
    checkCompanyVAT() {
      const regex_company_vat = /^[A-Za-z]{2}[0-9A-Za-z]{2,}$/

      if (
        !regex_company_vat.test(this.register.organization.vat) ||
        this.register.organization.vat === null
      ) {
        this.regex.company_vat = true
      } else {
        this.regex.company_vat = false
      }
    },
    checkCompanyZip() {
      const regex_company_zip = /^[0-9a-zA-Z ]{4,10}$/

      if (
        !regex_company_zip.test(this.register.organization.zip) ||
        this.register.organization.zip === null
      ) {
        this.regex.company_zip = true
      } else {
        this.regex.company_zip = false
      }
    },
    checkCompanyCity() {
      const regex_company_city = /^[\w'\-,.0-9][^_\\#$%&*{}|~<>[\]]{2,50}$/

      if (
        !regex_company_city.test(this.register.organization.city) ||
        this.register.organization.city === null
      ) {
        this.regex.company_city = true
      } else {
        this.regex.company_city = false
      }
    },
    checkCompanyRegion() {
      const regex_region = /[a-zA-Z -'àâäéèêëîïôöùûüÿçÀÂÄÉÈÊËÎÏÔÖÙÛÜŸÇ]{2,75}/

      if (
        !regex_region.test(this.register.organization.region) ||
        this.register.organization.region === null
      ) {
        this.regex.company_region = true
      } else {
        this.regex.company_region = false
      }
    },
    checkCompanyCountry() {
      if (this.register.organization.country === null) {
        this.regex.company_country = true
      } else {
        this.regex.company_country = false
      }
    },
    checkCompanySiret() {
      const regex_company_siret = /^[0-9a-zA-Z -]{3,30}$/

      if (this.register.organization.siret) {
        this.register.organization.siret =
          this.register.organization.siret.replace(/\s+/g, '')
      }

      if (
        !regex_company_siret.test(this.register.organization.siret) ||
        this.register.organization.siret === null
      ) {
        this.regex.company_siret = true
      } else {
        this.regex.company_siret = false
      }
    },
    checkCompanyEmail() {
      const regex_company_email =
        //eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      if (
        !regex_company_email.test(this.register.organization.email) ||
        this.register.organization.email === null
      ) {
        this.regex.company_email = true
      } else {
        this.regex.company_email = false
      }
    },
    checkCivility() {
      if (this.register.civility !== 0 && this.register.civility !== 1) {
        this.regex.civility = true
      } else {
        this.regex.civility = false
      }
    },
    checkFirstnameLastname() {
      const regex_firstname_lastname =
        /^[\w'\-,.][^0-9_!¡?÷?¿\\+=@#$%&*(){}|~<>;:[\]]{2,50}$/

      if (
        !regex_firstname_lastname.test(this.register.firstname) ||
        this.register.firstname === null
      ) {
        this.regex.firstname = true
      } else {
        this.regex.firstname = false
      }

      if (
        !regex_firstname_lastname.test(this.register.lastname) ||
        this.register.lastname === null
      ) {
        this.regex.lastname = true
      } else {
        this.regex.lastname = false
      }
    },
    checkBirth() {
      const adultCheck = m().subtract('years', 18)
      const maxAge = m().subtract('years', 120)

      const birthday = m(this.register.birth_unformated, 'DD/MM/YYYY')

      if (
        birthday.isValid() &&
        adultCheck.isAfter(birthday) &&
        maxAge.isBefore(birthday)
      ) {
        this.register.birth = m(birthday, 'DD/MM/YYYY').format('YYYY-MM-DD')
        this.regex.birth = false
      } else {
        this.regex.birth = true
      }
    },
    checkZip() {
      const regex_zip = /^[0-9a-zA-Z ]{4,10}$/

      if (!regex_zip.test(this.register.zip) || this.register.zip === null) {
        this.regex.zip = true
      } else {
        this.regex.zip = false
      }
    },
    checkCity() {
      const regex_city = /^[\w'\-,.0-9][^_\\#$%&*{}|~<>[\]]{2,50}$/

      if (!regex_city.test(this.register.city) || this.register.city === null) {
        this.regex.city = true
      } else {
        this.regex.city = false
      }
    },
    checkAddress() {
      const regex_address = /^[\w'\-,.0-9][^_\\#$%&*{}|~<>[\]]{2,80}$/

      if (
        !regex_address.test(this.register.address) ||
        this.register.address === null
      ) {
        this.regex.address = true
      } else {
        this.regex.address = false
      }
    },
    checkRegion() {
      const regex_region = /[a-zA-Z -'àâäéèêëîïôöùûüÿçÀÂÄÉÈÊËÎÏÔÖÙÛÜŸÇ]{2,75}/

      if (
        !regex_region.test(this.register.region) ||
        this.register.region === null
      ) {
        this.regex.region = true
      } else {
        this.regex.region = false
      }
    },
    checkOtherDeliveryAddress() {
      if (!this.register.other_delivery_address_ckeck) {
        this.register.other_delivery_zip = null
        this.register.other_delivery_city = null
        this.register.other_delivery_address = null
      } else {
        const regex_zip = /^[0-9]{4,6}$/
        const regex_city = /^[\w'\-,.0-9][^_\\#$%&*{}|~<>[\]]{2,50}$/
        const regex_address = /^[\w'\-,.0-9][^_\\#$%&*{}|~<>[\]]{2,80}$/

        if (
          !regex_zip.test(this.register.other_delivery_zip) ||
          this.register.other_delivery_zip === null
        ) {
          this.regex.other_delivery_zip = true
        } else {
          this.regex.other_delivery_zip = false
        }

        if (
          !regex_city.test(this.register.other_delivery_city) ||
          this.register.other_delivery_city === null
        ) {
          this.regex.other_delivery_city = true
        } else {
          this.regex.other_delivery_city = false
        }

        if (
          !regex_address.test(this.register.other_delivery_address) ||
          this.register.other_delivery_address === null
        ) {
          this.regex.other_delivery_address = true
        } else {
          this.regex.other_delivery_address = false
        }
      }
    },
    checkEmail() {
      const regex_email =
        //eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      if (!regex_email.test(this.register.email)) {
        this.regex.email = true
      } else {
        this.regex.email = false
      }
    },
    checkPhone() {
      const regex_phone = /^[0-9]{8,13}$/
      const regex_phone_code = /^[0-9]{1,5}$/

      if (!regex_phone.test(this.register.phone) || !this.register.phone) {
        this.regex.phone = true
      } else {
        this.regex.phone = false
      }

      if (!regex_phone_code.test(this.register.phone_country)) {
        this.regex.phone_country = true
      } else {
        this.regex.phone_country = false
      }
    },
    checkPasswords() {
      //eslint-disable-next-line
      const regex_password =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,80}$/

      if (!regex_password.test(this.register.password)) {
        this.regex.password = true
      } else {
        this.regex.password = false
      }

      if (!regex_password.test(this.register.password_two)) {
        this.regex.password_two = true
      } else {
        this.regex.password_two = false
      }

      if (this.register.password !== this.register.password_two) {
        this.regex.password = true
        this.regex.password_two = true
      }
    },
    checkConditions() {
      if (!this.register.tep_conditions) {
        this.regex.tep = true
      } else {
        this.regex.tep = false
      }

      if (!this.register.mangopay_conditions) {
        this.regex.mongopay = true
      } else {
        this.regex.mongopay = false
      }
    },
    checkValues() {
      if (this.register.password !== this.register.password_two) {
        this.regex.password = true
        this.regex.password_two = true
      } else {
        if (
          !this.regex.firstname &&
          !this.regex.lastname &&
          !this.regex.civility &&
          !this.regex.birth &&
          !this.regex.region &&
          !this.regex.address &&
          !this.regex.city &&
          !this.regex.email &&
          !this.regex.password &&
          !this.regex.password_two &&
          !this.regex.phone &&
          !this.regex.zip &&
          !this.regex.tep &&
          !this.regex.mongopay
        ) {
          if (this.register.account_type) {
            if (
              !this.regex.company_address &&
              !this.regex.company_city &&
              !this.regex.company_country &&
              !this.regex.company_email &&
              !this.regex.company_region &&
              !this.regex.company_name &&
              !this.regex.company_siret &&
              !this.regex.company_zip &&
              !this.regex.company_status &&
              !this.regex.company_vat
            ) {
              this.registerMe(this.register)
            }
          } else {
            this.registerMe(this.register)
          }
        }
      }
    },
  },
  mounted() {
    this.$store.commit('SET_POSITION', 'register')
    this.register.account_type = this.account

    if (navigator.language) {
      const lang = navigator.language.substring(3, 5)
      const phonecode = find(this.country, { alpha2: lang })

      this.register.nationality = lang
      this.register.country = lang

      if (phonecode) {
        this.register.phone_country = phonecode.phone_code
      }

      if (this.register.account_type) {
        this.register.organization.country = lang
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/root.scss';

.register-form,
.register-account-type,
.bloc-2,
.mangopay-conditions,
.tep-conditions,
.tep-conditions p,
.tep-newsletter,
.tep-us-phone-regulation,
.nationality-country,
form {
  display: flex;
}

.hide {
  visibility: hidden;
}

.register-form {
  justify-content: center;
  padding-bottom: 50px;

  .register-center {
    h1 {
      margin-top: 16px;
      margin-bottom: 11px;
    }

    p {
      color: $subtitle;
    }

    .register-account-type {
      align-items: center;
      justify-content: center;
      border-radius: 24px;
      border: 1px solid $border;
      padding: 8px;
      margin-top: 34px;
      width: 176px;
      cursor: pointer;

      img {
        margin-right: 8px;
      }

      p {
        color: white;
        font-size: 14px;
      }
    }

    form {
      flex-direction: column;
      margin-top: 42px;
      width: 492px;

      button {
        margin-top: 25px;
      }

      .password-requirement {
        font-size: 13px;
        margin-top: 7px;
      }
    }
  }
}

.mangopay-conditions {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mangopay-conditions,
.tep-conditions,
.tep-newsletter,
.tep-us-phone-regulation {
  align-items: center;

  .checkbox-accept {
    margin-right: 8px;
  }

  a,
  p {
    font-size: 14px;
  }

  a {
    color: $blue;
    margin: 0 3px;
    height: 17px;
  }

  .tep-conditions-line-2 {
    margin-left: 21px;
  }
}

.tep-conditions p {
  display: flex;
  flex-wrap: wrap;
}

.tep-newsletter,
.tep-us-phone-regulation {
  margin-top: 10px;
}

.tep-us-phone-regulation-warning p {
  color: #f59e0b!important;
}

.bloc-1 {
  h4 {
    color: white;
    font-weight: 600;
    margin-bottom: 8px;
  }
}

.bloc-2 {
  margin-top: 21px;
  width: 100%;

  label {
    width: 100%;

    h4 {
      color: white;
      font-weight: 600;
      margin-bottom: 8px;
    }
  }
}

.nationality-country {
  width: 100%;
  justify-content: space-between;

  label {
    display: flex;
    flex-direction: column;
    width: 238px;
  }
}

.mr {
  margin-right: 16px;
}

.form-divider {
  margin: 25px auto;
  border-color: $border;
}

.business-multi-identity {
  .multi-identity {
    margin-bottom: 15px;
  }

  .form-multi-identity {
    .add-person {
      width: 147px;
      margin: auto;
      margin-top: 25px;
      font-size: 13px;
    }

    .shareholders-list {
      margin: 25px 0;

      .shareholders-list-informations {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid $border;
        border-radius: 8px;
        padding: 10px 15px;
        margin-bottom: 10px;

        .shareholders-list-informations-person {
          p {
            margin-bottom: 4px;
            font-size: 15px;

            strong {
              color: white;
            }
          }
        }

        button {
          background: transparent;
          border: 0;
          width: 30px;
          margin-top: 0;
        }
      }
    }
  }
}

.tel-choice-coutry {
  width: 140px;
  border: 0;
}

@media screen and (max-width: 500px) {
  .hide-tel {
    display: none;
  }

  .hide {
    visibility: visible !important;
    color: $subtitle;
  }

  .bloc-2 {
    flex-wrap: wrap;

    label {
      width: 100%;
      max-width: 160px;

      div {
        max-width: 160px;
      }
    }

    .tel-width {
      max-width: 350px;
      div {
        max-width: 350px;
      }
    }
  }

  .nationality-country {
    max-width: 335px;
  }

  form {
    max-width: 350px;
  }

  .mangopay-conditions {
    flex-wrap: wrap;

    label {
      display: flex;
    }
  }

  .tep-newsletter,
  .tep-us-phone-regulation {
    margin-top: 20px;
  }

  label[for='company-status'] {
    div {
      margin-top: 20px;
    }
  }

  .generic-btn {
    width: 250px;
    margin: auto;
  }
}
</style>
