import { api } from '@/services/interceptors';

export default {
  state: {
    orders: [],
    orders_stats: null,
    stats: [],
  },
  getters: {
    getTransactions(state) {
      return state.orders;
    },
    getOrdersStats(state) {
      return state.orders_stats;
    },
    getStats(state) {
      return state.stats;
    },
  },
  actions: {
    getAllTransactions({ commit }) {
      api.get('api/auth/dashboard/transactions').then((res) => {
        commit('SET_MY_ORDERS', res.data.transactions);
      });
    },
    getOrderHistory({ commit }) {
      return new Promise((resolve) => {
        api.get('/api/auth/history').then((res) => {
          commit('SET_MY_ORDERS', res.data.orders);
          commit('ORDERS_STATS', res.data.statistics);
          resolve(res);
        });
      });
    },
    deleteOrder({ commit }, payload) {
    return new Promise((resolve) => {
      api.delete('api/auth/buysell/orders/' + payload.order_uuid).then(() => {
        api.get('/api/auth/history').then((res) => {
          commit('SET_MY_ORDERS', res.data.orders);
          resolve(res);
        });    
      });
    });
  },
    getStats({ commit }) {
      const last_check = new Date(localStorage.getItem('stats_last_check'));
      const today = new Date();
      if (today.getDate() > last_check.getDate()) {
        api.get('api/global/stats').then((res) => {
          localStorage.setItem('stats_last_check', today);
          commit('SET_STATS', res.data.statistics);
        });
      }
    },
  },
  mutations: {
    SET_MY_ORDERS(currentState, orders) {
      currentState.orders = orders;
    },
    ORDERS_STATS(currentState, stats) {
      currentState.orders_stats = stats;
    },
    SET_STATS(currentState, stats) {
      currentState.stats = stats;
    },
    STORE_NOTHING() {},
  },
};
